/**
 *
 *  For more info about page this page, refer
 *  https://github.com/mui-org/material-ui/tree/master/examples/nextjs-with-typescript/pages
 *
 */
import React from 'react';
import App, { AppProps } from 'next/app';
import { theme, MuiThemeProvider } from '@admitkard/ccl/style';
import { appWithTranslation } from 'utils/i18n';
import { setReferralMetaConfig, ReferralMetaConfigType } from '@admitkard/common/utils/pageReferralUtility';

// Custom Head Tag for Prelogin
import { AkHead } from 'components/AkHead'; // TODO @Mayank: Remove relative paths
import { auth } from '@admitkard/uiutils/http';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { WhatsappLink } from 'components/WhatsappLink';
import { env } from '@admitkard/uiutils/env';
import { Router } from 'next/router';
import { Loader } from 'components/Loader/Loader';
import { MobileCta } from 'components/MobileCta/MobileCta';
import { setupFFAndContext } from 'utils/ffSetup';
import { setCookie, getCookie } from '@admitkard/uiutils/cookie';
import { ab } from '@admitkard/uiutils/abTest';
import { DialogPortal } from '@admitkard/ccl';
import { GlobalContext } from 'context/context';
import '../styles/global.css';
import { Analytics } from '@admitkard/uiutils/analytics/events';

const FOOTER_BLACKLIST = [
  // pages on which footer needs to be hidden
  '/student/login/register',
];

const MOBILE_CTA_BACKLIST = ['/student/login/register', '/profilekard', '/mentorx/questionnare', '/mentorx'];

type PageLoadingState = 'entering' | 'exiting' | 'exited';

interface MyAppState {
  pageLoadingState: PageLoadingState;
  isSetupDone: boolean;
  ffInitDone: boolean;
}
class MyApp extends App<AppProps<{ ffInitDone: boolean }>, any, MyAppState> {
  isSetupDone = false;
  state = {
    pageLoadingState: 'exited' as PageLoadingState,
    isSetupDone: false,
    ffInitDone: false,
  };

  runSetup = () => {
    if (!this.isSetupDone) {
      env.setModule('prelogin');
      auth.setupToken();
      if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        setupFFAndContext().then(() => {
          this.setState({ ffInitDone: true });
        });
        ab.setExperiments(getCookie('_exp'));
        const experimentCookie = ab.getExperimentsCookie();
        setCookie('_exp', experimentCookie, 365);
      }
      this.isSetupDone = true;
    }
  };

  handleRouteChangeStart = () => {
    this.setState({ pageLoadingState: 'entering' });
  };

  handleRouteChangeEnd = () => {
    this.setState({ pageLoadingState: 'exiting' });
    if (env.ENV !== 'development' && env.ENV !== 'production') {
      Analytics.track('page_view', { page: window.location.pathname });
    }
  };

  handleRouteChangeErr = () => {
    this.setState({ pageLoadingState: 'exiting' });
  };

  onLoaderExit = () => {
    this.setState({ pageLoadingState: 'exited' });
  };

  historyOverride(type: string) {
    const originalEvent = (window.history as any)[type];
    return function (...args: any[]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const originalCall = originalEvent.apply(this, args);
      const e = new Event(type);
      window.dispatchEvent(e);
      return originalCall;
    };
  }

  storeReferralMetaData = () => {
    if (window) {
      const urlParams = new URLSearchParams(window.location.search);
      const referralMetaConfig: ReferralMetaConfigType = {
        referredBy: urlParams.get('referredBy') || '',
        referralMedium: urlParams.get('referralMedium') || '',
        referralType: urlParams.get('referralType') || '',
      };
      setReferralMetaConfig(referralMetaConfig);
    }
  };

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    setupFFAndContext();
    Router.events.on('routeChangeStart', this.handleRouteChangeStart);
    Router.events.on('routeChangeComplete', this.handleRouteChangeEnd);
    Router.events.on('routeChangeError', this.handleRouteChangeStart);
    window.history.pushState = this.historyOverride('pushState');

    // eslint-disable-next-line prefer-rest-params
    ab.activate();
    this.storeReferralMetaData();
  }

  componentWillUnmount() {
    Router.events.off('routeChangeStart', this.handleRouteChangeStart);
    Router.events.off('routeChangeComplete', this.handleRouteChangeEnd);
    Router.events.off('routeChangeError', this.handleRouteChangeStart);
  }

  render() {
    this.runSetup();
    const { Component, pageProps, router } = this.props;
    return (
      <>
        <GlobalContext>
          <MuiThemeProvider theme={theme}>
            {this.state.pageLoadingState !== 'exited' && (
              <Loader transitionState={this.state.pageLoadingState} onExit={this.onLoaderExit} />
            )}
            <AkHead />
            <Header />
            <div className="main-body">
              <Component {...pageProps} ffInitDone={this.state.ffInitDone} />
            </div>
            {!FOOTER_BLACKLIST.includes(router.route) && <Footer />}
            <WhatsappLink />
            {!MOBILE_CTA_BACKLIST.includes(router.route) && <MobileCta />}
            <DialogPortal />
          </MuiThemeProvider>
        </GlobalContext>
      </>
    );
  }
}

export default appWithTranslation(MyApp);
