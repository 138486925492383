import { SuaveChip } from '@admitkard/ccl';
import { SingleSelectItemConfig } from 'components/ListingEngine/types';
import React from 'react';

interface MultiSelectDesktopFilterProps {
  itemConfigs: SingleSelectItemConfig[],
  onSelect: (selection?: string[]) => void;
  selection?: string[];
  withIcon?: boolean;
}

const MultiSelectDesktopFilter: React.FunctionComponent<MultiSelectDesktopFilterProps> = (props) => {

  const localData = new Set<string>(props.selection);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: '0.5rem',
    }}>
      {props.itemConfigs.map(
        (value) => {
          const isSelected = localData.has(value.slug);
          return (
            <SuaveChip
              key={`filter-item-${value.slug}}`}
              label={value.label}
              variant='select-able'
              size='xl'
              backgroundColor='lavender'
              isSelected={isSelected}
              icon={props.withIcon ? value?.icon : undefined}
              onClick={
                () => {
                  isSelected === true ? localData.delete(value.slug) : localData.add(value.slug);
                  if(localData.size > 0) {
                    props.onSelect(Array.from<string>(localData));
                  } else {
                    props.onSelect(undefined);
                  }
                }
              }
            />
          );
        }
      )}
    </div>
  );
};

export default MultiSelectDesktopFilter;
