import { ChipData } from 'components/ListingEngine/types';
export type IntakeChipData = ChipData & { listSlug: string; };

type IntakeSkeleton = { from: number, to: number, year: number };

/**
 * Function to get the intake for a given month
 * @param {number} currentMonth - The current month (0-based index)
 * @returns {number|undefined} - The intake for the month, or undefined if the month is not in the ranges specified
 */
function getIntakeForMonth(currentMonth: number) {
  switch (true) {
    case (currentMonth < 3): {
      return 3;
    }
    case (currentMonth < 6): {
      return 6;
    }
    case (currentMonth < 9): {
      return 9;
    }
    case (currentMonth < 12): {
      return 0;
    }
  }
  return undefined;
}


function rawToIntakeChipDataPAF(param: IntakeSkeleton): IntakeChipData {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] as const;
  return {
    slug: `${months[param.from]} to ${months[param.to]} - ${param.year}`,
    label: `${months[param.from]} - ${months[param.to]} ${param.year}`,
    listSlug: `${months[param.from]}-${months[param.to]},${param.year}`.toLowerCase(),
    icon: 'calendar3',
  };
}

function rawToIntakeChipDataListing(param: IntakeSkeleton): ChipData {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] as const;
  return {
    label: `${months[param.from]} - ${months[param.to]} ${param.year}`,
    slug: `${months[param.from]}-${months[param.to]},${param.year}`.toLowerCase(),
    icon: 'calendar',
  };
}

/**
 * Get the intake chip data for a given period.
 * @param {number} currentMonth - The current month (0-11).
 * @param {number} currentYear - The current year.
 * @param {number} length - The number of intakes to generate.
 * @param {(param: IntakeSkeleton) => T} chipDataGenerator - The generator function to generate the intake chip data.
 * @returns {T[]} - The generated intake chip data.
 */
function getAllIntakeChipData<T>(
  currentMonth: number,
  currentYear: number,
  length: number,
  chipDataGenerator: (param: IntakeSkeleton) => T,
): T[] {
  let year = currentMonth >= 9 ? currentYear + 1 : currentYear;
  let month = getIntakeForMonth(currentMonth);
  const intakesChips: T[] = [];
  for (let i = 0; i < length; i++) {
    if (typeof month === 'number') {
      intakesChips.push(chipDataGenerator({ from: month, to: month + 2, year }));
      if (month >= 9) {
        year += 1;
        month = 0;
      } else {
        month += 3;
      }
    }
  }
  return intakesChips;
}

/**
 * Get the intake chip data for a given period.
 * @param {number} currentMonth - The current month (0-11).
 * @param {number} currentYear - The current year.
 * @param {number} length - The number of intakes to generate.
 * @returns {IntakeChipData[]} - The generated intake chip data for PAF.
 */
export function getAllIntakeChipDataPAF(
  currentMonth: number,
  currentYear: number,
  length: number,
): IntakeChipData[] {
  return getAllIntakeChipData(currentMonth, currentYear, length, rawToIntakeChipDataPAF);
}

/**
 * Get the intake chip data for a given period.
 * @param {number} currentMonth - The current month (0-11).
 * @param {number} currentYear - The current year.
 * @param {number} length - The number of intakes to generate.
 * @returns {ChipData[]} - The generated intake chip data for Listing.
 */
export function getAllIntakeChipDataListing(
  currentMonth: number,
  currentYear: number,
  length: number,
): ChipData[] {
  return getAllIntakeChipData(currentMonth, currentYear, length, rawToIntakeChipDataListing);
}
