import { FilterItemProps, IconName, MinMaxRange } from '@admitkard/ccl';
import { slugify } from 'components/MentorX/MentorXutils';
import { LIST_COUNTRIES } from '../Constants';
export type { default as AccommodationItemData } from './AccommodationItemData';
export * from './AccommodationDetailsData';

export interface BaseCustomFilter<T> {
  onSelect: (selection?: T) => void;
  selection?: T;
  filterData?: any;
}

export type BudgetFilterProps = BaseCustomFilter<MinMaxRange> & {
  currency?: string,
};

export type SpecialFilterItemProps = FilterItemProps & { isSpecial?: boolean };

export interface StaticSearchResult {
  id: string;
  label: string;
  isRecommended: boolean;
}

export type SingleSelectItemConfig = {
  slug: string,
  label: string,
  icon?: IconName,
};

export type CourseBucket = 'dreamCourses' | 'safeCourses' | 'reachCourses';

export const gradingValues = [100, 10, 5] as const;
export type CourseGrading = typeof gradingValues[number];

export const englishExamNoneKey = 'none';
export const competitiveExamNoneKey = 'competitiveNone';

export class ShortlistPayload {
  country: string;
  countrySlug: string;
  universityId: string;
  universityName: string;
  intake: string;
  courseName: string;
  courseLevel: string;
  courseId: string;
  courseLink: string;
  areaOfStudy: string;
  specialization: string;
  courseDuration: string;
  annualTuitionFees: number;
  annualTuitionFeesInr: number;
  annualTuitionFeesInInr: number;
  admitProbability: number;
  applicationDeadline: string;
  isPremiumUniversity: boolean;
  isOnlineCourse: boolean;
  applicationFees: number;
  applicationFeesInr: number;
  applicationFeesInInr: number;
  isCompetitiveExamRequired: boolean;
  minEnglishExamScore: number;
  workExperienceNeeded: boolean;
  username?: string;

  constructor(
    courseData: Record<string, any>,
    admitProbability: number,
    username?: string,
  ) {
    this.country =  courseData['universityCountry'];
    this.universityId =  courseData['universityId'];
    this.universityName =  courseData['universityName'];
    this.intake =  '';
    this.courseName =  courseData['courseName'];
    this.courseLevel =  courseData['courseLevel'];
    this.courseId =  courseData['uid'];
    this.areaOfStudy =  courseData['areaOfStudy'];
    this.specialization =  courseData['specialization'];
    this.courseDuration =  courseData['courseDuration'];
    this.admitProbability =  admitProbability;
    this.isOnlineCourse =  courseData['isOnlineCourse'];
    this.courseLink = courseData['websiteURL'];
    this.username =  username;

    // preprocessed
    this.countrySlug = slugify(this.country);
    this.isPremiumUniversity =  courseData['isPartner'] === 'Yes' ? false  :  true;
    this.isCompetitiveExamRequired = this._getIsCompExamRequired(courseData['entryRequirements']);
    this.minEnglishExamScore =  courseData?.entryRequirements?.ielts?.overallRequirement ?? 0;

    const parsedWorkExp = parseInt(courseData['workExperience']);
    this.workExperienceNeeded = isNaN(parsedWorkExp)
      ? false
      : parsedWorkExp > 0 ? true : false;

    const conversionRate = LIST_COUNTRIES
      .find((item) => item.slug === this.country)
      ?.conversionRate ?? 1;

    const parsedAnnualTuitionFees =  parseFloat(courseData['tuitionFeeAmount']);
    this.annualTuitionFees =  isNaN(parsedAnnualTuitionFees) ? 0 : parsedAnnualTuitionFees;
    this.annualTuitionFeesInInr = this.annualTuitionFees * conversionRate;
    this.annualTuitionFeesInr = this.annualTuitionFees * conversionRate;

    const parsedApplicationFees =  parseFloat(courseData['applicationFee']);
    this.applicationFees =  isNaN(parsedApplicationFees) ? 0 : parsedApplicationFees;
    this.applicationFeesInInr = this.applicationFees * conversionRate;
    this.applicationFeesInr = this.applicationFees * conversionRate;

    // set as empty
    this.applicationDeadline =  '';
  }

  _getIsCompExamRequired = (entryRequirements?: Record<string, any>): boolean => {
    let isRequired = false;
    const allCompTestKey = ['gmat', 'gre', 'sat', 'act'];

    if(entryRequirements) {
      for(const [key, value] of  Object.entries(entryRequirements)) {
        if (allCompTestKey.some((i) => i===key)) {
          if(value?.required ?? false) {
            isRequired = true;
            break;
          }
        }
      }
    }

    return isRequired;
  };

  toPayLoad() {
    return ([{
      country : this.country,
      countrySlug : this.countrySlug,
      universityId : this.universityId,
      universityName : this.universityName,
      intake : this.intake,
      courseName : this.courseName,
      courseLevel : this.courseLevel,
      courseId : this.courseId,
      courseLink : this.courseLink,
      areaOfStudy : this.areaOfStudy,
      specialization : this.specialization,
      courseDuration : this.courseDuration,
      annualTuitionFees : this.annualTuitionFees,
      admitProbability : this.admitProbability,
      applicationDeadline : this.applicationDeadline,
      isPremiumUniversity : this.isPremiumUniversity,
      isOnlineCourse : this.isOnlineCourse,
      applicationFees : this.applicationFees,
      isCompetitiveExamRequired : this.isCompetitiveExamRequired,
      minEnglishExamScore : this.minEnglishExamScore,
      workExperienceNeeded : this.workExperienceNeeded,
      username : this.username,
      annualTuitionFeesInInr : this.annualTuitionFeesInInr,
      annualTuitionFeesInr : this.annualTuitionFeesInr,
      applicationFeesInInr : this.applicationFeesInInr,
      applicationFeesInr : this.applicationFeesInr,
    }
    ]);
  }
}

export interface RenderMobileFilterProps {
  selectedFilterKey: string
  filterData: Record<string, any>,
  onSelection: (value: any) => void,
}

export interface RenderDesktopFilterProps {
  selectedFilterKey: string,
  filterData: Record<string, any>,
  onSelection: (value?: any) => void,
}

export interface ACMCityFilterData {
  label: string,
  id: string,
}

export type ChipData = {
  _id?: string;
  label: string;
  slug: string;
  icon: IconName;
  recommended?: boolean,
};
