import { SuaveChip } from '@admitkard/ccl/Suave';
import { SingleSelectItemConfig } from 'components/ListingEngine/types';
import React from 'react';

interface SingleSelectMobileFilterProps {
  itemConfigs: SingleSelectItemConfig[],
  onSelect: (selection?: string) => void;
  selection?: string;
  withIcon?: boolean;
}

const SingleSelectMobileFilter: React.FunctionComponent<SingleSelectMobileFilterProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: '0.5rem',
    }}>
      {props.itemConfigs.map((value) =>
      {
        const isSelected = value.slug === props.selection;
        return (
          <SuaveChip
            key={`single-item-${value.slug}}`}
            label={value.label}
            variant='select-able'
            isSelected={isSelected}
            icon={props.withIcon ? value?.icon : undefined}
            onClick={
              () => {
                const onSelectValue = isSelected === true ? undefined : value.slug;
                props.onSelect(onSelectValue);
              }
            }
          />
        );
      }
      )}
    </div>
  );
};

export default SingleSelectMobileFilter;
