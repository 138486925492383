import css from 'styled-jsx/css';

export default css.global`
.filterContainer {
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}

.filterContainer > div  {
  margin-left: .5rem;
}
.filterContainer > div:nth-child(1)  {
  margin-left: 0rem;
}

/* hide scrollbar in webkit browser */
.filterContainer::-webkit-scrollbar {
  display: none;
}
`;
