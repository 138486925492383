import { Avatar, SuaveButton, SuaveChip, SuaveSearch, SuaveSearchResultItem } from '@admitkard/ccl';
import pushToDataLayer from '@admitkard/uiutils/gTagDataLayer';
import React, { useEffect, useRef, useState } from 'react';
import { PafPageSearchableCity } from '../Types';
import { QuestionHeader } from './Components';
import styles from './questionStyles.css';
import { CustomLoader } from 'components/ListingEngine/CommonComponents';
import { ACMS_BASE_URL } from 'constants/links';
import { Analytics } from '@admitkard/uiutils/analytics/events';

export const SearchableCitySelect: React.FunctionComponent<PafPageSearchableCity> = (props) => {
  const classes = styles();
  const { pageKey } = props;
  const [currentSelection , setCurrentSelection] = useState<{ id: string, label: string } | undefined>(props.pafData[pageKey]);
  const searchVariant = typeof window !== 'undefined' && window.innerWidth < 767 ? 'style2' : 'style1';

  const [isLoading, setIsLoading] = useState(true);
  const topCitiesRef = useRef<SuaveSearchResultItem[]>([]);

  if (props.urlParams.city && !props.pafData[pageKey]) {
    props.setData(props.urlParams.city);
  }

  const handleResult = (value: SuaveSearchResultItem | undefined) => {
    if(value){
      props.setData(value);
      setCurrentSelection(value);
    }else{
      props.setData(undefined);
      setCurrentSelection(undefined);
    }
  };

  const fetchTopCity = async () => {
    const url = `${ACMS_BASE_URL}/cities/top?limit=9&country=${props.countryInfo?.slug}`;
    const response = await fetch(url);
    const results = await response.json();
    if(results && Array.isArray(results)) {
      return results;
    } else {
      return [];
    }
  };

  const isTopCity = (citySlug?: string) => topCitiesRef.current.some((city) => city.id === citySlug);

  useEffect(()=>{
    fetchTopCity().then((data) => {
      topCitiesRef.current = [ ...data ];
      setIsLoading(false);
    });
  },[]);

  const fetchApi = async ( keyword?: string ) => {
    const finalResult: SuaveSearchResultItem[] = [];
    let url;
    if(keyword){
      url = `${ACMS_BASE_URL}/cities?country=${props.countryInfo?.slug}&keyword=${keyword}`;
    }
    else{
      url = `${ACMS_BASE_URL}/cities?country=${props.countryInfo?.slug}`;
    }
    const response = await fetch(url);
    const results = await response.json();
    if(results && Array.isArray(results)) {
      results.forEach((value) => finalResult.push({
        id: value.id,
        label: value.label,
      }));
    }
    return finalResult;
  };

  const goToNext = () => {
    pushToDataLayer({
      event: 'level_city',
      data: {
        ['city']: props.pafData[pageKey].id,
      },
    });
    Analytics.track('level_city',{
      ['city']: props.pafData[pageKey].id,
    });
    Analytics.people.set({
      ['city']: props.pafData[pageKey].id,
    });
    props.goToNext();
  };

  const renderCustomOption = (value : SuaveSearchResultItem)=>{
    return(
      <div className={classes.dropDownContainer}>
        <div className={classes.countryFlag}>
          <Avatar alt={value.id} src={value.value} size='m' noBorder noMargin />
        </div>
        <div>{value.label}</div>
      </div>
    );
  };

  const questionHeader = props?.countryInfo?.label
    ? `mention your city in ${props?.countryInfo?.label}`
    : 'mention your city';

  return (
    <div className={classes.questionContainer}>
      <QuestionHeader
        questionText={questionHeader}
        isFirstPage={props.currentPage === 0}
        onBackClick={props.goToPrevious}
      />
      { isLoading
        ? (<CustomLoader message={`Finding cities in ${props?.countryInfo?.label}`} />)
        : <>
          <div className={classes.searchBarContainer}>
            <SuaveSearch
              id='select-city'
              label={'Search Cities'}
              styleVariant={searchVariant }
              results={(query:string)=>fetchApi(query)}
              onResult={(value) => {
                if(typeof value !== 'string') {
                  handleResult(value);
                }
              }}
              renderCustomOptions={renderCustomOption}
              optionCount ={6}
              autoLoadAtStart
              isScrollable={true}
            />
          </div>

          {
            currentSelection && !isTopCity(currentSelection.id) &&
            <div className={classes.searchChipContainer}>
              <SuaveChip
                key={currentSelection.id ?? 'undefined'}
                label={currentSelection.label ?? 'undefined'}
                icon='globe2'
                size = 'large'
                isSelected
                variant='select-able'
                onClick={()=> handleResult(undefined)}
              />
            </div>
          }

          <div className={classes.optionsContainerMedium}>
            {
              topCitiesRef.current.map((city)=> {
                const isSelected = currentSelection?.id === city.id;
                return <SuaveChip
                  key={city.id}
                  label={city.label}
                  icon='globe2'
                  size='medium'
                  isSelected={isSelected}
                  isInActive={!isSelected && currentSelection !== undefined}
                  onClick={ isSelected ? ()=>handleResult(undefined) : () => handleResult(city)}
                />;
              })
            }
          </div>

          <div className={classes.buttonContainer}>
            <SuaveButton
              id="next-button-city-select"
              variants='primary'
              disabled={props.pafData[pageKey] ? false : true}
              onClick={goToNext}
            >Next</SuaveButton>
          </div>
        </>
      }
    </div>
  );
};
