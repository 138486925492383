import { Button, SuaveChip, SuaveValuedSlider } from '@admitkard/ccl';
import pushToDataLayer from '@admitkard/uiutils/gTagDataLayer';
import React from 'react';
import { PafPageProps } from '../Types';
import { QuestionHeader } from './Components';
import { PAFSubtitle } from './Components/PAFSubtitle';
import styles from './questionStyles.css';
import { Analytics } from '@admitkard/uiutils/analytics/events';

interface WorkExperienceData {
  hasWorkExperience?: boolean;
  numberOfYears?: number;
}

export const WorkExperienceSelect: React.FunctionComponent<PafPageProps> = (props) => {
  const classes = styles();
  const { pageKey } = props;
  const currentData: WorkExperienceData = props.pafData[pageKey] ?? { numberOfYears: 0 };
  const hasSelection = props.pafData[pageKey];
  const goToNext = () => {
    pushToDataLayer({
      event: 'level_workexp',
      data: {
        ['work_experience']: props.pafData[pageKey].hasWorkExperience,
        ['work_experience_years']: props.pafData[pageKey].numberOfYears,
      },
    });
    Analytics.track('level_workexp', {
      ['work_experience']: props.pafData[pageKey].hasWorkExperience,
      ['work_experience_years']: props.pafData[pageKey].numberOfYears,
    });
    Analytics.people.set({
      ['work_experience']: props.pafData[pageKey].hasWorkExperience,
      ['work_experience_years']: props.pafData[pageKey].numberOfYears,
    });
    props.goToNext();
  };
  return (
    <div className={classes.questionContainer}>
      <QuestionHeader
        questionText="do you have any work experience?"
        isFirstPage={props.currentPage === 0}
        onBackClick={props.goToPrevious}
      />
      <div className={classes.optionsContainerSmall}>
        <SuaveChip
          key="yes"
          label="Yes"
          icon={'yes'}
          size="small"
          isSelected={currentData.hasWorkExperience === true}
          isInActive={currentData.hasWorkExperience === false}
          onClick={() => addData(currentData.hasWorkExperience === true ? undefined : { hasWorkExperience: true })}
        />
        <SuaveChip
          key="no"
          label="No"
          icon={'none'}
          size="small"
          isSelected={currentData.hasWorkExperience === false}
          isInActive={currentData.hasWorkExperience === true}
          onClick={() => addData(currentData.hasWorkExperience === false ? undefined : { hasWorkExperience: false })}
        />
      </div>
      {currentData.hasWorkExperience && (
        <>
          <div style={{ margin: '1.25rem 0 0.75rem 0.5rem' }}>
            {<PAFSubtitle label="how many years of experience" />}
          </div>
          <SuaveValuedSlider
            min={0}
            max={6}
            maxLabel={'5+'}
            value={currentData.numberOfYears}
            onChange={(val) => addData({ hasWorkExperience: true, numberOfYears: Number(val) })}
            label="years of experience"
          />
          <div style={{ margin: '4.25rem 0 0 0' }} />
        </>
      )}
      <div className={classes.buttonContainer}>
        <Button
          id="next-button-work-experience-select"
          isRounded={true}
          color="indigo"
          hasArrowIcon
          disabled={!hasSelection}
          onClick={goToNext}
        >
          Next
        </Button>
      </div>
    </div>
  );

  function addData(data?: Partial<WorkExperienceData>): void {
    if (data) {
      if (!data.hasWorkExperience) {
        props.setData({
          hasWorkExperience: false,
          numberOfYears: 0,
        });
      } else {
        props.setData({
          hasWorkExperience: true,
          numberOfYears: data.numberOfYears ?? 0,
        });
      }
    } else {
      props.setData(undefined);
    }
  }
};
