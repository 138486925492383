import { Avatar, SuaveButton, SuaveChip, SuaveSearch, SuaveSearchResultItem } from '@admitkard/ccl';
import pushToDataLayer from '@admitkard/uiutils/gTagDataLayer';
import React, { useState } from 'react';
import { LIST_SEARCHABLE_COUNTRIES } from '../../../Constants';
import { PafPageSearchableCountries } from '../Types';
import { QuestionHeader } from './Components';
import styles from './questionStyles.css';
import { Analytics } from '@admitkard/uiutils/analytics/events';

interface CountryData {
  slug?: string;
  label?: string;
}

export const SearchableCountrySelect: React.FunctionComponent<PafPageSearchableCountries> = (props) => {
  const classes = styles();
  const { pageKey } = props;
  const [currentSelection, setCurrentSelection] = useState<CountryData | undefined>(props.pafData[pageKey]);

  const currentData: CountryData = props.pafData[pageKey] ?? {};
  const searchVariant = typeof window !== 'undefined' && window.innerWidth < 767 ? 'style2' : 'style1';

  if (props.urlParams.country2 && !currentData?.slug) {
    props.setData(props.urlParams.country2);
  }

  const goToNext = () => {
    pushToDataLayer({
      event: 'level_country',
      data: {
        ['country']: currentData?.slug,
      },
    });
    Analytics.track('level_country', { ['country']: currentData.slug ? currentData.slug : 'undefined' });
    Analytics.people.set({ ['country']: currentData.slug ? currentData.slug : 'undefined' });
    props.goToNext();
  };

  // handling search results
  const handleResult = (value: SuaveSearchResultItem) => {
    const cData = {
      label: value.label,
      slug: value.id,
      currency: value?.value?.currency,
    };

    setCurrentSelection(cData);
    props.setData(cData);
  };

  // handling chip selection
  const handleChipSelection = (country: any) => {
    if (country) {
      const cData = {
        label: country.label,
        slug: country.slug,
        currency: country.currency,
      };
      props.setData(cData);
      setCurrentSelection(cData);
    } else {
      props.setData(country);
      setCurrentSelection(country);
    }
  };

  const renderCustomOption = (value: SuaveSearchResultItem) => {
    return (
      <div className={classes.dropDownContainer}>
        <div className={classes.countryFlag}>
          <Avatar alt={value.id} src={value.value} size="m" noBorder noMargin />
        </div>
        <div>{value.label}</div>
      </div>
    );
  };

  return (
    <div className={classes.questionContainer}>
      <QuestionHeader
        questionText="where do you want to find your home?"
        isFirstPage={props.currentPage === 0}
        onBackClick={props.goToPrevious}
      />
      <div id="search-bar" className={classes.searchBarContainer}>
        <SuaveSearch
          id="country"
          key={`country-search-${currentSelection?.slug}`}
          label={'Search Countries'}
          styleVariant={searchVariant}
          results={props?.countries ?? []}
          onResult={(value) => {
            if (typeof value !== 'string') {
              handleResult(value);
            }
          }}
          renderCustomOptions={renderCustomOption}
          optionCount={6}
          isScrollable={true}
        />
      </div>

      {currentSelection && !LIST_SEARCHABLE_COUNTRIES.some((value) => value.slug === currentSelection?.slug) && (
        <div className={classes.searchChipContainer}>
          <SuaveChip
            key={currentSelection?.slug ?? 'undefined'}
            label={currentSelection?.label ?? 'undefined'}
            isSelected
            variant="select-able"
            onClick={() => handleChipSelection(undefined)}
          />
        </div>
      )}

      {
        <div className={classes.optionsContainerMedium}>
          {LIST_SEARCHABLE_COUNTRIES.map((country) => {
            const isSelected = currentData?.slug === country.slug;
            return (
              <SuaveChip
                key={country.slug}
                avatar={{
                  alt: country.slug,
                  src: country.avatar,
                }}
                label={country.label}
                size="medium"
                isSelected={isSelected}
                isInActive={!isSelected && currentData?.slug ? true : false}
                onClick={() => (isSelected ? handleChipSelection(undefined) : handleChipSelection(country))}
              />
            );
          })}
        </div>
      }

      <div className={classes.buttonContainer}>
        <SuaveButton
          id="next-button-country-select"
          variants="primary"
          disabled={currentData?.slug ? false : true}
          onClick={goToNext}
        >
          Next
        </SuaveButton>
      </div>
    </div>
  );
};
