import React, { FunctionComponent } from 'react';
import { Text } from '@admitkard/ccl/Text';
import { SubMenuLinkDetails } from '../headerLinks';
import { theme } from '@admitkard/ccl/style';

interface SubmenuHeaderProps {
  parent?: SubMenuLinkDetails;
  onBack?: () => void;
}

export const SubmenuHeader: FunctionComponent<SubmenuHeaderProps> = (props) => {
  const { parent, onBack } = props;
  return <>
    <div className="mobile-menu-header">
      { parent?.label &&
        <div className="submenu-level-heading">
          <div className="back-arrow" onClick={onBack}>❮</div>
          <div className="heading-text">
            <Text variant="s" lightness={7} weight="bold">{parent?.label}</Text>
          </div>
        </div>
      }
    </div>
    <style jsx>{`
      .mobile-menu-header {
      }
      
      .submenu-level-heading {
        display: flex;
        flex-direction: row;
        align-item: center;
        position: relative;
      }
      .back-arrow {
        flex: 0;
        padding: ${theme.spacingOld(4, 5)};
        color: ${theme.palette.grey[600]};
        position: absolute;
        font-size: ${theme.fontSize.s};
      }
      .heading-text {
        padding: ${theme.spacingOld(4, 5)};
        flex: 1 1 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @media ${theme.mediaQuery.tabletUp} {
        .header-logo {
          display: none;
        }
        .back-arrow {
          display: none;
        }
        .heading-text {
          justify-content: flex-start;
        }
      }
    `}</style>
  </>;
};
