import React from 'react';
import { IconName } from '@admitkard/ccl/Icon';
import { theme } from '@admitkard/ccl/style';
import { EvaluationFormLink } from './HeaderSubDetails/EvaluationFormLink';

interface SubMenuLinkOptions {
  width?: string;
  height?: string;
}
export interface SubMenuLinkDetails {
  name: string;
  label: string | JSX.Element;
  href?: string;
  as?: string;
  children?: SubMenuLinkDetails[];
  icon?: IconName;
  options?: SubMenuLinkOptions;
  raw?: boolean;
  borderBottom?: boolean;
  onClick?: () => void;
}

export const HEADER_LEVEL_COLORS = [theme.palette.grey[100], theme.palette.grey[50], theme.palette.grey[20], theme.palette.grey[10]];

export type TopHeaderLinks = 'explore' | 'search' | 'login' | 'tools';

const EXAM_SECTION_LABEL: Record<string, string> = {
  overview: 'Overview',
  syllabus: 'Syllabus',
  registration: 'Registration',
  dates: 'Exam Dates',
  'results-and-scores': 'Results & Scores',
  'test-centres': 'Test Centres',
  'practice-papers': 'Practice Papers',
  'preparation-tips': 'Preparation Tips',
};

const EXAM_SECTIONS = ['overview',
  'syllabus',
  'registration',
  'results-and-scores',
  'dates',
  'test-centres',
  'practice-papers',
  'preparation-tips',
];

const VISA = [{
  name: 'visa-usa',
  label: 'USA Student Visa',
  children: [{
    name: 'usa-student-visa/overview',
    label: 'US Visa Overview',
    href: '/visa/usa-student-visa/overview',
  }, {
    name: 'usa-student-visa/visa-process',
    label: 'US Visa Process',
    href: '/visa/usa-student-visa/visa-process',
  }, {
    name: 'usa-student-visa/eligibility-and-rules',
    label: 'US Visa Eligibility',
    href: '/visa/usa-student-visa/eligibility-and-rules',
  }, {
    name: 'usa-student-visa/intervie',
    label: 'US Visa Interviews',
    href: '/visa/usa-student-visa/interview'
  }],
}, {
  name: 'visa-australia',
  label: 'Australia Student Visa',
  children: [{
    name: 'australia-student-visa/overview',
    label: 'Australia Visa Overview',
    href: '/visa/australia-student-visa/overview',
  }, {
    name: 'australia-student-visa/visa-process',
    label: 'Australia Visa Process',
    href: '/visa/australia-student-visa/visa-process',
  }, {
    name: 'australia-student-visa/eligibility-and-rules',
    label: 'Australia Visa Eligibility',
    href: '/visa/australia-student-visa/eligibility-and-rules',
  }, {
    name: 'australia-student-visa/intervie',
    label: 'Australia Visa Interviews',
    href: '/visa/australia-student-visa/interview'
  }],
}];

const DOCUMENTS = [{
  name: 'statement-of-purpose',
  label: 'SOP',
  children: [ {
    name: 'sop-overview',
    label: 'SOP Overview',
    href: '/admissions/statement-of-purpose/overview',
  }, {
    name: 'sop-samples',
    label: 'SOP Sample',
    href: '/admissions/statement-of-purpose/sop-samples',
  }, {
    name: 'sop-format',
    label: 'SOP Format',
    href: '/admissions/statement-of-purpose/sop-format',
  }, {
    name: 'sop-writing-tips',
    label: 'SOP Writing' ,
    href: '/admissions/statement-of-purpose/sop-writing-tips',
  }, {
    name: 'sop-writing-services',
    label: 'SOP Editing' ,
    href: '/admissions/statement-of-purpose/sop-writing-services'
  }],
}, {
  name: 'letter-of-recommendation',
  label: 'LOR',
  children: [{
    name: 'overview',
    label: 'LOR Overview',
    href: '/admissions/letter-of-recommendation/overview',
  }, {
    name: 'lor-samples',
    label: 'LOR Sample',
    href: '/admissions/letter-of-recommendation/lor-samples',
  }, {
    name: 'lor-format',
    label: 'LOR Format',
    href: '/admissions/letter-of-recommendation/lor-format',
  }, {
    name: 'lor-writing-tip',
    label: 'LOR Writing' ,
    href: '/admissions/letter-of-recommendation/lor-writing-tips'
  }],
}, {
  name: 'admission-essays',
  label: 'Essay',
  children: [{
    name: 'overview',
    label: 'Essay Overview',
    href: '/admissions/admission-essays/overview',
  }, {
    name: 'type-of-essays',
    label: 'Essay Types',
    href: '/admissions/admission-essays/type-of-essays',
  }, {
    name: 'essay-samples',
    label: 'Essay Samples',
    href: '/admissions/admission-essays/essay-samples',
  }, {
    name: 'essay-guideline',
    label: 'Essay Guidelines',
    href: '/admissions/admission-essays/essay-guidelines',
  }],
}, {
  name: 'student-cv',
  label: 'CV',
  children: [{
    name: 'overview',
    label: 'CV Overview',
    href: '/admissions/student-cv/overview',
  }, {
    name: 'cv-samples',
    label: 'CV Sample',
    href: '/admissions/student-cv/cv-samples',
  }, {
    name: 'cv-format',
    label: 'CV Format',
    href: '/admissions/student-cv/cv-format',
  }, {
    name: 'cv-guidelines',
    label: 'CV Guideliens',
    href: '/admissions/student-cv/cv-guidelines',
  }],
}, {
  name: 'others',
  label: 'Others',
  children: [{
    name: 'transcripts',
    label: 'Transcripts',
    href: '/admissions/transcripts',
  }]
}];

const BY_COUNTRY = [
  { // usa
    name: 'usa',
    label: 'USA',
    children: [{
      name: 'btech+colleges+in+united-states',
      label: 'BTech in USA',
      href: '/search/btech+colleges+in+united-states',
    }, {
      name: 'mba+colleges+in+united-states',
      label: 'MBA in USA',
      href: '/search/mba+colleges+in+united-states',
    }, {
      name: 'colleges+in+united-states',
      label: 'All courses in USA',
      href: '/search/colleges+in+united-states',
    }],
  }, { // australia
    name: 'australia',
    label: 'Australia',
    children: [{
      name: 'btech+colleges+in+australia',
      label: 'BTech in Australia',
      href: '/search/btech+colleges+in+australia',
    }, {
      name: 'mba+colleges+in+australia',
      label: 'MBA in Australia',
      href: '/search/mba+colleges+in+australia',
    }, {
      name: 'colleges+in+australia',
      label: 'All courses in Australia',
      href: '/search/colleges+in+australia',
    }],
  }, { //canada
    name: 'canada',
    label: 'Canada',
    children: [{
      name: 'btech+colleges+in+canada',
      label: 'BTech in Canada',
      href: '/search/btech+colleges+in+canada',
    }, {
      name: 'mba+colleges+in+canada',
      label: 'MBA in Canada',
      href: '/search/mba+colleges+in+canada',
    }, {
      name: 'colleges+in+canada',
      label: 'All courses in Canada',
      href: '/search/colleges+in+canada',
    }],
  }, { //uk
    name: 'uk',
    label: 'UK',
    children: [{
      name: 'btech+colleges+in+united-kingdom',
      label: 'BTech in UK',
      href: '/search/btech+colleges+in+united-kingdom',
    }, {
      name: 'mba+colleges+in+united-kingdom',
      label: 'MBA in UK',
      href: '/search/mba+colleges+in+united-kingdom',
    }, {
      name: 'colleges+in+united-kingdom',
      label: 'All courses in UK',
      href: '/search/colleges+in+united-kingdom',
    }],
  }, { // newzealand
    name: 'new zealand',
    label: 'New Zealand',
    children: [{
      name: 'btech+colleges+in+new-zealand',
      label: 'BTech in New Zealand',
      href: '/search/btech+colleges+in+new-zealand',
    }, {
      name: 'mba+colleges+in+new-zealand',
      label: 'MBA in New Zealand',
      href: '/search/mba+colleges+in+new-zealand',
    }, {
      name: 'colleges+in+new-zealand',
      label: 'All courses in New Zealand',
      href: '/search/colleges+in+new-zealand',
    }],
  }, { // singapore
    name: 'singapore',
    label: 'Singapore',
    children: [{
      name: 'btech+colleges+in+singapore',
      label: 'BTech in Singapore',
      href: '/search/btech+colleges+in+singapore',
    }, {
      name: 'mba+colleges+in+singapore',
      label: 'MBA in Singapore',
      href: '/search/mba+colleges+in+singapore',
    }, {
      name: 'colleges+in+singapore',
      label: 'All courses in Singapore',
      href: '/search/colleges+in+singapore',
    }]
  },
];

const BY_DEGREES = [
  { // degree
    name: 'top degrees',
    label: 'Top Degrees',
    children: [{
      name: 'mba',
      label: 'MBA',
      href: '/search/mba+colleges',
    }, {
      name: 'btech',
      label: 'BTech',
      href: '/search/btech+colleges',
    }, {
      name: 'baf',
      label: 'BAF',
      href: '/search/baf+colleges',
    }, {
      name: 'bba',
      label: 'BBA',
      href: '/search/bba+colleges',
    }, {
      name: 'pgdm',
      label: 'PGDM',
      href: '/search/pgdm+colleges',
    }, {
      name: 'bfm',
      label: 'BFM',
      href: '/search/bfm+colleges'
    }],
  },
];

const BY_AOS = [
  { // engineering
    name: 'engineering',
    label: 'Engineering',
    children: [{
      name: 'engineering+colleges+in+united-states',
      label: 'Engineering in USA',
      href: '/search/engineering+colleges+in+united-states',
    }, {
      name: 'engineering+colleges+in+australia',
      label: 'Engineering in Australia',
      href: '/search/engineering+colleges+in+australia',
    }, {
      name: 'engineering+colleges+in+canada',
      label: 'Engineering in Canada',
      href: '/search/engineering+colleges+in+canada',
    }]
  }, { // business
    name: 'business & management',
    label: 'Business & Management',
    children: [{
      name: 'business-and-management+colleges+in+united-states',
      label: 'Business & Mgt. in USA',
      href: '/search/business-and-management+colleges+in+united-states',
    }, {
      name: 'business-and-management+colleges+in+australia',
      label: 'Business & Mgt. in Australia',
      href: '/search/business-and-management+colleges+in+australia',
    }, {
      name: 'business-and-management+colleges+in+canada',
      label: 'Business & Mgt. in Canada',
      href: '/search/business-and-management+colleges+in+canada',
    }],
  }, { // cs
    name: 'computer science & it',
    label: 'Computer Science & IT',
    children: [{
      name: 'computer-science-and-it+colleges+in+united-states',
      label: 'Computer Science & IT in USA',
      href: '/search/computer-science-and-it+colleges+in+united-states',
    }, {
      name: 'computer-science-and-it+colleges+in+australia',
      label: 'Computer Science & IT in Australia',
      href: '/search/computer-science-and-it+colleges+in+australia',
    }, {
      name: 'computer-science-and-it+colleges+in+canada',
      label: 'Computer Science & IT in Canada',
      href: '/search/computer-science-and-it+colleges+in+canada',
    }],
  },
];

export const HEADER_LINKS_NEW: Record<TopHeaderLinks, SubMenuLinkDetails> = {
  explore: {
    name: 'explore',
    label: 'Explore',
    children: [
      { // Exams
        name: 'exams',
        label: 'Exams',
        children: ['ielts', 'gmat', 'gre', 'sat', 'pte', 'toefl'].map((exam) => ({
          name: exam,
          label: exam.toUpperCase(),
          children: EXAM_SECTIONS.map((examSection) => ({
            name: `${exam}__${examSection}`,
            label: `${exam.toUpperCase()} ${EXAM_SECTION_LABEL[examSection]}`,
            href: `/exams/${exam}/${examSection}`,
          })),
        })),
      }, { // Documents
        name: 'documnents',
        label: 'Documents',
        children: DOCUMENTS,
      }, { // Visa
        name: 'visa',
        label: 'Visa',
        children: VISA,
      }, { // Mentors
        name: 'mentors',
        label: 'Mentors',
        children: [{
          name: 'connect',
          label: 'Connect with Mentors',
          href: '/connect-with-mentors',
        }, {
          name: 'become-a-mentor',
          label: 'Become a Mentor',
          href: '/mentor-signup',
        }],
      }, {
        name: 'blog',
        label: 'AdmitKard Blogs',
        href: 'https://www.admitkard.com/blog',
      },
    ],
  },
  search: {
    name: 'search',
    label: 'Search',
    children: [
      {
        name: 'country',
        label: 'By Country',
        children: BY_COUNTRY,
      }, {
        name: 'area-of-study',
        label: 'By Area of Study',
        children: BY_AOS,
      }, {
        name: 'degrees',
        label: 'By Degrees',
        children: BY_DEGREES,
      },
    ],
  },
  login: {
    name: 'login',
    label: 'Get Started',
    children: [
      {
        name: 'student',
        label: 'Student Login',
        href: '/student/login/',
      }, {
        name: 'mentor',
        label: 'Mentor Login',
        href: 'https://odyssey.admitkard.com/mentor/signup',
      },
    ],
  },
  tools: {
    label: 'Tools',
    name: 'tools',
    options: {
      width: 'auto',
      height: 'auto',
    },
    children: [{
      name: 'evaluation-form',
      label: <EvaluationFormLink />,
      raw: true,
    }],
  }
};
