import { cn, SuaveChip, SuaveSearch, SuaveSearchResultItem } from '@admitkard/ccl';
import { makeStyles } from '@admitkard/ccl/style';
import React from 'react';
import { StaticSearchResult } from '../../../types';

const useStyles = makeStyles((theme) => ({
  selectedResultContainer: {
    marginBottom: '20px',
  },
  selectedResultContainerWithResult: {
    marginTop: '1rem',
  },
  recommendationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem'
  },
  recommendationsLabel: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '23px',
    marginLeft: '1rem',
    color: theme.colorPalette.indigo.main,
  }
}), {name: 'SearchFilter'});

interface SearchFilterProps {
  onSelect: (selection?: SuaveSearchResultItem) => void;
  staticSearchResult: StaticSearchResult[];
  selection?: SuaveSearchResultItem;
}

const SearchFilter: React.FunctionComponent<SearchFilterProps> = (props) => {
  const classes = useStyles();

  const recommendResults = props.staticSearchResult.filter((item) => item.isRecommended);

  return (
    <div>
      <SuaveSearch
        id='desktop-filter'
        label='Search'
        autoWidth
        backgroundColor='lavender'
        onResult={
          (result) =>
            typeof result === 'object'
              ? props.onSelect(result)
              : () => {
                // TODO: Handle freeSolo result
              }
        }
        results={props.staticSearchResult}
      />
      <div className={cn(classes.selectedResultContainer,
        {[classes.selectedResultContainerWithResult]: props.selection ? true : false}
      )} >
        { props.selection && <SuaveChip
          label={props.selection.label}
          variant='select-able'
          size='xxl'
          backgroundColor='lavender'
          isSelected={props.selection ? true : false}
          onClick={ () => props.onSelect() }
        />
        }
      </div>
      <div className={classes.recommendationsContainer}>
        <span className={classes.recommendationsLabel}>Recommended</span>
        {recommendResults.map((value) => (
          <SuaveChip
            key={`recommendation-${value.id}}`}
            label={value.label}
            size='xxl'
            backgroundColor='lavender'
            onClick={ () => props.onSelect(value) }
          />
        ) )}
      </div>
    </div>
  );
};

export default SearchFilter;
