import { env } from '@admitkard/uiutils/env';
import { AxiosError, BackendErrorObject, http } from '@admitkard/uiutils/http';
import { ShortlistPayload } from 'components/ListingEngine/types';
import { ACMS_BASE_URL } from 'constants/links';
import { AccommodationShortlist } from 'components/ListingEngine/AccommodationListingPages/AccommodationListing/AccommodationListingPage';

export const getCount = (query?: any) => {
  return http.post<any>(`${env.COSMOS_API}/prelogin/coursecountfilter`, query).then((response: any) => response.data);
};

export const getCourseWithAdmitProbability = (query?: any, skip?: number) => {
  return http
    .post<any>(`${env.COSMOS_API}/prelogin/admitprobability/v2?limit=9&skip=${skip ?? 0}`, query)
    .then((response: any) => response.data);
};

export const getCourseWithAdmitProbability2 = (query?: any, skip?: number) => {
  return http
    .post<any>(`${env.COSMOS_API}/prelogin/admitprobability/v2?limit=15&skip=${skip ?? 0}`, query)
    .then((response: any) => response.data);
};

export const getMoreCourses = (query?: any) => {
  return http.post<any>(`${env.COSMOS_API}/prelogin/admitprobability/v2`, query);
};

export const handleGetCount = (query?: any) => {
  return getCount(query)
    .then((response: any) => {
      return response;
    })
    .catch((error: AxiosError<BackendErrorObject>) => {
      if (error.response) {
        return null;
      }
      return null;
    });
};

export const handleGetCourseWithAdmitProbability = (query: any, skip: number) => {
  return getCourseWithAdmitProbability(query, skip)
    .then((response: any) => {
      return response;
    })
    .catch((error: AxiosError<BackendErrorObject>) => {
      if (error.response) {
        return null;
      }
      return null;
    });
};

export const handleGetCourseWithAdmitProbability2 = (query: any, skip: number) => {
  return getCourseWithAdmitProbability2(query, skip)
    .then((response: any) => {
      return response;
    })
    .catch((error: AxiosError<BackendErrorObject>) => {
      if (error.response) {
        return null;
      }
      return null;
    });
};

export const postShortlist = (payload: ShortlistPayload) => {
  return http.post(`${env.AMS_API}/auth/tracker/shortlist`, [payload.toPayLoad()]);
};
interface PostPreferences {
  userId: string;
  studentUiPreferences?: any;
  shortlistedCourses?: string[];
  shortlistedScholarships?: string[];
  shortlistedAccommodations?: AccommodationShortlist[];
}
export const postPreferences = ({
  userId,
  studentUiPreferences,
  shortlistedCourses,
  shortlistedScholarships,
  shortlistedAccommodations,
}: PostPreferences) => {
  let payload;
  if (shortlistedCourses && Array.isArray(shortlistedCourses)) {
    payload = {
      studentUiPreferences: {
        ...studentUiPreferences,
        courseShortlisted: shortlistedCourses,
      },
    };
  }
  if (shortlistedScholarships && Array.isArray(shortlistedScholarships)) {
    payload = {
      studentUiPreferences: {
        ...studentUiPreferences,
        scholarshipShortlisted: shortlistedScholarships,
      },
    };
  }
  if (shortlistedAccommodations && Array.isArray(shortlistedAccommodations)) {
    payload = {
      studentUiPreferences: {
        ...studentUiPreferences,
        accommodationShortlisted: shortlistedAccommodations,
      },
    };
  }
  return http.patch(`${env.SMS_API}auth/student-profile/${userId}`, payload);
};

export const getScholarships = (query: Record<string, any>, skip: number) => {
  return http
    .get<any>(`${env.COSMOS_API}/scholarships/?limit=9&skip=${skip ?? 0}`, { params: { ...query } })
    .then((response: any) => response.data);
};

export const getAccommodations = (query: Record<string, any>, skip: number) => {
  let sortByParam = '';
  if (query.sortBy) {
    sortByParam = `&sortBy=${query.sortBy}`;
  }
  return http
    .post<any>(`${ACMS_BASE_URL}/?limit=9${sortByParam}&skip=${skip ?? 0}`, { ...query })
    .then((response: any) => ({ data: response.data.data, totalCount: response.data.total }));
};

export const getAccommodations2 = (query: Record<string, any>, skip: number) => {
  let sortByParam = '';
  if (query.sortBy) {
    sortByParam = `&sortBy=${query.sortBy}`;
  }
  return http
    .post<any>(`${ACMS_BASE_URL}/?limit=15${sortByParam}&skip=${skip ?? 0}`, { ...query })
    .then((response: any) => ({ data: response.data.data, totalCount: response.data.total }));
};

export const getScholarships2 = (query: Record<string, any>, skip: number) => {
  return http
    .get<any>(`${env.COSMOS_API}/scholarships/?limit=15&skip=${skip ?? 0}`, { params: { ...query } })
    .then((response: any) => response.data);
};
