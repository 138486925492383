import React, { FunctionComponent, useState } from 'react';
import { SubMenuLinkDetails } from './headerLinks';
import { SubMenuLevel } from './HeaderSubDetails';
import { ImgInfoCard } from '@admitkard/ccl/ImgInfoCard';
import { Avatar } from '@admitkard/ccl/Avatar';
import { ff } from '@admitkard/uiutils/featureFlags';
import { Text } from '@admitkard/ccl';

interface UserMenuProps {
  userDetails?: Record<string, string>;
  onLogoutClick: () => void;
  theme?: string;
}

interface UserDetailsProps {
  userDetails?: Record<string, string>;
}
const UserDetails: FunctionComponent<UserDetailsProps> = (props) => {
  return props.userDetails ? <ImgInfoCard
    heading={props.userDetails.firstName}
    additionalContent={<span style={{fontSize: '0.625rem'}}>{props.userDetails.email}</span>}
    imgSrc={props.userDetails.profilePicUrl}
    imgAlt={props.userDetails.firstName}
    size="m"
  /> : <></>;
};

const UserDetailsSuave: FunctionComponent<UserDetailsProps> = (props) => {
  return props.userDetails ? <div style={{display:'flex', flexDirection: 'column'}}>
    <Text color='indigo'>
      {props.userDetails.firstName}
    </Text>
    <Text color='indigo' variant='xs'>
      {props.userDetails.email}
    </Text>
  </div>
    : <></>;
};

export const getUserLinks = (props: UserMenuProps) => {
  const USER_LINKS_CHILDREN: SubMenuLinkDetails[] = [{
    name: 'profile',
    label: props.userDetails ? props.theme === 'suave' ? <UserDetailsSuave userDetails={props.userDetails}/>
      : <UserDetails userDetails={props.userDetails} /> : '',
    href: '/student/profile?src=header_menu',
    borderBottom: true,
  }, {
    name: 'dashboard',
    label: props.theme === 'suave' ? 'dashboard' : 'Dashboard',
    href: '/student?src=header_menu',
  }, {
    name: 'profile-text',
    label: props.theme === 'suave' ? 'view profile' : 'View Profile',
    href: '/student/profile?src=header_menu_profiletext',
  }];

  if (ff.isEnabled('help_section')) {
    USER_LINKS_CHILDREN.push({
      name: 'help',
      label: props.theme === 'suave' ? 'help & support' : 'Help & Support',
      href: '/support?src=header_menu',
    });
  }

  USER_LINKS_CHILDREN.push({
    name: 'Logout',
    label: props.theme === 'suave' ? 'log out' : 'Logout',
    onClick: props.onLogoutClick
  });

  return USER_LINKS_CHILDREN;
};

export const UserMenu: FunctionComponent<UserMenuProps> = (props) => {
  const [ isMenuActive, setIsMenuActive ] = useState(false);
  const [ timer, setTimer ] = useState(0);

  const USER_LINKS: SubMenuLinkDetails = {
    label: '',
    name: 'user',
    options: {
      width: '16rem',
      height: 'auto',
    },
    children: getUserLinks(props),
  };

  const handleLinkEnterClick = () => {
    window.clearTimeout(timer);
    setTimer(window.setTimeout(() => {
      setIsMenuActive(true);
    }, 151));
  };

  const handleLinkExit = () => {
    window.clearTimeout(timer);
    setTimer(window.setTimeout(() => {
      setIsMenuActive(false);
    }, 150));
  };

  return <>
    <div
      className="login-subheader-user"
      onClick={handleLinkEnterClick}
      onMouseOver={handleLinkEnterClick}
      onMouseEnter={handleLinkEnterClick}
      onMouseLeave={handleLinkExit}
    >
      { props.userDetails &&
        <div className="login-subheader-user">
          <Avatar src={props.userDetails.profilePicUrl} alt={props.userDetails.firstName} />
          <div className="login-subheader-menu-container">
            <SubMenuLevel theme={props.theme} parent={USER_LINKS} isActive={isMenuActive} links={USER_LINKS.children}/>
          </div>
        </div>
      }
    </div>
    <style jsx>{`
      .login-subheader-menu-container {
        top: 80px;
        position: absolute;
        transform: translateX(-11rem);
        right: ${props.theme === 'suave' ? '85px' : '' }
      }
      .login-subheader-user {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
      }
    `}</style>
  </>;
};

