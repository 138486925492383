export const FOOTER_LINKS = [
  {
    name: 'AdmitKard Platform',
    availableLinks: [
      {
        name: 'Free Profile Evaluation',
        link: '/student/login/register?src=footer',
      },
      {
        name: 'Connect with Mentors',
        link: '/connect-with-mentors/?src=footer',
      },
      {
        name: 'Become a mentor',
        link: '/mentor-signup/?src=footer',
      },
      {
        name: 'Blog',
        link: '/blog/?src=footer',
      },
    ],
  },
  {
    name: 'Top Countries',
    availableLinks: [
      {
        name: 'USA',
        link: '/search/colleges+in+united-states/?src=footer',
      },
      {
        name: 'Australia',
        link: '/search/colleges+in+australia/?src=footer',
      },
      {
        name: 'Canada',
        link: '/search/colleges+in+canada/?src=footer',
      },
      {
        name: 'UK',
        link: '/search/colleges+in+united-kingdom/?src=footer',
      },
      {
        name: 'New Zealand',
        link: '/search/colleges+in+new-zealand/?src=footer',
      },
      {
        name: 'Singapore',
        link: '/search/colleges+in+singapore/?src=footer',
      },
    ],
  },
  {
    name: 'Top Exams',
    availableLinks: [
      {
        name: 'SAT',
        link: '/exams/sat/overview/?src=footer',
      },
      {
        name: 'GRE',
        link: '/exams/gre/overview/?src=footer',
      },
      {
        name: 'PTE',
        link: '/exams/pte/overview/?src=footer',
      },
      {
        name: 'TOEFL',
        link: '/exams/toefl/overview/?src=footer',
      },
      {
        name: 'GMAT',
        link: '/exams/gmat/overview/?src=footer',
      },
      {
        name: 'IELTS',
        link: '/exams/ielts/overview/?src=footer',
      },
    ],
  },
  {
    name: 'Top Degrees',
    availableLinks: [
      {
        name: 'MBA',
        link: '/search/mba+colleges/',
      },
      {
        name: 'B.Tech',
        link: '/search/btech+colleges/',
      },
      {
        name: 'MCA',
        link: '/search/mca+colleges/',
      },
      {
        name: 'BCA',
        link: '/search/bca+colleges/',
      },
      {
        name: 'MA',
        link: '/search/ma+colleges/',
      },
      {
        name: 'BA',
        link: '/search/ba+colleges/',
      },
    ],
  },
  {
    name: 'Top Departments',
    availableLinks: [
      {
        name: 'Mechanical Engineering',
        link: '/search/mechanical-engineering+colleges/',
      },
      {
        name: 'Civil Engineering',
        link: '/search/civil-engineering+colleges/',
      },
      {
        name: 'Architecture',
        link: '/search/architecture+colleges/',
      },
      {
        name: 'Event Management',
        link: '/search/event-management+colleges/',
      },
      {
        name: 'Business Administration',
        link: '/search/business-administration+colleges/',
      },
      {
        name: 'Data & Analytics',
        link: '/search/data-and-analytics+colleges/',
      },
    ],
  },
  {
    name: 'Top Streams',
    availableLinks: [
      {
        name: 'Engineering',
        link: '/search/engineering+colleges/',
      },
      {
        name: 'Business & Mgmt.',
        link: '/search/business-and-management+colleges/',
      },
      {
        name: 'Computer Science & IT',
        link: '/search/computer-science-and-it+colleges/',
      },
      {
        name: 'Law',
        link: '/search/law+colleges/',
      },
      {
        name: 'Science',
        link: '/search/science+colleges/',
      },
      {
        name: 'Fine Arts',
        link: '/search/fine-arts+colleges/',
      },
    ],
  },
];
