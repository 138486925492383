import { Button, cn, Drawer, theme, makeStyles } from '@admitkard/ccl';
import Router from 'next/router';
import React, { useEffect } from 'react';

interface MobileHamMenuProps {
  variant: 'blue' | 'white';
  isLoggedIn?: boolean | null;
  userDetails?: Record<string, any>;
  onLogoutClick: () => void;
  setOpen: (open: boolean) => any;
  open: boolean;
}

const NAV_HEADER_HEIGHT = '52px';
const useStyles = makeStyles({
  button: {
    margin: '0rem 2rem',
    order: 1,
  },
  cardButtonContainer: {
    height: '3rem',
    width: 'max-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem 1rem 1rem 1.25rem',
    gap: '0.75rem',
    background: theme.colorPalette.indigo.main,
    borderRadius: '1.875rem',
    boxSizing: 'border-box',
    fontSize: '0.875rem',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    lineHeight: '1rem',
    fontWeight: 400,
    color: '#F7EFFF',
    border: 'none',
    textDecoration: 'none',
    '&:hover': {
      transform: 'translateY(-0.2rem)',
      boxShadow: '0px 3px 0px rgb(0 0 0 / 25%)',
    },
    '@media screen and (min-width: 992px)': {
      fontSize: '1.1rem',
      height: '3.5rem',
    },
  },
  cardButtonIconContainer: {
    height: '1.25rem',
    aspectRatio: '1',
    '@media screen and (min-width: 992px)': {
      height: '1.5rem',
    },
    '& svg': {
      height: 'inherit',
      width: 'inherit',
    },
  },
});
const MobileHamMenu: React.FunctionComponent<MobileHamMenuProps> = (props) => {
  const classes = useStyles(props);
  const toggleDrawer = () => props.setOpen(!props.open);
  const closeDrawer = () => props.setOpen(false);

  useEffect(() => {
    Router.events.on('routeChangeStart', closeDrawer);
    (window.addEventListener as any)('pushState', closeDrawer);
  }, []);

  return (
    <>
      <div
        className={cn('nav-icon', {
          ['open']: props.open,
        })}
        onClick={toggleDrawer}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <Drawer open={props.open} onClose={closeDrawer} anchor="left">
        <div className="mobile-menu-container">
          {props.isLoggedIn && (
            <div
              onClick={() => {
                Router.push('/student/dashboard');
              }}
              className="mobile-menu-item-container"
            >
              dashboard
              <div className="mobile-menu-item-container-divider" />
            </div>
          )}
          <div
            onClick={() => {
              if (props.isLoggedIn) {
                Router.push(`/paf?journey=DEFAULT_PAF_FLOW_NO_SUBMIT`);
              } else {
                Router.push(`/paf?journey=DEFAULT_PAF_FLOW`);
              }
              Router.push('/paf');
            }}
            className="mobile-menu-item-container"
            id="button-mobile-header-coursefinder"
          >
            course finder
            <div className="mobile-menu-item-container-divider" />
          </div>
          <div
            onClick={() => {
              Router.push('/blog');
            }}
            className="mobile-menu-item-container"
            id="button-mobile-header-blog"
          >
            blog
            <div className="mobile-menu-item-container-divider" />
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'absolute',
              bottom: '100px',
              left: '0',
              right: '0',
            }}
          >
            {props.isLoggedIn ? (
              <Button
                color="lightOrange"
                onClick={() => {
                  props.onLogoutClick();
                }}
                isRounded
                hasArrowIcon
              >
                Log out
              </Button>
            ) : (
              <div className={classes.button}>
                <a id="cta-button" href="/login" className={classes.cardButtonContainer}>
                  Register
                  <span className={classes.cardButtonIconContainer}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="24" height="24" rx="12" fill="#F7EFFF" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.8425 11.8281C12.9207 11.75 12.9207 11.6234 12.8425 11.5453L9.69108 8.39379C9.49582 8.19853 9.49582 7.88195 9.69108 7.68668C9.88634 7.49142 10.2029 7.49142 10.3982 7.68668L14.0446 11.3331C14.2399 11.5284 14.2399 11.845 14.0446 12.0402L10.3982 15.6867C10.2029 15.8819 9.88634 15.8819 9.69108 15.6867C9.49582 15.4914 9.49581 15.1748 9.69108 14.9796L12.8425 11.8281Z"
                        fill="#483287"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            )}
          </div>
        </div>
      </Drawer>
      <style jsx>
        {`
          :global(.MuiPaper-root) {
            margin-top: ${NAV_HEADER_HEIGHT};
            background-color: transparent;
          }
          :global(.MuiBackdrop-root) {
            margin-top: ${NAV_HEADER_HEIGHT};
            background-color: transparent;
          }
          .nav-icon {
            width: 1.25rem;
            height: 0.875rem;
            position: relative;
            cursor: pointer;
            display: inline-block;
          }
          @media only screen and (min-width: 600px) {
            .nav-icon {
              display: none;
            }
          }
          .nav-icon span {
            background-color: ${props.variant === 'white' ? 'hsla(256, 46%, 36%, 1)' : 'white'};
            position: absolute;
            border-radius: 0.125rem;
            transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
            width: 100%;
            height: 0.125rem;
            transition-duration: 500ms;
          }
          .nav-icon span:nth-child(1) {
            top: 0rem;
            left: 0rem;
          }
          .nav-icon span:nth-child(2) {
            top: 0.375rem;
            left: 0rem;
            width: 80%;
            opacity: 1;
          }
          .nav-icon span:nth-child(3) {
            bottom: 0rem;
            left: 0rem;
          }
          .nav-icon.open span:nth-child(1) {
            transform: rotate(45deg);
            top: 0.375rem;
          }
          .nav-icon.open span:nth-child(2) {
            opacity: 0;
          }
          .nav-icon.open span:nth-child(3) {
            transform: rotate(-45deg);
            top: 0.375rem;
          }
          .mobile-menu-container {
            width: 100vw;
            box-sizing: border-box;
            background-color: white;
            height: -moz-available;
            height: -webkit-fill-available;
            display: flex;
            flex-direction: column;
            position: relative;
          }
          .mobile-menu-item-container {
            margin: 1.25rem auto 0;
            width: fit-content;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 1.25rem;
            line-height: 1.875rem;
            color: #483287;
            text-align: center;
          }
          .mobile-menu-item-container-divider {
            height: 0.0625rem;
            background-color: hsla(270, 100%, 97%, 1);
            width: 14.375rem;
            margin-top: 1.25rem;
          }
        `}
      </style>
    </>
  );
};

export default MobileHamMenu;
