import { createStyles, withStyles, WithStyles } from '@admitkard/ccl';
import { CourseLandingFilterMobilePopup, FilterItemProps, SuaveButton } from '@admitkard/ccl/Suave';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { useState } from 'react';
import { BaseCustomFilter, RenderMobileFilterProps, SpecialFilterItemProps } from '../../types';

const styles = createStyles({
  root: {
    margin: 0,
    padding: 0,
  },
  paper: {
    margin: '0',
    padding: '0',
    width: '100%',
    bottom: '0',
    height: 'auto',
    marginTop: 'auto',
    borderTopRightRadius: '20px',
    borderTopLeftRadius: '20px',
  },
});

export type FilterMobileDialogState = {
  filterConfigs: SpecialFilterItemProps[],
  filterData: Record<string, any>,
};

interface FilterMobileDialogProps extends WithStyles<typeof styles>  {
  open: boolean,
  selectedFilterKey: string;
  filterState: FilterMobileDialogState,
  onClose: (value?: Record<string, any>) => void;
  specialFilterRenderer?: React.FunctionComponent<BaseCustomFilter<any>>;
  filterRenderer: React.FunctionComponent<RenderMobileFilterProps>,
  defaultInitialFilters?: Record<string, any>,
  hideClearAllBtn?:boolean;
}

const Transition = React.forwardRef((
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

Transition.displayName='slide-up-transition';

const FilterMobileDialog = (props: FilterMobileDialogProps) => {
  const specialFilterIndex = props.filterState.filterConfigs.findIndex((item) => (item.isSpecial === true));
  const specialFilter = (specialFilterIndex === -1) ? undefined : props.filterState.filterConfigs[specialFilterIndex];
  const filterConfigsWithOutSpecial = props.filterState.filterConfigs;

  const {open, classes } = props;
  const [filterData, setFilterData] = useState<Record<string, any>>(props.filterState.filterData);
  const [selectedFilterKey, setSelectedFilterKey] = useState(props.selectedFilterKey === specialFilter?.key
    ? filterConfigsWithOutSpecial[0].key
    : props.selectedFilterKey);

  const handleApplyAndClose = () => {
    props.onClose(filterData);
  };

  const handleClose = () => {
    props.onClose(props.filterState.filterData);
  };

  const handleClearAll = () => {
    setFilterData({ ...props.defaultInitialFilters });
  };

  return(
    <Dialog
      classes={classes}
      fullWidth
      TransitionComponent={Transition}
      open={open} >
      <DialogContent style={{padding: 0}} >
        <FilterMobileContent
          selectedFilterKey={selectedFilterKey}
          filterConfigs={[ ...filterConfigsWithOutSpecial ]}
          filterData={{...filterData }}
          onExit={handleClose}
          onFilterDataChange={setFilterData}
          onSelectionChange={setSelectedFilterKey}
          filterRenderer={props.filterRenderer}
          specialFilterProps={(specialFilter && props.specialFilterRenderer)
            ? {
              key: specialFilter?.key,
              label: specialFilter?.label,
              content: (<props.specialFilterRenderer
                onSelect={(value) => setFilterData(prepareFilterData(
                  specialFilter?.key,
                  filterData,
                  value,
                ))}
                selection={filterData[specialFilter?.key]}
                filterData={filterData}
              />)
            }
            :undefined
          }
        />
      </DialogContent>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '8px 0px',
        columnGap: '8px',
      }} >
        <SuaveButton
          onClick={handleApplyAndClose}
          variants='primary'>
           Apply
        </SuaveButton>
        {!props.hideClearAllBtn && <SuaveButton
          onClick={handleClearAll}
          id="clearAll-cta"
          variants='secondary'>
        Clear all
        </SuaveButton>
        }
      </div>
    </Dialog>
  );
};

type FilterMobileContentProps = FilterMobileDialogState & {
  selectedFilterKey: string,
  onExit: () => void,
  onFilterDataChange: (filterData: Record<string, any>) => void,
  onSelectionChange: (filterKey: string) => void,
  specialFilterProps?: FilterItemProps,
  filterRenderer: React.FunctionComponent<RenderMobileFilterProps>
};

const FilterMobileContent: React.FunctionComponent<FilterMobileContentProps> = (props) => {

  const { selectedFilterKey } = props;

  const filterConfigsWithSelection = props.filterConfigs.map((fProp) => {
    const filterHasSelection = props.filterData[fProp.key] ? true : false;
    return (
      { ...fProp, hasSelection:filterHasSelection }
    );
  });

  const handleSingleSelect = (data: any) => {
    const tData = props.filterData;

    if (data) {
      tData[selectedFilterKey] = data;

      if(selectedFilterKey === 'area-of-study-filter') {
        delete tData['specialization-filter'];
      }
      if (selectedFilterKey === 'course-level-filter') {
        delete tData['academic-proficiency-exam-filter'];
      }

    } else {
      delete tData[selectedFilterKey];
    }

    props.onFilterDataChange(tData);
  };

  return (
    <CourseLandingFilterMobilePopup
      filters={filterConfigsWithSelection}
      selectedFilterKey={selectedFilterKey}
      onFilterClick={props.onSelectionChange}
      onExitClick={props.onExit}
      specialFilter={props?.specialFilterProps}
    >
      <props.filterRenderer
        selectedFilterKey={selectedFilterKey}
        onSelection={handleSingleSelect}
        filterData={props.filterData}
      />
    </CourseLandingFilterMobilePopup>
  );
};

const prepareFilterData = (
  selectedFilterKey:string,
  currentFilterData: Record<string, any>,
  filterData: any
) => {
  const tData = currentFilterData;

  if (filterData) {
    tData[selectedFilterKey] = filterData;

    if(selectedFilterKey === 'area-of-study-filter') {
      delete tData['specialization-filter'];
    }

  } else {
    delete tData[selectedFilterKey];
  }

  return { ...tData };
};

export default withStyles(FilterMobileDialog, styles) ;
