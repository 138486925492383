import { makeStyles } from '@admitkard/ccl';

export default makeStyles(
  (theme) => ({
    questionContainer: {
      boxSizing: 'border-box',
      height: 'max-content',
      borderRadius: '2rem',
      backgroundColor: theme.colorPalette.lavender.main,
      display: 'flex',
      flexDirection: 'column',
      padding: '1.5rem 0.75rem 1rem',
    },
    searchContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    optionsContainerLarge: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.625rem',
      alignSelf: 'center',
      height: '17rem',
      width: '94%',
      overflow: 'auto',
      paddingTop: '0.8rem',
      margin: '0.625rem 0 1rem 0',
      '@media screen and (min-width: 575px)': {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        height: 'unset',
      },
    },
    optionsContainerMedium: {
      margin: '1.75rem 0',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      rowGap: '0.625rem',
      columnGap: '0.625rem',
      boxSizing: 'border-box',
    },
    optionsContainerSmall: {
      margin: '1.75rem 0',
      display: 'flex',
      justifyContent: 'center',
      rowGap: '0.5rem',
      flexWrap: 'wrap',
      columnGap: '0.5rem',
      boxSizing: 'border-box',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    gradingSelectContainer: {
      marginLeft: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      columnGap: '0.5rem',
      marginBottom: '0.75rem',
      '@media screen and (min-width: 992px)': {
        columnGap: '0.625rem',
      }
    },
    dropDownContainer:{
      display : 'flex',
      alignItems : 'center',
      justifyContent : 'center',
      padding : '0.5rem 1.5rem',
      alignContent : 'center'
    },
    dropDownContainerCurrentCity:{
      display : 'flex',
      alignItems : 'center',
      justifyContent : 'center',
      padding : '1rem',
      alignContent : 'center'
    },
    countryFlag:{
      height : '2rem',
      width : '2rem',
      marginRight : '1rem',
      alignSelf : 'center',
      marginBottom : '0.5rem',
    },
    searchBarContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop : '1rem',
      marginBottom : '0.25rem'
    },
    searchChipContainer : {
      margin : 'auto',
      marginTop : '16px'
    },
    fill: {
      height: '100%',
      justifyContent: 'space-between',
    },
    searchBarContainerWithMargin: {
      marginBottom: '15.375rem',
      ['@media (min-width:767px)']: {
        margin: '5rem 0 18rem 0'
      }
    },
  }),
  { name: 'Question' }
);
