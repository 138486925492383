import { Button, SuaveChip, SuaveValuedSlider } from '@admitkard/ccl';
import pushToDataLayer from '@admitkard/uiutils/gTagDataLayer';
import { parseEnglishTestFromQuery } from 'components/ListingEngine/utils';
import React from 'react';
import { LIST_ENGLISH_TESTS } from '../Constants';
import { PafPageProps } from '../Types';
import { EnglishTextNoneWarning, QuestionHeader } from './Components';
import { PAFSubtitle } from './Components/PAFSubtitle';
import styles from './questionStyles.css';
import { Analytics } from '@admitkard/uiutils/analytics/events';

interface EnglishTestData {
  englishTest?: string;
  score?: number;
}

export const EnglishTestSelect: React.FunctionComponent<PafPageProps> = (props) => {
  const classes = styles();
  const { pageKey } = props;
  const currentData: EnglishTestData = props.pafData[pageKey] ?? {};
  const hasSelection = props.pafData[pageKey] && currentData.englishTest;
  const englishTestData = LIST_ENGLISH_TESTS.find((item) => item.slug === currentData.englishTest);

  // English Exam Parse
  if (!props.pafData[pageKey]) {
    const parsedEngTest = parseEnglishTestFromQuery(props.urlParams);
    if(parsedEngTest) {
      props.setData(parsedEngTest);
    }
  }

  const goToNext = () => {
    pushToDataLayer({
      event: 'level_englishtest',
      data: {
        ['english_test']: props.pafData[pageKey].englishTest,
        ['english_test_score']: props.pafData[pageKey].score,
      },
    });
    Analytics.track('level_englishtest', {
      ['english_test']: props.pafData[pageKey].englishTest,
      ['english_test_score']: props.pafData[pageKey].score,
    });
    Analytics.people.set({
      ['english_test']: props.pafData[pageKey].englishTest,
      ['english_test_score']: props.pafData[pageKey].score,
    });
    props.goToNext();
  };

  return (
    <div className={classes.questionContainer}>
      <QuestionHeader
        questionText="which english test did you take?"
        isFirstPage={props.currentPage === 0}
        onBackClick={props.goToPrevious}
      />
      <div className={classes.optionsContainerSmall}>
        {LIST_ENGLISH_TESTS.map((item) => {
          const isSelected = currentData.englishTest === item.slug;
          return (
            <SuaveChip
              key={item.slug}
              label={item.label}
              icon={item.icon}
              size="small"
              isSelected={isSelected}
              isInActive={ !isSelected && props.pafData[pageKey]?.englishTest?.length>0}
              onClick={() =>
                addData(
                  isSelected
                    ? undefined
                    : {
                      englishTest: item.slug,
                      score: item?.defaultValue,
                    }
                )
              }
            />
          );
        })}
      </div>
      {hasSelection && (
        <>
          {currentData.englishTest !== 'none' && englishTestData && (
            <>
              <div style={{ margin: '1.25rem 0 0.75rem 0.5rem' }}>{<PAFSubtitle label="select your score" />}</div>
              <SuaveValuedSlider
                min={englishTestData.minScore}
                max={englishTestData.maxScore}
                step={englishTestData.step ?? 0.5}
                value={currentData.score}
                onChange={(val) => addData({ score: Number(val) })}
                label="score"
              />
              <div style={{ margin: '1.875rem 0 0 0' }} />
            </>
          )}
          {currentData.englishTest === 'none' && <EnglishTextNoneWarning />}
          <div className={classes.buttonContainer}>
            <Button
              id="next-button-english-test-select"
              isRounded={true}
              color="indigo"
              hasArrowIcon
              disabled={!hasSelection}
              onClick={goToNext}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </div>
  );

  function addData(data?: Partial<EnglishTestData>): void {
    if (data) {
      props.setData({ ...currentData, ...data });
    } else {
      props.setData(undefined);
    }
  }
};
