import { env } from '@admitkard/uiutils/env';
import { http } from '@admitkard/uiutils/http';

const HEADER_API = {
  LOGOUT: `${env.AUTH_API}/logout`,
  GET_STUDENT_PROFILE: `${env.SMS_API}auth/student-profile/my-profile`,
};

export const getUserDetails = () => {
  return http
    .get(HEADER_API.GET_STUDENT_PROFILE, {
      params: {
        select: ['firstName', 'email', 'mobileNumber', 'profilePicUrl', 'username'],
      },
    })
    .then((response) => response.data);
};

export const logoutUser = () => {
  return http.put(HEADER_API.LOGOUT).then((response) => response.data);
};
