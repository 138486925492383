import { makeStyles } from '@admitkard/ccl/style';
import React from 'react';

interface PAFSubtitleProps {
  label: string,
  style?: React.CSSProperties | undefined
}

const useStyles = makeStyles(() => ({
  root: {
    marginRight: '2rem',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: '#483287',
    '@media screen and (min-width: 992px)': {
      fontSize: '1.25rem',
    }
  }
}));

export const PAFSubtitle: React.FunctionComponent<PAFSubtitleProps> = (props) => {
  const classes = useStyles();
  return (
    <span style={{ ...props.style }} className={classes.root} >{props.label}</span>
  );
};
