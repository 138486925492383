import { getCookie } from '@admitkard/uiutils/cookie';
import { auth } from '@admitkard/uiutils/http';
import { ff } from '@admitkard/uiutils/featureFlags';
import { env } from '@admitkard/uiutils/env';

export const setupFFAndContext = async(cookie = '') => {
  const uid = getCookie('_uid', cookie);
  const token = getCookie('token', cookie);
  const jwt = auth.parseJwt(token);

  ff.setContext({ userId: uid });

  await ff.init({ environment: env.ENV });

  if (token && jwt && jwt.id && jwt.username) {
    ff.setContext({ _id: jwt.id, username: jwt.username });
  } else {
    ff.setContext();
  }
};
