import { Button, SuaveChip } from '@admitkard/ccl';
import pushToDataLayer from '@admitkard/uiutils/gTagDataLayer';
import React from 'react';
import { PafPageProps } from '../Types';
import { QuestionHeader } from './Components';
import styles from './questionStyles.css';
import { INTAKE_SCHOLARSHIP_PAF_CONFIGS } from 'components/ListingEngine/Constants';
import { Analytics } from '@admitkard/uiutils/analytics/events';

type IntakeData = {
  slug: string;
  listSlug: string;
};

export const IntakeSelect: React.FunctionComponent<PafPageProps> = (props) => {
  const classes = styles();
  const { pageKey } = props;

  const currentData: IntakeData = props.pafData[pageKey] ?? {};
  const hasSelection = props.pafData[pageKey] && currentData.slug;

  const goToNext = () => {
    pushToDataLayer({
      event: 'level_intake',
      data: {
        ['intake']: props.pafData[pageKey].listSlug,
      },
    });
    Analytics.track('level_intake', {
      ['intake']: props.pafData[pageKey].listSlug,
    });
    Analytics.people.set({
      ['intake']: props.pafData[pageKey].listSlug,
    });
    props.goToNext();
  };

  return (
    <div className={classes.questionContainer}>
      <QuestionHeader
        questionText="What is your preferred intake?"
        isFirstPage={props.currentPage === 0}
        onBackClick={props.goToPrevious}
      />
      <div className={classes.optionsContainerMedium}>
        {INTAKE_SCHOLARSHIP_PAF_CONFIGS.map((intake) => {
          const isSelected = currentData.slug === intake.slug;
          return (
            <SuaveChip
              key={intake.slug}
              label={intake.label}
              icon={intake.icon}
              size="medium"
              isSelected={isSelected}
              isInActive={ !isSelected && (currentData.slug?.length > 0) }
              onClick={() => (isSelected ? props.setData(undefined) : props.setData(intake))}
            />
          );
        })}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          id="next-button-intake-select"
          isRounded={true}
          color="indigo"
          hasArrowIcon
          disabled={!hasSelection}
          onClick={goToNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
