import { cn } from '@admitkard/ccl';
import { makeStyles, theme } from '@admitkard/ccl/style';
import React from 'react';

export interface QuestionHeaderProps {
  isFirstPage: boolean,
  questionText: string,
  onBackClick: () => void,
}

const useStyles = makeStyles(() => ({
  questionTextContainer: {
    display: 'flex',
    justifyItems: 'left',
    alignItems: 'center',
  },
  backButtonContainer: {
    height: '2rem',
    width: '2rem',
    aspectRatio: '1/1',
    borderRadius: '50%',
    backgroundColor: 'white',
    display: 'grid',
    placeItems: 'center',
    marginRight: '0.625rem',
    cursor: 'pointer',
    '--icon-color' : 'hsla(256, 46%, 36%, 1)',
    '& svg' : {
      height: '0.75rem',
      width: '0.5625rem',
    }
  },
  questionText: {
    color: theme.colorPalette.indigo.main,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    overflow: 'hidden' /* Ensures the content is not revealed until the animation */,
    paddingRight: '0.5rem',
    position: 'relative',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    lineClamp: 2,
    WebkitBoxOrient: 'vertical',
    '@media screen and (min-width: 992px)': {
      fontSize: '1.25rem',
    }
  },
  questionTextWithoutIcon: {
    marginLeft: '0.75rem',
  },

}), {name: 'QuestionHeader'});

const QuestionHeader: React.FunctionComponent<QuestionHeaderProps> = (props) => {
  const classes = useStyles();
  const { isFirstPage } = props;

  const backIcon = (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 11 19">
    <path
      fill="#483287"
      fillRule="evenodd"
      clipRule="evenodd"
      d="m3.06 9.38 7.11-7.1A1.08 1.08 0 0 0 8.65.77L.7 8.66a1 1 0 0 0 0 1.42L8.65 18a1.08 1.08 0 0 0 1.52-1.53l-7.11-7.1Z"
    />
  </svg>);

  return (
    <div className={classes.questionTextContainer}>
      {!isFirstPage && (
        <div role='button' onClick={props.onBackClick} className={classes.backButtonContainer}>
          {backIcon}
        </div>
      )}
      <span className={cn(classes.questionText,{[classes.questionTextWithoutIcon]:isFirstPage})}>
        {props.questionText}
      </span>
    </div>
  );
};

export default QuestionHeader;
