import { Button, SuaveChip, SuaveValuedSlider } from '@admitkard/ccl';
import pushToDataLayer from '@admitkard/uiutils/gTagDataLayer';
import { parseAptitudeTestFromQuery } from 'components/ListingEngine/utils';
import React from 'react';
import { competitiveExamNoneKey } from '../../../types';
import { LIST_APTITUDE_TESTS_PG_PHD, LIST_APTITUDE_TESTS_UG } from '../Constants';
import { PafPageAptitudeProps } from '../Types';
import { QuestionHeader } from './Components';
import { PAFSubtitle } from './Components/PAFSubtitle';
import styles from './questionStyles.css';
import { Analytics } from '@admitkard/uiutils/analytics/events';

interface AptitudeTestData {
  aptitudeTest?: string;
  score?: number;
}

export const AptitudeTestTestSelect: React.FunctionComponent<PafPageAptitudeProps> = (props) => {
  const classes = styles();
  const { pageKey } = props;
  const currentData: AptitudeTestData = props.pafData[pageKey] ?? {};
  const hasSelection = props.pafData[pageKey] && currentData.aptitudeTest;
  const currentTestList = props.isUG ? LIST_APTITUDE_TESTS_UG : LIST_APTITUDE_TESTS_PG_PHD;
  const aptitudeItemData = currentTestList.find((item) => item.slug === currentData.aptitudeTest);

  // Aptitude-test parse
  if (!props.pafData[pageKey]) {
    const parsedAptitudeTest = parseAptitudeTestFromQuery(props.urlParams);
    if (parsedAptitudeTest) {
      props.setData(parsedAptitudeTest);
    }
  }

  const goToNext = () => {
    pushToDataLayer({
      event: 'level_aptitudetest',
      data: {
        ['aptitude_test']: props.pafData[pageKey].aptitudeTest,
        ['aptitude_test_score']: props.pafData[pageKey].score,
      },
    });
    Analytics.track('level_aptitudetest', {
      ['aptitude_test']: props.pafData[pageKey].aptitudeTest,
      ['aptitude_test_score']: props.pafData[pageKey].score,
    });
    Analytics.people.set({
      ['aptitude_test']: props.pafData[pageKey].aptitudeTest,
      ['aptitude_test_score']: props.pafData[pageKey].score,
    });
    props.goToNext();
  };
  return (
    <div className={classes.questionContainer}>
      <QuestionHeader
        questionText="which aptitude test did you take?"
        isFirstPage={props.currentPage === 0}
        onBackClick={props.goToPrevious}
      />
      <div className={classes.optionsContainerSmall}>
        {currentTestList.map((item) => {
          const noneReplacedValue =
            currentData.aptitudeTest === competitiveExamNoneKey ? 'none' : currentData.aptitudeTest;
          const isSelected = noneReplacedValue === item.slug;
          return (
            <SuaveChip
              key={item.slug}
              label={item.label}
              icon={item.icon}
              size="small"
              isSelected={isSelected}
              isInActive={!isSelected && props.pafData[pageKey]?.aptitudeTest.length > 0}
              onClick={() => addData(isSelected ? undefined : { aptitudeTest: item.slug, score: item.defaultValue })}
            />
          );
        })}
      </div>
      {hasSelection && (
        <>
          {currentData.aptitudeTest !== 'none' && aptitudeItemData && (
            <>
              <div style={{ margin: '1.25rem 0 0.75rem 0.5rem' }}>{<PAFSubtitle label="what is your score?" />}</div>
              <SuaveValuedSlider
                min={aptitudeItemData.minScore}
                max={aptitudeItemData.maxScore}
                value={currentData.score}
                onChange={(val) => addData({ score: Number(val) })}
                label="score"
              />
              <div style={{ margin: '1.875rem 0 0 0' }} />
            </>
          )}
          <div className={classes.buttonContainer}>
            <Button
              id="next-button-aptitude-test-select"
              isRounded={true}
              color="indigo"
              hasArrowIcon
              disabled={!hasSelection}
              onClick={goToNext}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </div>
  );

  function addData(data?: Partial<AptitudeTestData>): void {
    if (data) {
      if (data.aptitudeTest === 'none') {
        props.setData({ aptitudeTest: competitiveExamNoneKey, score: true });
      } else {
        props.setData({ ...currentData, ...data });
      }
    } else {
      props.setData(undefined);
    }
  }
};
