import { makeStyles } from '@admitkard/ccl/style';
import React from 'react';

// eslint-disable-next-line max-len
const message = 'There are currently no courses available for this selection but courses would be shown with an assumed score of 6.5 in IELTS';

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    width: '20.875rem',
    height: '3.9375rem',
    background: '#FDFCFF',
    borderRadius: '0.8125rem',
    padding: '0.5rem',
    display: 'flex',
    gap: '0.5rem',
    marginBottom: '1.875rem',
    alignSelf : 'center',
    '@media screen and (min-width: 992px)': {
      alignSelf: 'center',
      width: '28.125rem',
      height: '4rem',
    }
  },
  warningIconContainer: {
    height: '1.75rem',
    width: '1.75rem',
    background: '#F7F0FF',
    borderRadius: '0.3125rem',
    '& svg':{
      margin: '0.5rem',
    },
    '@media screen and (min-width: 992px)': {
      width: '2.125rem',
      height: '2.125rem',
      '& svg':{
        margin: '0.625rem',
      },
    }
  },
  message: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.625rem',
    lineHeight: '1rem',
    color: '#483287',
    '@media screen and (min-width: 992px)': {
      fontSize: '0.75rem',
      lineHeight: '1.25rem',
    }
  }
}), {name: 'EnglishTestNoneWarning'});

const warningIconSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 11">
    <path fill="#483287"
      d="M6 0a.83.83 0 0 0-.73.43L2.71 5.01A1749.98 1749.98 0 0 0 .33 9.28l-.17.32-.09.17a.92.92 0 0 0-.07.36c0 .48.37.87.82.87h10.36c.45 0 .82-.4.82-.87a.92.92 0 0 0-.07-.36l-.09-.17-.17-.32a315.76 315.76 0 0 0-2.38-4.27L6.73.43A.83.83 0 0 0 6 0Zm0 .58c.1 0 .21.06.26.14L8.82 5.3l1.78 3.2.6 1.07.17.32.06.11.02.13c0 .16-.12.3-.27.3H.82c-.15 0-.27-.14-.27-.3 0-.05 0-.09.02-.13l.06-.1.18-.33.6-1.08L3.17 5.3 5.74.72A.31.31 0 0 1 6 .58Zm-.03 3.18a.28.28 0 0 0-.24.3v3.47c0 .07.02.15.08.2.05.06.12.09.19.09s.14-.03.2-.09a.3.3 0 0 0 .07-.2V4.05a.3.3 0 0 0-.08-.2.26.26 0 0 0-.2-.1h-.02Zm0 4.63a.28.28 0 0 0-.24.3v.28c0 .08.02.16.08.21.05.06.12.09.19.09s.14-.03.2-.09a.3.3 0 0 0 .07-.2v-.3a.3.3 0 0 0-.08-.2.26.26 0 0 0-.2-.09h-.02Z"/>
  </svg>
);

const EnglishTestNoneWarning: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.root} >
      <div><div className={classes.warningIconContainer}>{warningIconSvg}</div>  </div>
      <span className={classes.message} >{message}</span>
    </div>
  );
};

export default EnglishTestNoneWarning;
