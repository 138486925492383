import { cn, SuaveChip, SuaveSearch, SuaveSearchResultItem } from '@admitkard/ccl';
import { makeStyles } from '@admitkard/ccl/style';
import React from 'react';
import { StaticSearchResult } from '../../../types';

const useStyles = makeStyles((theme) => ({
  selectedResultContainer: {
    marginTop: '5rem',
    marginBottom: '1.25rem',
  },
  selectedResultContainerWithResult: {
    marginTop: '3rem',
  },
  searchBarContainer: {
    width:'12.019rem'
  },
  recommendationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem'
  },
  recommendationsLabel: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1.438rem',
    color: theme.colorPalette.indigo.main,
    textAlign: 'center'
  }
}), {name: 'SpecializationFilter'});

interface SpecializationFilterProps {
  onSelect: (selection?: SuaveSearchResultItem) => void;
  selection?: SuaveSearchResultItem;
  staticSearchResults: StaticSearchResult[];
}

const SpecializationFilter: React.FunctionComponent<SpecializationFilterProps> = (props) => {
  const classes = useStyles();

  const recommendResults = props.staticSearchResults.filter((item) => item.isRecommended);

  return (
    <div>
      <div className={classes.searchBarContainer}>
        <SuaveSearch
          id="mobile-filter"
          label='Search'
          autoWidth
          onResult={
            (result) =>
              typeof result === 'object'
                ? props.onSelect(result)
                : () => {
                // TODO: Handle freeSolo result
                }
          }
          results={props.staticSearchResults}
        />
      </div>
      <div className={cn(classes.selectedResultContainer,
        {[classes.selectedResultContainerWithResult]: props.selection ? true : false}
      )} >
        { props.selection && <SuaveChip
          label={props.selection.label}
          variant='select-able'
          size='large'
          isSelected={props.selection ? true : false}
          onClick={ () => props.onSelect() }
        />
        }
      </div>
      <div className={classes.recommendationsContainer}>
        <span className={classes.recommendationsLabel}>Recommended</span>
        { recommendResults.map((value) => (
          <SuaveChip
            style={{width: '12.25rem'}}
            key={`recommendation-${value.id}}`}
            label={value.label}
            size='medium'
            onClick={ () => props.onSelect(value) }
          />
        )) }
      </div>
    </div>
  );
};

export default SpecializationFilter;
