/* eslint-disable react/react-in-jsx-scope */
// import { QuestionConfig } from 'components/ListingEngine/CourseListing';
import { QuestionConfig } from 'types';
import { LeadSourceObject } from 'components/ReferralEngine/referralEngineTypes';
import { DEFAULT_COURSE_PAF_CONFIG } from 'constants/configs';
import { Context, createContext, FunctionComponent, useEffect, useState } from 'react';
import { useTokenStatus } from 'utils/hooks';

export interface GlobalContext {
  showLoginRegisterModal: boolean;
  setShowLoginRegisterModal: (showLoginRegisterModal: boolean) => void;
  redirectUrl: string;
  setRedirectUrl: (redirectUrl: string) => void;
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  handleRedirectionOutsideLogin: boolean;
  setHandleRedirectionOutsideLogin: (handleRedirectionOutside: boolean) => void;
  isLoggedInCtx: boolean;
  setIsLoggedInCtx: (loggedIn: boolean) => void;
  globalPafConfig: QuestionConfig;
  setGlobalPafConfig: (config: any) => void;
  allLeadSources: LeadSourceObject[];
  setAllLeadSources: (leadSources: any) => void;
  studentProfile: any;
  setStudentProfile: (studentProfile: any) => void;
  hasToken: boolean;
  basicUserDetails?: any;
}
export const LoginRegisterContext: Context<GlobalContext> = createContext<GlobalContext>(undefined as any);

export type GlobalContextType = Context<GlobalContext>;

export const GlobalContext: FunctionComponent<any> = (props) => {
  const [showLoginRegisterModal, setShowLoginRegisterModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('/courses');
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoggedInCtx, setIsLoggedInCtx] = useState(false);
  const [handleRedirectionOutsideLogin, setHandleRedirectionOutsideLogin] = useState(false);
  const [globalPafConfig, setGlobalPafConfig] = useState(DEFAULT_COURSE_PAF_CONFIG);
  const [allLeadSources, setAllLeadSources] = useState([]);
  const [studentProfile, setStudentProfile] = useState(null);
  const { token, hasToken, userDetails } = useTokenStatus();

  useEffect(() => {
    if (!hasToken) {
      setStudentProfile(null);
    }
  }, [token]);
  return (
    <LoginRegisterContext.Provider
      value={{
        showLoginRegisterModal,
        handleRedirectionOutsideLogin,
        setHandleRedirectionOutsideLogin,
        isLoggedInCtx,
        setIsLoggedInCtx,
        setShowLoginRegisterModal,
        redirectUrl,
        setRedirectUrl,
        modalOpen,
        setModalOpen,
        globalPafConfig,
        setGlobalPafConfig,
        allLeadSources,
        setAllLeadSources,
        studentProfile,
        setStudentProfile,
        hasToken,
        basicUserDetails: userDetails
      }}
    >
      {props.children}
    </LoginRegisterContext.Provider>
  );
};
