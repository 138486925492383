import { cn } from '@admitkard/ccl';
import { makeStyles } from '@admitkard/ccl/style';
import Link from 'next/link';
import React from 'react';

const useStyles = makeStyles(
  () => ({
    outerContainer: {
      backgroundColor: 'hsla(0, 0%, 97%, 1)',
      width: '100%',
      boxSizing: 'border-box',
      '@media screen and (min-width: 992px)': {
        paddingTop: '5rem',
        paddingBottom: '5rem',
      },
    },
    innerContainer: {
      width: '100%',
      backgroundColor: 'hsla(0, 0%, 97%, 1)',
      margin: '0 auto',
      display: 'flex',
      maxWidth: '68.5rem',
      alignItems: 'center',
      placeContent: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '1.5rem',
      '@media screen and (min-width: 992px)': {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        position: 'relative',
      },
    },
    logoLinkContainer: {
      marginTop: '1.5rem',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '@media screen and (min-width: 992px)': {
        width: 'unset',
        marginTop: '0',
        border: 'none',
        alignItems: 'flex-start',
      },
    },
    akLogo: {
      '& svg': {
        height: '1rem',
      },
      '@media screen and (min-width: 992px)': {
        '& svg': {
          height: '1.25rem',
        },
      },
    },
    socialIconsContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '1.25rem',
      paddingBottom: '2.375rem',
      '@media screen and (min-width: 992px)': {
        marginTop: '1.625rem',
      },
    },
    socialIconsInnerContainer: {
      display: 'flex',
      height: '1.75rem',
      width: '10rem',
      columnGap: '1rem',
      '& svg': {
        height: '1.75rem',
        cursor: 'pointer',
      },
      '@media screen and (min-width: 992px)': {
        width: '13.625rem',
        height: '2rem',
        columnGap: '1.625rem',
        '& svg': {
          height: '2rem',
        },
      },
    },
    mobileLinkContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      justifyContent: 'center',
      width: '100%',
      '@media screen and (min-width: 992px)': {
        width: 'unset',
        gap: '1.75rem',
        gridTemplateColumns: '1fr 1fr',
      },
    },
    mobileLink: {
      padding: '0.625rem 0',
      margin: '0 1rem',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
      textAlign: 'center',
      borderBottom: '1px solid #FFFFFF',
      '& a': {
        color: '#959595',
        cursor: 'pointer',
        textDecoration: 'none',
      },
      '& a:hover': {
        textDecoration: 'underline',
      },
      '&:nth-child(1)': {
        borderTop: '1px solid #FFFFFF',
      },
      '@media screen and (min-width: 992px)': {
        border: 'none',
        width: 'fit-content',
        padding: '0',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        '&:nth-child(1)': {
          border: 'none',
        },
      },
    },
    copyrightItem: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.625rem',
      lineHeight: '0.75rem',
      color: '#959595',
      paddingTop: '2rem',
      paddingBottom: '0.625rem',
      '@media screen and (min-width: 992px)': {
        position: 'absolute',
        bottom: '0',
        fontSize: '0.75rem',
      },
    },
    primaryLogo: {
      margin: '1.125rem auto 1.125rem 1rem',
      height: '0.975rem',
      display: 'none',
      '@media only screen and (min-width: 600px)': {
        margin: '0',
        marginRight: 'auto',
        height: '100%',
      },
      cursor: 'pointer',
    },
    primaryLogoWhite: {
      '@media only screen and (max-width: 599px)': {
        display: 'block',
      },
    },
    primaryLogoColored: {
      height: '2rem',
      width: '7rem',
      '@media only screen and (min-width: 600px)': {
        display: 'block',
      },
      '@media only screen and (max-width: 599px)': {
        display: 'none',
        marginLeft: '1rem',
        height: '1.2rem',
        width: '6rem',
      },
    },
  }),
  { name: 'NewFooter' }
);

const NewFooter: React.FunctionComponent = () => {
  const classes = useStyles(false);
  const AdmitKardLogoColored = (
    <svg
      id={'AdmitKard-logo'}
      href="/"
      className={cn(classes.primaryLogo, classes.primaryLogoColored)}
      width="1341"
      height="210"
      viewBox="0 0 1341 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 207.673L141.648 0.375H177.207V151.382L141.648 207.673V59.0064L45.066 207.673H0Z" fill="#483287" />
      <path
        d="M294.5 79.1121H229.702L203.642 105.445V183.081L229.702 208.483H308.14L333.794 183.081V22.9126H303.201V183.081H229.712V105.445H294.511L294.5 79.1121Z"
        fill="#483287"
      />
      <path
        d="M380.954 208.493H352.553V96.8187L380.954 71.3501H507.088L536.302 106.038V208.493H507.088V106.038H455.309V208.493H427.859V106.038H380.997L380.954 208.493Z"
        fill="#483287"
      />
      <path d="M557.905 80.2798V183.837L580.353 208.699H605.226V183.837H580.353V80.2798H557.905Z" fill="#483287" />
      <path d="M557.905 38.7705V65.0704H583.613V38.7705H557.905Z" fill="#483287" />
      <path
        d="M627.214 51.9146H652.099V80.9169H738.681V104.699H652.099V185.318H738.681V209.293H652.099L627.214 178.908V51.9146Z"
        fill="#483287"
      />
      <path d="M777.719 0.374512V206.603L815.003 144.744V0.374512H777.719Z" fill="#B777FA" />
      <path
        d="M876.231 0.374669L815.58 105.293L879.245 203.543H910.394L846.782 101.597L910.394 0.362793L876.231 0.374669Z"
        fill="#B777FA"
      />
      <path
        d="M941.189 80.2798H1030.56L1055.73 105.661V180.345L1030.56 205.36H953.482L928.181 180.345V153.764L953.482 127.312H1016.95V153.764H953.482V180.345H1030.6V105.661H941.232L941.189 80.2798Z"
        fill="#B777FA"
      />
      <path d="M1190.48 80.2798H1100.25L1075.41 106.948V209.197H1100.25V106.958H1190.48V80.2798Z" fill="#B777FA" />
      <path
        d="M1300.41 80.2794H1236.75L1213.87 105.022V185.912L1236.75 206.191H1316.41L1341 177.75V25.3335H1316.41V177.75L1236.75 181.6V99.1424H1300.4L1300.41 80.2794Z"
        fill="#B777FA"
      />
    </svg>
  );

  // const AdmitKardLogoColored = (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg" width="128" height="20" viewBox="0 0 128 20" fill="none">
  //     <path d="M0 19.8499L13.2034 0H16.9142V19.8499L13.2034 15.1992V5.61427L4.3015 19.8499H0Z" fill="#2199E8"/>
  //     <path d="M28.1096 7.53955H21.9247L19.4373 10.0611V17.4951L21.9247 19.9275H29.4115L31.8601 17.4951V2.15814H28.9401V17.4951H21.9257V10.0611H28.1106L28.1096 7.53955Z" fill="#2199E8"/>
  //     <path d="M36.3617 19.9285H33.6509V9.23508L36.3617 6.79633H48.4011L51.1895 10.1179V19.9285H48.4011V10.1179H43.4588V19.9285H40.8387V10.1179H36.3658L36.3617 19.9285Z" fill="#2199E8"/>
  //     <path d="M53.2515 7.65137V17.5675L55.3941 19.9482H57.7682V17.5675H55.3941V7.65137H53.2515Z" fill="#2199E8"/>
  //     <path d="M53.2515 3.67664V6.19499H55.7053V3.67664H53.2515Z" fill="#5185FC"/>
  //     <path d="M59.8669 4.93524H62.2422V7.71237H70.5064V9.98964H62.2422V17.7093H70.5064V20.0051H62.2422L59.8669 17.0955V4.93524Z" fill="#2199E8"/>
  //     <path d="M74.2324 0V19.7474L77.7911 15.4V0H74.2324Z" fill="#666666"/>
  //     <path d="M83.6353 3.85506e-05L77.8462 10.0465L83.923 19.4545H86.8961L80.8244 9.69263L86.8961 -0.00109863L83.6353 3.85506e-05Z" fill="#666666"/>
  //     <path d="M89.8354 7.65137H98.366L100.768 10.0818V17.2331L98.366 19.6284H91.0088L88.5938 17.2331V14.6879L91.0088 12.155H97.0672V14.6879H91.0088V17.2331H98.37V10.0818H89.8395L89.8354 7.65137Z" fill="#666666"/>
  //     <path d="M113.631 7.65137H105.017L102.647 10.205V19.9959H105.017V10.206H113.631V7.65137Z" fill="#666666"/>
  //     <path d="M124.123 7.65138H118.046L115.863 10.0206V17.7663L118.046 19.7081H125.65L127.997 16.9848V2.39001H125.65V16.9848L118.046 17.3533V9.45761H124.122L124.123 7.65138Z" fill="#666666"/>
  //   </svg>
  // );

  const LogoMobileFB = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28">
      <path
        fill="#B8B8B8"
        d="M14 0C6.268 0 0 6.3 0 14.072c0 7.055 5.17 12.881 11.907 13.898V17.802H8.443v-3.7h3.464v-2.461c0-4.076 1.975-5.864 5.345-5.864 1.614 0 2.467.12 2.872.174V9.18h-2.3c-1.43 0-1.93 1.364-1.93 2.9v2.023h4.193l-.568 3.7h-3.623V28C22.729 27.07 28 21.196 28 14.072 28 6.3 21.732 0 14 0Z"
      />
    </svg>
  );
  const LogoMobileInstagram = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28">
      <path
        fill="#B8B8B8"
        d="M8.164 0C3.662 0 0 3.666 0 8.169v11.667C0 24.338 3.666 28 8.169 28h11.667C24.338 28 28 24.334 28 19.831V8.164C28 3.662 24.334 0 19.831 0H8.164Zm14.003 4.667A1.167 1.167 0 1 1 22.166 7a1.167 1.167 0 0 1 0-2.334ZM14 7c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.14-7-7 3.14-7 7-7Zm0 2.333a4.666 4.666 0 1 0 0 9.333 4.666 4.666 0 0 0 0-9.333Z"
      />
    </svg>
  );
  const LogoMobileIN = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28">
      <path
        fill="#B8B8B8"
        d="M14 0C6.28 0 0 6.28 0 14s6.28 14 14 14 14-6.28 14-14S21.72 0 14 0ZM9.74 20.087H7.303v-9.13H9.74v9.13ZM8.521 9.13a1.217 1.217 0 1 1 0-2.434 1.217 1.217 0 0 1 0 2.434Zm12.782 10.957H18.87v-4.565c0-1.172-.96-2.13-2.13-2.13a2.136 2.136 0 0 0-2.131 2.13v4.565h-2.435v-9.13h2.435v1.105a3.942 3.942 0 0 1 2.739-1.105 3.956 3.956 0 0 1 3.956 3.956v5.174Z"
      />
    </svg>
  );
  const LogoMobileTwitter = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28">
      <path
        fill="#B8B8B8"
        d="M14 0C6.28 0 0 6.28 0 14s6.28 14 14 14 14-6.28 14-14S21.72 0 14 0Zm6.84 11.275c.006.144.01.29.01.437 0 4.454-3.393 9.592-9.595 9.592a9.54 9.54 0 0 1-5.168-1.515c.264.032.53.047.804.047a6.76 6.76 0 0 0 4.187-1.442 3.378 3.378 0 0 1-3.15-2.342 3.36 3.36 0 0 0 1.523-.058 3.375 3.375 0 0 1-2.703-3.306v-.043a3.36 3.36 0 0 0 1.527.423 3.37 3.37 0 0 1-1.5-2.807c0-.618.166-1.197.456-1.696a9.578 9.578 0 0 0 6.95 3.523 3.372 3.372 0 0 1 5.744-3.075 6.76 6.76 0 0 0 2.142-.818 3.381 3.381 0 0 1-1.483 1.865 6.78 6.78 0 0 0 1.937-.53 6.855 6.855 0 0 1-1.681 1.745Z"
      />
    </svg>
  );

  return (
    <div className={classes.outerContainer}>
      <div className={classes.innerContainer}>
        <div className={classes.logoLinkContainer}>
          <div className={classes.akLogo}>{AdmitKardLogoColored}</div>
          <div className={classes.socialIconsContainer}>
            <div className={classes.socialIconsInnerContainer}>
              <Link href="https://www.facebook.com/AdmitKard/" target="_blank">
                <a rel="noopener referrer" target="_blank">
                  {LogoMobileFB}
                </a>
              </Link>
              <Link href="https://www.instagram.com/admitkard/" target="_blank">
                <a rel="noopener referrer" target="_blank">
                  {LogoMobileInstagram}
                </a>
              </Link>
              <Link href="https://www.linkedin.com/company/admitkard/" target="_blank">
                <a rel="noopener referrer" target="_blank">
                  {LogoMobileIN}
                </a>
              </Link>
              <Link href="https://twitter.com/admitkard/" target="_blank">
                <a rel="noopener referrer" target="_blank">
                  {LogoMobileTwitter}
                </a>
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.mobileLinkContainer}>
          <div className={classes.mobileLink}>
            <Link href="/aboutus/">about admitKard</Link>
          </div>
          <div className={classes.mobileLink}>
            <Link href="/our-success-stories/">our success stories</Link>
          </div>
          <div className={classes.mobileLink}>
            <Link href="/contact-us/">contact us</Link>
          </div>
          <div className={classes.mobileLink}>
            <Link href="/pay/application/fee/now/">pay now</Link>
          </div>
          <div className={classes.mobileLink}>
            <Link href="/company/shipping-refund-return-policy/">refund policy</Link>
          </div>
          <div className={classes.mobileLink}>
            <Link href="/company/student-financial-award-policy/">student financial award policy</Link>
          </div>
          <div className={classes.mobileLink}>
            <Link href="/akprime/">product pricing & description</Link>
          </div>
          <div className={classes.mobileLink}>
            <Link href="/company/privacy-policy/">privacy policy</Link>
          </div>
          <div className={classes.mobileLink}>
            <Link href="/company/terms-of-use/">terms & conditions</Link>
          </div>
          <div className={classes.mobileLink}>
            <Link href="/company/gdpr-cookie-policy/">GDPR & cookies policy</Link>
          </div>
        </div>
        <div className={classes.copyrightItem}>All rights reserved 2022. Pineyards Solutions Pvt Ltd</div>
      </div>
    </div>
  );
};

export default NewFooter;
