import { Button, SuaveChip, SuaveValuedSlider } from '@admitkard/ccl';
import pushToDataLayer from '@admitkard/uiutils/gTagDataLayer';
import { parseCurrentCourseLevel } from 'components/ListingEngine/utils';
import React, { useState } from 'react';
import { LIST_COURSE_LEVELS } from '../Constants';
import { PafPageProps } from '../Types';
import { QuestionHeader } from './Components';
import { PAFSubtitle } from './Components/PAFSubtitle';
import styles from './questionStyles.css';
import { Analytics } from '@admitkard/uiutils/analytics/events';

interface CourseLevelData {
  currentCourseLevel?: string;
  grading?: CourseGrading;
  marksInPercent?: number;
  backlogCount?: number;
}

const gradingValues = [100, 10, 5] as const;
type CourseGrading = typeof gradingValues[number];

export const CurrentCourseLevelSelect: React.FunctionComponent<PafPageProps> = (props) => {
  const classes = styles();
  const { pageKey } = props;

  if (!props.pafData[pageKey]) {
    // Current Course Level
    const currentCourseLevel = parseCurrentCourseLevel(props.urlParams);
    if (currentCourseLevel) {
      props.setData({
        currentCourseLevel: currentCourseLevel.currentCourseLevel,
        marksInPercent: currentCourseLevel.marksInPercent,
        grading: 100,
      });
    }
  }

  const currentData: CourseLevelData = props.pafData[pageKey] ?? { grading: 100, backlogCount: 0, marksInPercent: 60 };
  const hasSelection = props.pafData[pageKey] && currentData.currentCourseLevel;

  const goToNext = () => {
    pushToDataLayer({
      event: 'level_edulevel',
      data: {
        ['current_course_level_name']: props.pafData[pageKey].currentCourseLevel,
        ['current_course_level_grading']: props.pafData[pageKey].grading,
        ['current_course_level_marks']: props.pafData[pageKey].marksInPercent,
        ['current_course_level_backlogs']: props.pafData[pageKey].backlogCount,
      },
    });
    Analytics.track('level_edulevel', {
      ['current_course_level_name']: props.pafData[pageKey].currentCourseLevel,
      ['current_course_level_grading']: props.pafData[pageKey].grading,
      ['current_course_level_marks']: props.pafData[pageKey].marksInPercent,
      ['current_course_level_backlogs']: props.pafData[pageKey].backlogCount,
    });
    Analytics.people.set({
      ['current_course_level_name']: props.pafData[pageKey].currentCourseLevel,
      ['current_course_level_grading']: props.pafData[pageKey].grading,
      ['current_course_level_marks']: props.pafData[pageKey].marksInPercent,
      ['current_course_level_backlogs']: props.pafData[pageKey].backlogCount,
    });
    props.goToNext();
  };

  const [currentMarksInPercent, setCurrentMarksInPercent] = useState<number>(0);

  return (
    <div className={classes.questionContainer}>
      <QuestionHeader
        questionText="what is your current education level?"
        isFirstPage={props.currentPage === 0}
        onBackClick={props.goToPrevious}
      />
      <div className={classes.optionsContainerMedium}>
        {LIST_COURSE_LEVELS.map((item) => {
          const isSelected = currentData.currentCourseLevel === item.slug;
          return (
            <SuaveChip
              key={item.slug}
              label={item.label}
              icon={item.icon}
              size="medium"
              isSelected={isSelected}
              isInActive={!isSelected && props.pafData[pageKey]?.currentCourseLevel?.length > 0}
              onClick={() => {
                addData(
                  isSelected ? undefined : { currentCourseLevel: item.slug, marksInPercent: 60, backlogCount: 0 }
                );
                setCurrentMarksInPercent(60);
              }}
            />
          );
        })}
      </div>
      {hasSelection && (
        <>
          <div style={{ margin: '1.25rem 0 0.75rem 0.5rem' }}>{<PAFSubtitle label="enter your marks in %" />}</div>
          <SuaveValuedSlider
            key={`paf-filter-input-current-course-level-marks-exam-${currentData.currentCourseLevel}-grading-${currentData.grading}`}
            min={0}
            max={100}
            maxLabel={'100%'}
            value={currentMarksInPercent || currentData.marksInPercent}
            onChange={(val) => setCurrentMarksInPercent(val)}
            onChangeCommitted={(val) => addData({ marksInPercent: Number(val) })}
            label="marks in percent"
          />

          {currentData?.currentCourseLevel !== 'twelfth' && (
            <div style={{ margin: '1.25rem 0 0.75rem 0.5rem' }}>{<PAFSubtitle label="backlogs" />}</div>
          )}
          {currentData?.currentCourseLevel !== 'twelfth' && (
            <SuaveValuedSlider
              key={`paf-filter-input-current-course-level-backlog-exam-${currentData.currentCourseLevel}-grading-${currentData.grading}`}
              min={0}
              max={6}
              maxLabel={'5+'}
              value={currentData.backlogCount}
              onChange={(val) => addData({ backlogCount: Number(val) })}
              label="backlog count"
            />
          )}
          <div style={{ margin: '2.125rem 0 0 0' }} />
          <div className={classes.buttonContainer}>
            <Button
              id="next-button-current-course-level-select"
              isRounded={true}
              color="indigo"
              hasArrowIcon
              disabled={currentData?.marksInPercent !== 0 ? false : currentMarksInPercent === 0}
              onClick={goToNext}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </div>
  );

  function addData(data?: Partial<CourseLevelData>) {
    if (data) {
      props.setData({ ...currentData, ...data });
    } else {
      props.setData(undefined);
    }
  }
};
