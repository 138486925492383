import React from 'react';
import Link from 'next/link';
import { Img } from '@admitkard/ccl/Img';
import { theme } from '@admitkard/ccl/style';
import { FOOTER_LINKS } from './footerLinks';
import { useRouter } from 'next/router';
import NewFooter from './NewFooter';
import { PRE_LOGIN_URLS } from 'constants/links';

export const Footer: React.FunctionComponent = () => {
  const showNewFooter = () => {
    const routes = [
      '/blog',
      '/v2/home',
      '/aboutus',
      '/our-success-stories',
      '/contact-us',
      '/pay/application/fee/now',
      '/company/shipping-refund-return-policy',
      '/company/student-financial-award-policy',
      '/akprime',
      '/company/privacy-policy',
      '/company/terms-of-use',
      '/company/gdpr-cookie-policy',
      '/expert-connect',
      '/',
      PRE_LOGIN_URLS.Generic,
    ];

    return routes.some((route) => router.pathname.includes(route));
  };

  const router = useRouter();

  return _isHidden(router.pathname) ? (
    <></>
  ) : showNewFooter() ? (
    <NewFooter />
  ) : (
    <div className="footer-content">
      <div className="footer-content__important-links-container container">
        <div className="footer-content__socio-newsletter-tools">
          <div className="footer-content__margin-bottom-xl">
            <div className="footer-content__brand-logo">
              <Img src="images/admitkard_logo.svg" alt="Admit Kard" />
            </div>
            <div className="footer-content__brand-tag-line">Your one-stop gateway to studying abroad!</div>
            <ul className="footer-content__social-icons-collection">
              <li>
                <Link href="https://www.facebook.com/AdmitKard/" prefetch={false}>
                  <a rel="noopener referrer" target="_blank">
                    <Img src="images/facebook.svg" alt="Facebook" className="footer-content__social-icons" />
                  </a>
                </Link>
              </li>
              <li>
                <Link href="https://twitter.com/admitkard/" prefetch={false}>
                  <a rel="noopener referrer" target="_blank">
                    <Img src="images/twitter.svg" alt="Twitter" className="footer-content__social-icons" />
                  </a>
                </Link>
              </li>
              <li>
                <Link href="https://www.linkedin.com/company/admitkard/" prefetch={false}>
                  <a rel="noopener referrer" target="_blank">
                    <Img src="images/linkedin.svg" alt="LinkedIn" className="footer-content__social-icons" />
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-content__important-links">
          {FOOTER_LINKS.map((data, index) => (
            <div key={index} className="footer-content__links-container">
              <div className="footer-content__link-type-heading">{data.name}</div>
              <ul className="footer-content__links-array">
                {data.availableLinks.map((links, innerIndex) => (
                  <li key={innerIndex}>
                    <Link href={links.link} as={links.link} prefetch={false}>
                      <a rel="noopener referrer">{links.name}</a>
                    </Link>
                    {data.availableLinks.length !== innerIndex + 1 && (
                      <span className="footer-content__bullet-container">
                        <span className="footer-content__bullets">&nbsp;</span>
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className="footer-content__support-links">
        <div className="container">
          <div className="footer-content__links-list">
            <Link href="/aboutus/" as="/aboutus" prefetch={false}>
              <a rel="noopener referrer">About AdmitKard</a>
            </Link>
            <span className="footer-content__bullet-container">
              <span className="footer-content__bullets">&nbsp;</span>
            </span>
            <Link href="/our-success-stories/" as="/our-success-stories/" prefetch={false}>
              <a rel="noopener referrer">Our Success Stories</a>
            </Link>
            <span className="footer-content__bullet-container">
              <span className="footer-content__bullets">&nbsp;</span>
            </span>
            <Link href="/contact-us/" as="/contact-us" prefetch={false}>
              <a rel="noopener referrer">Contact Us</a>
            </Link>
            <span className="footer-content__bullet-container">
              <span className="footer-content__bullets">&nbsp;</span>
            </span>
            <Link href="/pay/application/fee/now/" as="/pay/application/fee/now/" prefetch={false}>
              <a rel="noopener referrer">Pay Now</a>
            </Link>
            <span className="footer-content__bullet-container">
              <span className="footer-content__bullets">&nbsp;</span>
            </span>
            <Link href="/company/privacy-policy" as="/company/privacy-policy" prefetch={false}>
              <a rel="noopener referrer">Privacy</a>
            </Link>
            <span className="footer-content__bullet-container">
              <span className="footer-content__bullets">&nbsp;</span>
            </span>
            <Link href="/company/terms-of-use/" as="/company/terms-of-use/" prefetch={false}>
              <a rel="noopener referrer">Terms &amp; Conditions</a>
            </Link>
            <span className="footer-content__bullet-container">
              <span className="footer-content__bullets">&nbsp;</span>
            </span>
            <Link href="/akprime" prefetch={false}>
              <a rel="noopener referrer">Product Pricing &amp; Description</a>
            </Link>
            <span className="footer-content__bullet-container">
              <span className="footer-content__bullets">&nbsp;</span>
            </span>
            <Link
              href="/company/shipping-refund-return-policy/"
              as="/company/shipping-refund-return-policy/"
              prefetch={false}
            >
              <a rel="noopener referrer">Refund Polic</a>
            </Link>
            <span className="footer-content__bullet-container">
              <span className="footer-content__bullets">&nbsp;</span>
            </span>
            <Link href="/company/gdpr-cookie-policy/" as="/company/gdpr-cookie-policy/" prefetch={false}>
              <a rel="noopener referrer">GDPR &amp; Cookies Policy</a>
            </Link>
          </div>
          <div className="footer-content__copyrights-claim">
            All rights reserved {new Date().getFullYear()}. Pineyardslabs Pvt Ltd
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .footer-content {
            border-top: 2px solid #222753;
            background-color: hsl(0, 0%, 98%);
          }
          .footer-content__margin-bottom-xl {
            margin-bottom: 1rem;
          }
          :global(.footer-content__social-icons) {
            width: 1.5rem;
            height: 1.5rem;
          }
          .footer-content__important-links-container {
            padding: 1rem;
            display: flex;
            justify-content: space-between;
          }
          .footer-content__important-links {
            max-width: 70%;
            flex: 1;
            padding: 0;
          }
          .footer-content__brand-logo {
            height: 1.5rem;
            margin-bottom: 1rem;
          }
          .footer-content__brand-tag-line {
            font-size: 0.75rem;
            margin-bottom: 1rem;
            color: hsl(234, 56%, 40%);
            font-weight: 600;
          }
          .footer-content__socio-newsletter-tools {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 2rem;
          }
          .footer-content__social-icons-collection {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
          }
          .footer-content__social-icons-collection li {
            margin-right: 1rem;
            height: 2.25rem;
          }
          .footer-content__social-icons-collection li:last-child {
            margin-right: 0;
          }
          .footer-content__links-container:last-child {
            margin-bottom: 0;
          }
          .footer-content__links-container {
            margin-bottom: 0rem;
          }
          .footer-content__link-type-heading {
            font-weight: 600;
            font-size: 0.75rem;
            color: hsl(0, 0%, 20%);
          }
          .footer-content__links-array {
            list-style: none;
            display: flex;
            padding: 0;
            margin-top: 0.375rem;
            flex-wrap: wrap;
          }
          .footer-content__links-array a {
            color: hsl(0, 0%, 60%);
            text-decoration: none;
            font-size: 0.75rem;
          }
          .footer-content__links-array a:hover {
            text-decoration: underline;
          }
          .footer-content__support-links {
            padding-bottom: 3rem;
            font-size: 0.625rem;
            font-weight: ${theme.typography.fontWeightRegular};
            color: ${theme.palette.grey[600]};
            display: flex;
            justify-content: space-between;
          }
          .footer-content__links-array li {
            display: flex;
            align-items: center;
          }
          .footer-content__links-list a {
            color: ${theme.palette.grey[700]};
            text-decoration: none;
          }
          .footer-content__links-list a:hover {
            text-decoration: underline;
          }
          .footer-content__bullets:after {
            content: '';
            background-color: ${theme.palette.grey[600]};
            position: absolute;
            width: 0.25rem;
            height: 0.25rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 100%;
          }
          .footer-content__bullet-container {
            position: relative;
            margin-right: 0.5rem;
            margin-left: 0.5rem;
          }
          @media (max-width: 768px) {
            .footer-content__support-links {
              flex-direction: column;
              text-align: center;
            }
            .footer-content__important-links-container {
              flex-direction: column;
            }
            .footer-content__socio-newsletter-tools {
              align-items: center;
              text-align: center;
              margin-bottom: 1rem;
            }
            .footer-content__social-icons-collection {
              display: flex;
              justify-content: center;
            }
            .footer-content__important-links {
              display: flex;
              flex-direction: column;
              max-width: 100%;
              text-align: center;
            }
            .footer-content__links-array {
              justify-content: center;
            }
            .footer-content__links-list {
              margin-bottom: 0.675rem;
              padding: 0 3rem;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
            }
          }
          @media (min-width: 768px) {
            .footer-content__support-links {
              padding-bottom: 1rem;
            }
            .footer-content__links-container {
              margin-bottom: 1.5rem;
            }
            .footer-content__important-links-container {
              padding: 3rem 4rem;
            }
            .footer-content__important-links {
              padding: 0 2rem;
            }
          }
        `}
      </style>
    </div>
  );
};

/**
 * determines component to be hidden for specific routes
 * @param pathName current pathName got from next router
 * @returns true if current routes is in exclusion list
 */
function _isHidden(pathName: string): boolean {
  // normal routes
  const normalRoutes = [
    '/mentorx/questionnare',
    '/paf',
    PRE_LOGIN_URLS.Login,
    PRE_LOGIN_URLS.ListingCourses,
    PRE_LOGIN_URLS.ListingScholarship,
    PRE_LOGIN_URLS.ListingAccommodations,
    PRE_LOGIN_URLS.ListingCourseStatic,
  ];

  const tblRoutes = ['/bigleague'];

  const dynamicRoutes = [
    PRE_LOGIN_URLS.ListingScholarship,
    PRE_LOGIN_URLS.ListingAccommodations,
    PRE_LOGIN_URLS.ListingCourses,
    PRE_LOGIN_URLS.ListingCourseStatic,
    PRE_LOGIN_URLS.ListingAccommodationStatic,
    PRE_LOGIN_URLS.ListingScholarshipStatic,
  ];

  if (normalRoutes.some((hiddenRoute) => hiddenRoute === pathName)) {
    return true;
  } else if (tblRoutes.some((hiddenRoute) => pathName.startsWith(`${hiddenRoute}`))) {
    return true;
  } else if (dynamicRoutes.some((hiddenRoute) => pathName.startsWith(`${hiddenRoute}/`))) {
    return true;
  } else {
    return false;
  }
}
