import { getCookie } from '@admitkard/uiutils/cookie';
import { auth } from '@admitkard/uiutils/http';
import { useEffect, useRef, useState } from 'react';

/**
 * periodically checks cookie and updates status
 * @returns token and status
 */
export default function useTokenStatus() {
  const [token, setToken] = useState<string|undefined>(auth.getToken());
  const userDetailsRef = useRef<Record<string,any> | undefined>(token ? auth.parseJwt(token) : undefined);

  // function to check if token is set by validating the value in token variable
  const hasToken = () => token ? true : false;

  useEffect(() => {
    // stored fpr comparing in inner closure to determine cookie change
    let lastCookie = document.cookie;

    // function validates cookie and extract token cookie value if available
    function validateTokenCookie() {
      const currentCookie = document.cookie;

      // check if any changes to last cookie
      if (currentCookie !== lastCookie) {
        const tokenCookie = getCookie('token');

        if(tokenCookie) {
          setToken(tokenCookie);
          userDetailsRef.current = auth.parseJwt(tokenCookie);
        } else {
          // unset state if token cookie value is not available
          setToken(undefined);
          userDetailsRef.current = undefined;
        }

        // updates changes to current cookie
        lastCookie = currentCookie;
      }
    }

    // runs the checks in 100ms intervals.
    // TODO: can be replaced by 'cookies.onChange' in future
    const autoCookieChecker = setInterval(validateTokenCookie, 100);

    return () => {
      // removed the timer on unmount
      clearInterval(autoCookieChecker);
    };
  }, []);

  return { hasToken: hasToken(), token: token, userDetails: userDetailsRef.current };
}
