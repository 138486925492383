import React, { FunctionComponent } from 'react';
import { makeStyles } from '@admitkard/ccl/style';

interface HideOnWidthRangeProps {
  minWidth: number;
  maxWidth: number;
}

const useStyles = (widths: HideOnWidthRangeProps) => makeStyles(() => ({
  widthContainer: {
    display: 'none',
    [`@media screen and (min-width: ${widths.minWidth}px) and (max-width: ${widths.maxWidth}px)`]: {
      display: 'block',
    },
  }
}), {name: 'HideOnWidthRange'});

const HideOnWidthRange: FunctionComponent<HideOnWidthRangeProps> = (props) => {
  const classes = useStyles(props)();
  return <div className={classes.widthContainer}>
    {props.children}
  </div>;
};

export default HideOnWidthRange;
