import { SnackbarService } from '@admitkard/ccl';
import Airtable from 'airtable';
import { LOCAL_STORAGE_KEY_REFERRED_BY, MentorXServicesSlugs } from 'components/MentorX/MentorXconstants';
import { EMAIL_REGEX } from 'utils/regex';

export const slugify = (text: string) => {
  return text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');
};

export const validateForErrors = (currentSlideNumber: number, inputObject: Record<string, any>) => {
  if (currentSlideNumber === 0 && inputObject && inputObject.selectedServices) {
    let isDisabled = true;
    for (let iterator = 0; iterator < MentorXServicesSlugs.length; iterator++) {
      if (inputObject.selectedServices[MentorXServicesSlugs[iterator]] === true) {
        isDisabled = false;
      }
    }
    if (isDisabled === true) {
      return 'Please select at-least 1 service';
    } else {
      return '';
    }
  }

  if (currentSlideNumber === 1 && inputObject) {
    if (!inputObject.name || !inputObject.phoneNumber) {
      return 'All fields are mandatory';
    }
    if (inputObject.name === '') {
      return 'Name cannot be empty';
    }
    if (inputObject.phoneNumber === '') {
      return 'Phone number be empty';
    }
    if (inputObject.email === '') {
      return 'Email be empty';
    }
    if (!EMAIL_REGEX.test(inputObject.email)) {
      return 'Invalid Email';
    }
    if (inputObject.phoneNumber.length < 7 || inputObject.phoneNumber.length > 15) {
      return 'Invalid Number';
    }
  }

  if (currentSlideNumber === 3 && inputObject) {
    if (!inputObject.courseYear) {
      return 'Please select one option';
    }
  }

  if (currentSlideNumber === 4 && inputObject) {
    if (!inputObject.hasSocialNumber) {
      return 'Please select one option';
    }
  }

  return '';
};

/**
 * fetches referrerKey from localStorage if available
 * else returns empty string
 * @returns referrer value
 */
const getReferrer = () => {
  const referrer = localStorage.getItem(LOCAL_STORAGE_KEY_REFERRED_BY);
  return referrer??'';
};

export const AddDataAirtable = async (data: Record<string, any>) => {


  const AIRTABLE_API_KEY = 'keyA5YEMnyFWiIbDc';
  const base = new Airtable({ apiKey: AIRTABLE_API_KEY }).base('appvxSlyFUCbSU4Ho');

  base('Questionnare Data').create([
    {
      'fields': {
        'name': data.name,
        'countryCode': data.countryCode,
        'phoneNumber': data.phoneNumber,
        'email': data.email,
        'country': data.country,
        'Credit Card': data.selectedServices['credit-card'],
        'International Sim Card': data.selectedServices['international-sim-card'],
        'Access to AKISC community': data.selectedServices['access-to-akisc-community'],
        'International Bank Account': data.selectedServices['international-bank-account'],
        'International Money Transfer': data.selectedServices['international-money-transfer'],
        'Accommodation': data.selectedServices['accommodation'],
        'courseYear': data.courseYear,
        'Social Security / National Insurance NumberNumber': data.hasSocialNumber,
        'Refereed By': getReferrer(),
        'created on': (new Date()).toISOString(),
      }
    },
  ], (err: any, _records: any) => {
    if (err) {
      SnackbarService.open({
        message: 'Some Error Occured!',
        variant: 'error',
      });
      return;
    } else {
      // removes key after fetching to eliminate duplication
      localStorage.removeItem(LOCAL_STORAGE_KEY_REFERRED_BY);
      SnackbarService.open({
        message: 'Thanks for Registering!',
        variant: 'success',
      });
    }
  });
};
