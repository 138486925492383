import { SuaveTextField } from '@admitkard/ccl';
import React, { useState } from 'react';

interface SuaveValidatorTextFieldProps {
  label: string;
  hint?: string;
  defaultValue: string,
  lavenderBg?: boolean,
  isNumber?: boolean,
  validator: (value: string) => string | undefined,
  onChange: (value: string) => void;
  handleOnFocus?: (value : any)=> void ;
}

const SuaveValidatorTextField: React.FunctionComponent<SuaveValidatorTextFieldProps> = (props) => {
  const [textValue, setTextValue] = useState(props.defaultValue);

  const handleOnChange = (value:string) => {
    setTextValue(value);
    if(props.validator(value) === undefined) {
      props.onChange(value);
    }
  };

  return (
    <SuaveTextField
      label={props.label}
      hint={props.hint}
      defaultValue={props.defaultValue}
      onChange={(val) => handleOnChange(val)}
      onFocus={props.handleOnFocus}
      style={{ width: '100%' }}
      error={!(props.validator(textValue))}
      helperText={props.validator(textValue)}
      inputType={props.isNumber ? 'number' : 'text' }
      backgroundColor={props.lavenderBg ? 'lavender' : 'default'}
    />
  );
};

export default SuaveValidatorTextField;
