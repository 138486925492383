import NextI18Next, { UseTranslation, WithTranslationHocType } from 'next-i18next';
import getConfig from 'next/config';

const {  publicRuntimeConfig } = getConfig();
const { localeSubpaths } = publicRuntimeConfig;
const NextI18NextInstance = new NextI18Next({
  defaultNS: 'common',
  defaultLanguage: 'en',
  otherLanguages: [],
  localeSubpaths,
  localePath: typeof window === 'undefined' ? 'public/locales' : 'locales',
  localeExtension: 'json',
  localeStructure: '{{lng}}/{{ns}}',
});

export default NextI18NextInstance;
export const {
  appWithTranslation,
} = NextI18NextInstance;
export const useTranslation: UseTranslation = NextI18NextInstance.useTranslation;
export const withTranslation: WithTranslationHocType = NextI18NextInstance.withTranslation;
