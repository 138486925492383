import { Button, SuaveChip } from '@admitkard/ccl';
import pushToDataLayer from '@admitkard/uiutils/gTagDataLayer';
import React from 'react';
import { LIST_DEGREES } from '../Constants';
import { PafPageCourseLevelProps } from '../Types';
import { QuestionHeader } from './Components';
import styles from './questionStyles.css';
import { Analytics } from '@admitkard/uiutils/analytics/events';

export const CourseLevelSelect: React.FunctionComponent<PafPageCourseLevelProps> = (props) => {
  const classes = styles();
  const { pageKey } = props;
  if (props.urlParams.courseLevel && !props.pafData[pageKey]) {
    props.setData(props.urlParams.courseLevel);
  }
  const goToNext = () => {
    pushToDataLayer({
      event: 'level_coursetype',
      data: {
        ['course_level']: props.pafData[pageKey],
      },
    });
    Analytics.track('level_coursetype', {
      ['course_level']: props.pafData[pageKey],
    });
    Analytics.people.set({
      ['course_level']: props.pafData[pageKey],
    });
    props.goToNext();
  };
  return (
    <div className={classes.questionContainer}>
      <QuestionHeader
        questionText="what degree do you want to pursue?"
        isFirstPage={props.currentPage === 0}
        onBackClick={props.goToPrevious}
      />
      <div className={classes.optionsContainerMedium}>
        {LIST_DEGREES.map((item) => {
          const isSelected = props.pafData[pageKey] === item.slug;
          return (
            <SuaveChip
              key={item.slug}
              label={item.label}
              icon={item.icon}
              size="medium"
              isSelected={isSelected}
              isInActive={ !isSelected && props.pafData[pageKey]?.length>0}
              onClick={() => (isSelected ? props.setData(undefined) : props.setData(item.slug))}
            />
          );
        })}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          id="next-button-course-level-select"
          disabled={props.pafData[pageKey] ? false : true}
          isRounded={true}
          color="indigo"
          hasArrowIcon
          onClick={goToNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
