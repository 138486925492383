import { SuaveChip } from '@admitkard/ccl';
import { SingleSelectItemConfig } from 'components/ListingEngine/types';
import React from 'react';

interface SingleSelectDesktopFilterProps {
  itemConfigs: SingleSelectItemConfig[],
  onSelect: (selection?: string) => void;
  selection?: string;
  withIcon?: boolean;
}

const SingleSelectDesktopFilter: React.FunctionComponent<SingleSelectDesktopFilterProps> = (props) => {

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: '0.5rem',
    }}>
      {props.itemConfigs.map(
        (value) => {
          const isSelected = value.slug === props.selection;
          return (
            <SuaveChip
              key={`filter-item-${value.slug}}`}
              label={value.label}
              variant='select-able'
              size='xl'
              backgroundColor='lavender'
              isSelected={isSelected}
              icon={props.withIcon ? value?.icon : undefined}
              onClick={
                () => {
                  const onSelectValue = isSelected === true ? undefined : value.slug;
                  props.onSelect(onSelectValue);
                }
              }
            />
          );
        }
      )}
    </div>
  );
};

export default SingleSelectDesktopFilter;
