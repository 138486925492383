import React, { FunctionComponent } from 'react';
import Link from 'next/link';
import { theme } from '@admitkard/ccl/style';
import { useRouter } from 'next/router';
import { PRE_LOGIN_URLS } from 'constants/links';

export const MobileCta: FunctionComponent = () => {
  const router = useRouter();
  const hiddenRoutes = [
    '/',
    '/paf',
    PRE_LOGIN_URLS.ListingCourses,
    PRE_LOGIN_URLS.ListingScholarship,
    PRE_LOGIN_URLS.ListingAccommodations,
  ];

  return hiddenRoutes.some((path) => router.pathname.startsWith(path))
    ? <></>
    : <>
      <div className="mobile-cta-container">
        <Link href="/student/login/register?utm_source=sticky&utm_medium=website&utm_campaign=CWM-QL">
          <a className="mobile-cta-button cta-mentors"><button>Connect With Mentors</button></a>
        </Link>
        <Link href="/student/login/register?utm_source=sticky&utm_medium=website&utm_campaign=PE-QL">
          <a className="mobile-cta-button cta-profileevaluation"><button>Profile Evaluation</button></a>
        </Link>
      </div>
      <style jsx>{`
      .mobile-cta-container {
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        width: 100%;
      }
      .mobile-cta-button {
        width: 50%;
      }
      .mobile-cta-button button {
        padding: ${theme.spacingOld(5)};
        color: #fff;
        border: none;
        width: 100%;
        font-size: ${theme.fontSize.m};
        font-weight: ${theme.typography.fontWeightBold};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .cta-mentors button {
        background-color: ${theme.palette.secondary.main};
      }
      .cta-profileevaluation button {
        background-color: ${theme.palette.primary.main};
      }
      @media ${theme.mediaQuery.tabletUp} {
        .mobile-cta-container {
          display: none;
        }
      }
    `}</style>
    </>;
};
