import { Button, cn, SuaveChip } from '@admitkard/ccl';
import pushToDataLayer from '@admitkard/uiutils/gTagDataLayer';
import React from 'react';
import { LIST_AREA_OF_STUDIES } from '../Constants';
import { PafPageProps } from '../Types';
import { QuestionHeader } from './Components';
import styles from './questionStyles.css';
import { Analytics } from '@admitkard/uiutils/analytics/events';

export const AreaOfStudySelect: React.FunctionComponent<PafPageProps> = (props) => {
  const classes = styles();
  const { pageKey } = props;

  if (props.urlParams.aos && !props.pafData[pageKey]) {
    props.setData(props.urlParams.aos);
  }

  const goToNext = () => {
    pushToDataLayer({
      event: 'level_areaofstudy',
      data: {
        [`area_of_study`]: props.pafData[pageKey],
      },
    });
    Analytics.track('level_areaofstudy', {
      [`area_of_study`]: props.pafData[pageKey],
    });
    Analytics.people.set({
      [`area_of_study`]: props.pafData[pageKey],
    });
    props.goToNext();
  };
  return (
    <div className={classes.questionContainer}>
      <QuestionHeader
        questionText=" what is your preferred area of study?"
        isFirstPage={props.currentPage === 0}
        onBackClick={props.goToPrevious}
      />
      <div id="container" className={cn(classes.optionsContainerLarge, 'hideScrollBar')}>
        {LIST_AREA_OF_STUDIES &&
          LIST_AREA_OF_STUDIES.map((item: any, index: number) => {
            const isSelected = props.pafData[pageKey] === item.slug;
            return (
              <SuaveChip
                key={item.slug}
                label={item.label}
                icon={item.icon}
                size="large"
                tag={index <= 2 ? 'POPULAR' : undefined}
                isSelected={isSelected}
                isInActive={!isSelected && props.pafData[pageKey]?.length > 0}
                onClick={() => {
                  props.setData(isSelected ? undefined : item.slug);
                }}
                style={{ width: 'auto' }}
              />
            );
          })}
        <style>
          {' '}
          {`.hideScrollBar {
            /* IE and Edge */
            -ms-overflow-style: none;
            /* Firefox */
            scrollbar-width: none;
          }
          
          /* hide scrollbar in webkit browser */
          .hideScrollBar::-webkit-scrollbar {
            display: none;
          }`}
        </style>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          id="next-button-area-of-study-select"
          isRounded={true}
          color="indigo"
          hasArrowIcon
          disabled={props.pafData[pageKey] ? false : true}
          onClick={goToNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
