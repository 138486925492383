import React, { FunctionComponent, useState, useEffect } from 'react';
import { SubMenuLinkDetails, HEADER_LEVEL_COLORS } from '../headerLinks';
import { SubMenuLink } from './SubMenuLink';
import { cn } from '@admitkard/ccl/cn';
import { theme } from '@admitkard/ccl/style';
import { SubmenuHeader } from './SubmenuHeader';

interface SubMenuLevelProps {
  links?: SubMenuLinkDetails[];
  level?: number;
  isActive: boolean;
  parent?: SubMenuLinkDetails;
  mobile?: boolean;
  onBack?: () => void;
  theme?: string;
}

export const SubMenuLevel: FunctionComponent<SubMenuLevelProps> = (props) => {
  const { level = 0, isActive, links = [], parent, onBack} = props;
  const [ activeLink, setActiveLink ] = useState('');
  const [ isInactive, setIsInactive ] = useState(!isActive);
  const additionalOptions = (parent && parent.options) || {
    width: null,
    height: null,
  };
  useEffect(() => {
    if (isActive === false) {
      setTimeout(() => {
        setIsInactive(true);
      }, 110);
    }
    if (isActive === true) {
      setIsInactive(false);
    }
  }, [isActive]);

  const handleBack = () => {
    setActiveLink('');
  };

  return <>
    <div className={cn(
      'submenu-level-container',
      `submenu-level-${level}`,
      {
        'is-active': isActive,
        'is-inactive': isInactive,
        'inactive-transition': !isActive && !isInactive,
      })}
    >
      <SubmenuHeader parent={parent} onBack={onBack} />
      { links.map((link) => (
        <SubMenuLink
          theme={props.theme}
          key={link.name}
          level={level}
          setActiveLink={setActiveLink}
          activeLink={activeLink}
          hasChildren={!!link.children}
          borderBottom={link.borderBottom}
          {...link}
        >
          { link.children &&
            <SubMenuLevel theme={props.theme}
              parent={link} links={link.children} level={level + 1} isActive={link.name === activeLink} onBack={handleBack} />
          }
        </SubMenuLink>
      ))}
    </div>
    <style jsx>{`
      .submenu-level-container {
        display: none;
        position: absolute;
        width: 80vw;
        max-width: 25rem;
        height: 100%;
        height: 100vh;
        height: calc(100 * var(--vh));
        top: 0;
        left: 0;
        border-bottom: ${theme.spacingOld(2)} solid 
        ${props.theme === 'suave' ? theme.colorPalette.lavender.main :  theme.palette.secondary.main};
        opacity: 0;
        box-sizing: border-box;
        transform: translateX(30rem);
        overflow: hidden;
        padding-top: 4rem;
        border-radius: '20px';
      }
      .submenu-level-heading {
        padding: ${theme.spacingOld(3, 5)};
      }
      .submenu-level-container.submenu-level-0 {
        left: 0;
        transform: translateX(0rem);
      }
      .submenu-level-0 { background-color: ${props.theme === 'suave' ? theme.colorPalette.lavender.main : HEADER_LEVEL_COLORS[0]};
      border-radius: ${props.theme === 'suave' ? '24px' : ''};
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      font-family: 'Poppins', sans-serif !important;
      }

      .submenu-level-1 { background-color: ${HEADER_LEVEL_COLORS[1]}; }
      .submenu-level-2 { background-color: ${HEADER_LEVEL_COLORS[2]}; }
      .submenu-level-3 { background-color: ${HEADER_LEVEL_COLORS[3]}; }
      .submenu-level-4 { background-color: ${HEADER_LEVEL_COLORS[4]}; }
      .submenu-level-container.is-active {
        display: block;
        opacity: 1;
        transform: translateX(0);
        animation: activeEnter 0.25s ease-out;
        z-index: 1000;
      }
      .submenu-level-container.inactive-transition {
        display: block;
        opacity: 0;
        transform: translateX(30rem);
        animation: inactiveExit 0.1s ease-in;
      }
      .submenu-level-container.submenu-level-0.is-active {
        animation: activeEnterY 0.25s ease-out;
      }
      .submenu-level-container.submenu-level-0.inactive-transition {
        animation: inactiveExitY 0.1s ease-out;
      }

      @keyframes activeEnter {
        from { opacity: 0; transform: translateX(30rem); }
        to { opacity: 1; transform: translateX(0rem); }
      }
      @keyframes inactiveExit {
        from { opacity: 1;  transform: translateX(0rem); }
        to { opacity: 0; transform: translateX(30rem); }
      }

      @keyframes activeEnterDesktop {
        from { opacity: 0; transform: translateX(-2rem); }
        to { opacity: 1; transform: translateX(0rem); }
      }
      @keyframes inactiveExitDesktop {
        from { opacity: 1;  transform: translateX(0rem); }
        to { opacity: 0; transform: translateX(-2rem); }
      }

      @keyframes activeEnterY {
        from { opacity: 0; transform: translateY(-2rem); }
        to { opacity: 1; transform: translateY(0rem); }
      }
      @keyframes inactiveExitY {
        from { opacity: 1;  transform: translateY(0rem); }
        to { opacity: 0; transform: translateY(-2rem); }
      }
      @media ${theme.mediaQuery.tabletUp} {
        .submenu-level-container {
          width: 15rem;
          height: 30rem;
          left: 15rem;
          overflow: visible;
          transform: translateX(-2rem);
          padding-top: 0;
        }
        .submenu-level-container.is-active {
          animation-name: activeEnterDesktop;
        }
        .submenu-level-container.inactive-transition {
          transform: translateX(-2rem);
          animation-name: inactiveExitDesktop;
        }
      }
    `}</style>
    <style jsx>{`
      @media ${theme.mediaQuery.tabletUp} {
        .submenu-level-container {
          width: ${additionalOptions.width};
          height: ${additionalOptions.height};
        }
      }
    `}</style>
  </>;
};
