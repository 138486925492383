import { CourseGrading } from 'components/ListingEngine/types';
import { LIST_AREA_OF_STUDIES, LIST_COUNTRIES, LIST_SEARCHABLE_COUNTRIES, INTAKE_CONFIGS,SPECIALIZATION } from '../Constants';
export * from './intake';

export type ProfileData = {
  areaOfStudy: string;
  courseLevel: string;
  country: string;
  englishExam?: string;
  englishExamScore?: number;
  competitiveExam?: string;
  competitiveExamScore?: number;
  totalWorkExperience?: number;
  currentEducation: 'twelfth' | 'ug' | 'pg';
  grading: 'outOf100' | 'outOf10' | 'outOf5';
  currentEducationMarks: number;
  specialization: string | string[];
  intake: string | string[];
  managedSpecialization: string | string[];
  budget: number;
  intents: any;
};


const mapTotalMarks = (grading: string) => {
  const map: Record<string, number> = {
    'outOf100': 100,
    'outOf10': 10,
    'outOf5': 5
  };
  return map[grading];
};

export const gradingToString: Record<CourseGrading, string> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  100: 'outOf100',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  10: 'outOf10',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  5: 'outOf5'
};

export const gradingToInputTextPostFix: Record<CourseGrading, string> =  {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  100: '%',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  10: 'GPA',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  5: 'GPA'
};

export const transformPayload = (data: ProfileData) => {
  const payload: any = {};
  if(data.areaOfStudy){
    payload.specialization = [data.areaOfStudy];
  }
  if(data.country){
    payload.preferenceCountry = [data.country];
  }
  if(data.courseLevel){
    payload.courseLevel = data.courseLevel;
  }
  if(data.englishExam && data.englishExamScore){
    payload[`test_${data.englishExam}`] = data.englishExamScore;
  }
  if(data.competitiveExam && data.competitiveExamScore){
    payload[`test_${data.competitiveExam}`] = data.competitiveExamScore;
  }
  if(data.currentEducation && data.grading && data.currentEducationMarks){
    const currentEducation = data.currentEducation === 'twelfth' ? 'twelth'
      : (data.currentEducation === 'ug'? 'graduation': 'post-graduation' );
    payload[currentEducation] = {
      achievedMarks: data.currentEducationMarks,
      totalMarks: mapTotalMarks(data.grading)
    };
  }
  if(data.totalWorkExperience){
    payload['totalWorkEx'] = data.totalWorkExperience;
  }

  if(data.specialization){
    payload.departments = typeof data.specialization === 'string' ? [data.specialization] : data.specialization;
  }

  if(data.managedSpecialization){
    payload.managedSpecialization = typeof data.managedSpecialization === 'string' ?
      [data.managedSpecialization] : data.managedSpecialization;
  }

  if(data.intake) {
    payload.intake = typeof data.intake === 'string' ? [data.intake] : data.intake;
  }

  if(data.budget){
    payload.budgetPerAnnum = data.budget;
  }

  if(data.intents) {
    payload.studyUiPreferences = {
      intents: data.intents
    };
  }

  return payload;
};


export const filterScholarshipDataToPayload = (filterData: Record<string, any>) => {

  const requestData:Record<string, any> = {
    scholarshipCourseLevel: filterData['course-level-filter']?.toUpperCase(),
    scholarshipCountry: filterData['country-filter'],
  };

  const isMerit = filterData['scholarship-type-filter']?.isMerit;
  if(isMerit === true || isMerit === false) {
    requestData['scholarshipType'] = isMerit ? 'Merit' : 'Need';
  }

  return requestData;
};

export const filterToPayloadAccommodations = (filterData: Record<string, any>) => {
  let universityFilter = filterData['university-filter'];
  if((filterData['city-filter']?.id && filterData['university-filter'])) {
    universityFilter = undefined;
  }

  const requestData:Record<string, any> = {
    country: filterData['country-filter']?.slug,
    sortBy: filterData['sort-filter'],
    city: filterData['city-filter']?.id,
    pricing: {
      min: filterData['budget-filter']?.rangeMin,
      max: filterData['budget-filter']?.rangeMax,
    },
    leaseDuration: filterData['lease-duration-filter'],
    keyword: universityFilter,
  };

  return requestData;
};

export const filterDataToPayload = (filterData: Record<string, any>) => {
  const conversionRate = LIST_COUNTRIES
    .find((item) => item.slug === filterData['country-filter'])
    ?.conversionRate;

  const requestData:Record<string, any> = {
    budget: Array.from(filterData['budget-filter'] ?? []),
    intake: Array.from(filterData['intake-filter'] ?? []),
    managedSpecialization: filterData['specialization-filter']?.id,
    country: filterData['country-filter'],
    conversionRate: conversionRate,
    courseLevel: filterData['course-level-filter'],
    areaOfStudy: filterData['area-of-study-filter']?.id,
  };

  // WorkExperience
  const workExperience = filterData['work-experience-filter'];
  if(workExperience) {
    requestData['workExperience'] = workExperience?.numberOfYears ?? 0;
  }

  // Current Course Level
  const currentCourseLevel = filterData['higher-education-filter']?.currentCourseLevel;
  if(currentCourseLevel) {
    const parsedMark = parseFloat(filterData['higher-education-filter']?.marksInPercent??0);
    requestData[currentCourseLevel] = parsedMark;
    requestData['grading'] = gradingToString[(filterData['higher-education-filter']?.grading ?? 100) as CourseGrading];
    requestData['backlogRange'] =  filterData['higher-education-filter']?.backlogCount;
  }

  // English Exam
  let englishTest = filterData['english-proficiency-exam-filter']?.englishTest;
  if(englishTest) {
    let score = filterData['english-proficiency-exam-filter']?.score;
    if(englishTest === 'none') {
      englishTest = 'ielts';
      score = 6.5;
    }
    requestData[englishTest] = score;
  }

  // Aptitude Exam
  const aptitudeTest = filterData['academic-proficiency-exam-filter']?.aptitudeTest;
  if(aptitudeTest) {
    requestData[aptitudeTest] = filterData['academic-proficiency-exam-filter']?.score;
  }

  return requestData;
};

export const parseScholarshipPafToFilters = (rawPafData: string) => {
  const parsedPafData = rawPafData ? JSON.parse(rawPafData) : {};
  const filters:Record<string,any> = {};

  const selectedCountry = parsedPafData['COUNTRY_SELECT'];
  if(selectedCountry) {
    filters['country-filter'] = selectedCountry;
  }

  const selectedCourseLevel = parsedPafData['COURSE_LEVEL_SELECT'];
  if(selectedCourseLevel) {
    filters['course-level-filter'] = selectedCourseLevel;
  }

  const aosFindResult = LIST_AREA_OF_STUDIES.find((item) => item.slug === parsedPafData['AREA_OF_STUDY_SELECT']);
  const aosConfig = aosFindResult ? { id: aosFindResult?.slug, label: aosFindResult?.label } : undefined;
  if(aosConfig) {
    filters['area-of-study-filter']= aosConfig;
  }

  return filters;
};

export const queryToFiltersScholarships = (query: any) => {
  const filters:Record<string,any> = {};

  const selectedCountry = query?.country;
  if(selectedCountry) {
    filters['country-filter'] = selectedCountry;
  }

  const selectedCourseLevel = query?.courseLevel;
  if(selectedCourseLevel) {
    filters['course-level-filter'] = selectedCourseLevel;
  }

  const scholarshipType = query?.scholarshipType;
  if(scholarshipType) {
    filters['scholarship-type-filter'] =
    (scholarshipType === 'merit')
      ? { isMerit: true }
      : (scholarshipType === 'need')
        ? { isMerit: false }
        : undefined;
  }

  const aosFindResult = LIST_AREA_OF_STUDIES.find((item) => item.slug === query?.aos);
  const aosConfig = aosFindResult ? { id: aosFindResult?.slug, label: aosFindResult?.label } : undefined;
  if(aosConfig) {
    filters['area-of-study-filter']= aosConfig;
  }

  return filters;
};

export const queryToFiltersAccommodations = (query: any) => {
  const filters:Record<string,any> = {};

  const selectedCountry = query?.country;
  if(selectedCountry) {
    const countryConfig = LIST_SEARCHABLE_COUNTRIES.find((country) => country.slug === selectedCountry);

    filters['country-filter'] = {
      label: countryConfig?.label ?? selectedCountry,
      slug: countryConfig?.slug ?? selectedCountry,
    };
  }

  const selectedSort = query?.sort;
  if(selectedSort) {
    filters['sort-filter'] = selectedSort;
  }

  const minBudget = parseInt(query?.minBudget, 10);
  const maxBudget = parseInt(query?.maxBudget, 10);
  if(minBudget || maxBudget) {
    const budgetFilter = {
      'rangeMin': 0,
      'rangeMax': 3000,
    };

    if(!isNaN(minBudget) && (minBudget >= 0 && minBudget < 3000) ) {
      budgetFilter.rangeMin = minBudget;
    }
    if(!isNaN(maxBudget) && (maxBudget <= 3000 && maxBudget > 0) ) {
      budgetFilter.rangeMax = maxBudget;
    }

    if(budgetFilter.rangeMin < budgetFilter.rangeMax) {
      filters['budget-filter'] = budgetFilter;
    }
  }

  const cityLabel = query?.cityLabel;
  const citySlug = query?.citySlug;
  if(cityLabel && citySlug) {
    filters['city-filter'] = {
      id: citySlug,
      label: cityLabel,
    };
  }

  const selectedUniversity = query?.university;
  if(selectedUniversity) {
    filters['university-filter'] = selectedUniversity;
  }

  const selectedLeaseDuration = query?.leaseDuration;
  if(selectedLeaseDuration) {
    filters['lease-duration-filter'] = selectedLeaseDuration;
  }

  return filters;
};

export const parsePafToFiltersAccommodation = (rawPafData: string) => {
  const parsedPafData = rawPafData ? JSON.parse(rawPafData) : {};

  const filters: Record<string,any> = {
    'city-filter': parsedPafData['SEARCHABLE_CITY_SELECT'],
    'country-filter': parsedPafData['SEARCHABLE_COUNTRY_SELECT'],
  };

  return filters;
};

export const parseCoursePafToFilters = (rawData: string) => {
  const parsedData = rawData ? JSON.parse(rawData) : {};

  let intake = undefined;
  if (parsedData['INTAKE_SELECT']) {
    const intakeSelection = parsedData['INTAKE_SELECT']?.listSlug;
    if(intakeSelection && intakeSelection !== 'none') {
      intake = [parsedData['INTAKE_SELECT']?.listSlug];
    }
  }

  let englishTest = undefined;
  if (parsedData['ENGLISH_TEST_SELECT']?.englishTest) {
    englishTest = {
      englishTest: parsedData['ENGLISH_TEST_SELECT']?.englishTest,
      score: parsedData['ENGLISH_TEST_SELECT']?.score,
    };
  }

  let compTest = undefined;
  if (parsedData['APTITUDE_TEST_SELECT']?.aptitudeTest) {
    compTest = {
      aptitudeTest: parsedData['APTITUDE_TEST_SELECT']?.aptitudeTest,
      score: parsedData['APTITUDE_TEST_SELECT']?.score,
    };
  }

  let workExp = undefined;
  if (parsedData['WORK_EXPERIENCE_SELECT']) {
    workExp = {
      hasWorkExperience: parsedData['WORK_EXPERIENCE_SELECT']?.hasWorkExperience,
      numberOfYears: parsedData['WORK_EXPERIENCE_SELECT']?.numberOfYears,
    };
  }

  let higherEducation = undefined;
  if (parsedData['CURRENT_COURSE_LEVEL_SELECT']?.currentCourseLevel) {
    higherEducation = {
      currentCourseLevel: parsedData['CURRENT_COURSE_LEVEL_SELECT']?.currentCourseLevel,
      marksInPercent: parsedData['CURRENT_COURSE_LEVEL_SELECT']?.marksInPercent,
      grading: parsedData['CURRENT_COURSE_LEVEL_SELECT']?.grading,
      backlogCount: parsedData['CURRENT_COURSE_LEVEL_SELECT']?.backlogCount,
    };
  }

  const aosFindResult = LIST_AREA_OF_STUDIES.find((item) => item.slug === parsedData['AREA_OF_STUDY_SELECT']);
  const aosConfig = aosFindResult ? { id: aosFindResult?.slug, label: aosFindResult?.label } : undefined;

  return {
    'country-filter': parsedData['COUNTRY_SELECT'],
    'course-level-filter': parsedData['COURSE_LEVEL_SELECT'],
    'area-of-study-filter': aosConfig,
    'higher-education-filter': higherEducation,
    'english-proficiency-exam-filter': englishTest,
    'academic-proficiency-exam-filter': compTest,
    'work-experience-filter': workExp,
    'intake-filter': intake,
  };
};

export function parseEnglishTestFromQuery(query: any) {
  if (query?.ielts) {
    return { englishTest: 'ielts', score: Number(query.ielts) };
  }
  if (query?.pte) {
    return { englishTest: 'pte', score: Number(query.pte) };
  }
  if (query?.toefl) {
    return { englishTest: 'toefl', score: Number(query.toefl) };
  }

  return undefined;
}

export function parseAptitudeTestFromQuery(query: any) {
  if (query?.gre) {
    return { aptitudeTest: 'gre', score: Number(query?.gre) };
  }
  if (query?.gmat) {
    return { aptitudeTest: 'gmat', score: Number(query?.gmat) };
  }
  if (query?.sat) {
    return { aptitudeTest: 'sat', score: Number(query?.sat) };
  }
  if (query?.act) {
    return { aptitudeTest: 'act', score: Number(query?.act) };
  }

  return undefined;
}

export function parseCurrentCourseLevel(query: any) {
  if (query['twelfth']) {
    return ({
      currentCourseLevel: 'twelfth',
      marksInPercent: Number(query['twelfth']),
    });
  }
  if (query['ug']) {
    return ({
      currentCourseLevel: 'ug',
      marksInPercent: Number(query['ug']),
    });
  }
  if (query['pg']) {
    return ({
      currentCourseLevel: 'pg',
      marksInPercent: Number(query['pg']),
    });
  }
  return undefined;
}

export const queryToFiltersCourses = (query: any) => {
  const filters:Record<string,any> = {};

  const country = query?.country;
  if(country) {
    filters['country-filter'] = country;
  }

  const courseLevel = query?.courseLevel;
  if(courseLevel) {
    filters['course-level-filter'] = courseLevel;
  }

  // English Exam Parse
  const parsedEngText = parseEnglishTestFromQuery(query);
  if(parsedEngText) {
    filters['english-proficiency-exam-filter'] = parsedEngText;
  }

  // Aptitude-test parse
  const parsedAptitudeText = parseAptitudeTestFromQuery(query);
  if(parsedAptitudeText) {
    filters['academic-proficiency-exam-filter'] = parsedAptitudeText;
  }

  // Current Course Level
  const currentCourseLevel = parseCurrentCourseLevel(query);
  if (currentCourseLevel) {
    filters['higher-education-filter'] = {
      currentCourseLevel: currentCourseLevel.currentCourseLevel,
      marksInPercent: currentCourseLevel.marksInPercent,
      grading: 100,
    };
  }

  const aos = query?.aos;
  if(aos) {
    const aosFindResult = LIST_AREA_OF_STUDIES.find((item) => item.slug === aos);
    const aosConfig = aosFindResult ? { id: aosFindResult?.slug, label: aosFindResult?.label } : undefined;
    if(aosConfig) {
      filters['area-of-study-filter'] = aosConfig;
    }
  }

  // specialization parse
  const specializationSelected= query.specialization;
  if(specializationSelected){
    const specializationFindResult = SPECIALIZATION.find((item) => item.slug === specializationSelected);
    const specializationConfig = specializationFindResult ?
      { id: specializationFindResult?.slug,
        label: specializationFindResult?.name,
        isRecommended: specializationFindResult?.recommended ?? false
      }
      : undefined;
    if(specializationConfig) {
      filters['specialization-filter'] = specializationConfig;
    }
  }


  //Intake parse
  const intakeYearSelected = query?.intake;
  if(intakeYearSelected){
    const intakeYear =  INTAKE_CONFIGS.find((item) => item.slug === intakeYearSelected);
    if(intakeYear){
      filters['intake-filter'] = [intakeYear.slug];
    }
  }

  return filters;
};

export const queryToPafData = (query: any) => {
  const filters:Record<string,any> = {};

  const country = query?.country;
  if(country) {
    filters['COUNTRY_SELECT'] = country;
  }

  const courseLevel = query?.courseLevel;
  if(courseLevel) {
    filters['COURSE_LEVEL_SELECT'] = courseLevel;
  }

  // English Exam Parse
  const parsedEngText = parseEnglishTestFromQuery(query);
  if(parsedEngText) {
    filters['ENGLISH_TEST_SELECT'] = parsedEngText;
  }

  // Aptitude-test parse
  const parsedAptitudeText = parseAptitudeTestFromQuery(query);
  if(parsedAptitudeText) {
    filters['APTITUDE_TEST_SELECT'] = parsedAptitudeText;
  }

  // Current Course Level
  const currentCourseLevel = parseCurrentCourseLevel(query);
  if (currentCourseLevel) {
    filters['CURRENT_COURSE_LEVEL_SELECT'] = {
      currentCourseLevel: currentCourseLevel.currentCourseLevel,
      marksInPercent: currentCourseLevel.marksInPercent,
      grading: 100,
    };
  }

  const aos = query?.aos;
  if(aos) {
    const aosFindResult = LIST_AREA_OF_STUDIES.find((item) => item.slug === aos);
    const aosConfig = aosFindResult ? { id: aosFindResult?.slug, label: aosFindResult?.label } : undefined;
    if(aosConfig) {
      filters['AREA_OF_STUDY_SELECT'] = aosConfig.id;
    }
  }

  return filters;
};

export const pafDataToPayload = (pafData: Record<string, any>) => {
  const requestData: Record<string, any> = {
    country: pafData['COUNTRY_SELECT'],
    courseLevel: pafData['COURSE_LEVEL_SELECT'],
    areaOfStudy: pafData['AREA_OF_STUDY_SELECT'],
  };

  // Intake
  const intakeData = pafData['INTAKE_SELECT'];
  if(intakeData) {
    if(intakeData?.listSlug && intakeData?.listSlug !== 'none') {
      requestData['intake'] = [intakeData.listSlug];
    }
  }

  // WorkExperience
  const workExperience = pafData['WORK_EXPERIENCE_SELECT'];
  if(workExperience) {
    requestData['workExperience'] = workExperience?.numberOfYears ?? 0;
  }

  // Current Course Level
  const currentCourseLevel = pafData['CURRENT_COURSE_LEVEL_SELECT']?.currentCourseLevel;
  if(currentCourseLevel) {
    const parsedMark = parseFloat(pafData['CURRENT_COURSE_LEVEL_SELECT']?.marksInPercent??0);
    requestData[currentCourseLevel] = parsedMark;
    requestData['grading'] = 'outOf100';
    requestData['backlogRange'] =  pafData['CURRENT_COURSE_LEVEL_SELECT']?.backlogCount;
  }

  // English Exam
  let englishTest = pafData['ENGLISH_TEST_SELECT']?.englishTest;
  if(englishTest) {
    let score = pafData['ENGLISH_TEST_SELECT']?.score;
    if(englishTest === 'none') {
      englishTest = 'ielts';
      score = 6.5;
    }
    requestData[englishTest] = score;
  }

  // Aptitude Exam
  const aptitudeTest = pafData['APTITUDE_TEST_SELECT']?.aptitudeTest;
  if(aptitudeTest) {
    requestData[aptitudeTest] = pafData['APTITUDE_TEST_SELECT']?.score ?? 0;
  }

  return requestData;
};

/**
 * Slices the input text and adds a postfix if the length of the text exceeds a specified limit.
 * @param {string} text - The text to be sliced and checked for length.
 * @param {{ length: number, postFix: string }} [options] - An object containing the length limit and the postfix string.
 *                                                        If not provided, the default postfix is "..."
 * @returns {string} - The sliced text with the postfix if applicable, otherwise the original text.
 */
export function sliceTextWithPostFix(text: string, options: { length: number, postFix?: string }) {
  const { length, postFix = '...' } = options;
  if (text.length > length) {
    return text.slice(0, length) + postFix;
  }
  return text;
}

/**
 * Formats a given number as a currency, with optional currency code
 *
 * @param cost - The number to format as currency
 * @param currency - The currency code to use for formatting (optional)
 * @returns The formatted currency string, or the original number if formatting fails
 */
export function safeCurrencyFormat(cost: number, currency?: string) {
  if(currency){
    try {
      const formattedCurrency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 0,
      }).format(cost);
      return formattedCurrency;
    } catch (error) {
      return `${cost}`;
    }
  }
  return `${cost}`;
}

/**
 *
 * @param queryData - THe filter data from url in JSON format of static accommodation listing page
 * @returns The formatted filterData for static accommodation listing page
 */
export function getFilterDataFromUrlAccommodation(queryData : Record<string,string>){
  const tempData : Record<string,any> = {};
  for (const key in queryData) {
    const value = queryData[key];
    if(value && typeof value ==='string' ){
      const isJSON =(value?.startsWith('{') && value?.endsWith('}')) || (value?.startsWith('[') && value?.endsWith(']'));
      if (isJSON) {
        const parsedValue = JSON.parse(value);
        tempData[key] = parsedValue;
      } else {
        tempData[key] = value;
      }
    }
  }

  const filterData : Record<string,any>= {};
  const validKeys = ['budget-filter', 'sort-filter', 'country-filter', 'city-filter', 'university-filter', 'lease-duration-filter'];
  Object.keys(tempData).forEach(key => {
    if (validKeys.includes(key)) {
      filterData[key] = tempData[key];
    }
  });

  return filterData;
}

/**
 *
 * @param queryData - THe filter data from url in JSON format of static course listing page
 * @returns The formatted filterData for static course listing page
 */
export function getFilterDataFromUrlCourse(queryData : Record<string,string>){
  const tempData : Record<string,any> = {};
  for (const key in queryData) {
    const value = queryData[key];
    if(value && typeof value ==='string' ){
      const isJSON =(value?.startsWith('{') && value?.endsWith('}')) || (value?.startsWith('[') && value?.endsWith(']'));
      if (isJSON) {
        const parsedValue = JSON.parse(value);
        tempData[key] = parsedValue;
      } else {
        tempData[key] = value;
      }
    }
  }

  const filterData : Record<string,any>= {};
  const validKeys = ['country-filter', 'english-proficiency-exam-filter','course-level-filter','academic-proficiency-exam-filter',
    'area-of-study-filter','specialization-filter','intake-filter','work-experience-filter','budget-filter'];
  Object.keys(tempData).forEach(key => {
    if (validKeys.includes(key)) {
      filterData[key] = tempData[key];
    }
  });

  return filterData;
}


/**
 *
 * @param queryData - THe filter data from url in JSON format of static scholarship listing page
 * @returns The formatted filterData for static scholarship listing page
 */
export function getFilterDataFromUrlScholarship(queryData : Record<string,string>){
  const tempData : Record<string,any> = {};
  for (const key in queryData) {
    const value = queryData[key];
    if(value && typeof value ==='string' ){
      const isJSON =(value?.startsWith('{') && value?.endsWith('}')) || (value?.startsWith('[') && value?.endsWith(']'));
      if (isJSON) {
        const parsedValue = JSON.parse(value);
        tempData[key] = parsedValue;
      } else {
        tempData[key] = value;
      }
    }
  }

  const filterData : Record<string,any>= {};
  const validKeys = ['country-filter','course-level-filter','area-of-study-filter','scholarship-type-filter'];
  Object.keys(tempData).forEach(key => {
    if (validKeys.includes(key)) {
      filterData[key] = tempData[key];
    }
  });

  return filterData;
}
