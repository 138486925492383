import React, { FunctionComponent, useState } from 'react';
import Link from 'next/link';
import { Link as CclLink} from '@admitkard/ccl/Link';
import { HEADER_LEVEL_COLORS } from 'components/Header/headerLinks';
import { theme } from '@admitkard/ccl/style';
import { cn } from '@admitkard/ccl/cn';
import { isAbsoluteUrl } from 'next/dist/shared/lib/utils';

interface SubMenuLinkProps {
  href?: string;
  as?: string;
  level: number;
  raw?: boolean; // should process as raw element
  label: string | JSX.Element;
  hasChildren: boolean;
  name: string;
  activeLink: string;
  setActiveLink: (linkName: string) => void;
  mobile?: boolean;
  onClick?: () => void;
  theme?: string;
  borderBottom?: boolean;
}

export const SubMenuLink: FunctionComponent<SubMenuLinkProps> = (props) => {
  const { href, as, level, label, name, activeLink, hasChildren, mobile, raw, onClick, borderBottom } = props;
  const [ timer, setTimer ] = useState(0);
  const isActive = activeLink === name;

  const setActiveLink = () => {
    window.clearTimeout(timer);
    setTimer(window.setTimeout(() => {
      if (name !== activeLink) {
        props.setActiveLink(name);
      }
    }, 151));
  };

  const unsetActiveLink = () => {
    window.clearTimeout(timer);
    setTimer(window.setTimeout(() => {
      if (name === activeLink) {
        props.setActiveLink('');
      }
    }, 150));
  };

  const menuProps: Record<string, () => void> = {
    onClick: setActiveLink,
  };

  if (!mobile) {
    menuProps.onMouseEnter =  setActiveLink;
    menuProps.onMouseLeave = unsetActiveLink;
  }

  const isAbsoluteHref = isAbsoluteUrl(href || '');
  const LinkComponent = isAbsoluteHref ? CclLink : Link;

  return <>
    <div
      className={cn(`header-submenu-link-container`, `level-${level}`, {'is-active': isActive}, {'border-bottom-class' : borderBottom})}
      {...menuProps}
    >
      { href ?
        <LinkComponent href={href} as={as || href}>
          <a className="header-submenu-link">
            <div className='header-submenu-link-color'>{label}</div>
            { hasChildren && <span className="expand-icon">›</span> }
          </a>
        </LinkComponent> :
        !raw ?
          <div className="header-submenu-link" onClick={onClick}>
            <div className='header-submenu-link-color'>{label}</div>
            { hasChildren && <span className="expand-icon">›</span> }
          </div> : // For links without href, use div without link
          label // for JSX use the element directly
      }
      { props.children }
    </div>
    <style jsx>{`
      .header-submenu-link-container {
        box-sizing: border-box;
        position: unset;
        border-radius: ${props.theme === 'suave' ? '20px' : '0px'};
      }
      .header-submenu-link {
        text-decoration: none;
        white-space: nowrap;
        display: flex;
        align-items: center;
        position: relative;
        font-size: ${theme.fontSize.l};
        cursor: pointer;
      }

      .header-submenu-link-color {
        color: ${props.theme ==='suave' ? `${theme.colorPalette.indigo.main} !important` : ''} ;
        font-weight: 400;
      }

      .border-bottom-class {
        border-bottom: 1px solid #8D61E7;
        border-radius: 0px;
      }

      .expand-icon {
        padding: ${theme.spacingOld(1, 5)}
      }

      .header-submenu-link:before {
        content: '';
        width: 0;
        background-color: ${props.theme === 'suave' ? '' : theme.palette.secondary.main};
        height: 100%;
        position: absolute;
        transition: 0.25s width;
      }

      .header-submenu-link-container.is-active>.header-submenu-link:before {
        background-color${theme.palette.secondary.disabled};
      }
      .header-submenu-link-container:hover>.header-submenu-link:before {
        width: ${theme.spacingOld(2)};
      }
      .header-submenu-link-container:hover>.submenu-level-container {
        display: block;
        opacity: 1;
      }

      .header-submenu-link div {
        padding: ${theme.spacingOld(5, 6)};
        box-sizing: border-box;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: ${theme.palette.grey[600]}
      }

      .level-0 { background-color: ${props.theme === 'suave' ? theme.colorPalette.lavender.main : HEADER_LEVEL_COLORS[0]}; 
        padding: ${props.theme === 'suave' ?  theme.spacing(0.5) : ''};
      }
      .level-1 { background-color: ${HEADER_LEVEL_COLORS[1]}; }
      .level-2 { background-color: ${HEADER_LEVEL_COLORS[2]}; }
      .level-3 { background-color: ${HEADER_LEVEL_COLORS[3]}; }
      .level-4 { background-color: ${HEADER_LEVEL_COLORS[4]}; }
      .level-0:hover { background-color: ${props.theme === 'suave' ? theme.colorPalette.lavender.main : HEADER_LEVEL_COLORS[1]}; }
      .level-1:hover { background-color: ${HEADER_LEVEL_COLORS[2]}; }
      .level-2:hover { background-color: ${HEADER_LEVEL_COLORS[3]}; }
      .level-3:hover { background-color: ${HEADER_LEVEL_COLORS[4]}; }
      .level-4:hover { background-color: ${HEADER_LEVEL_COLORS[5]}; }

      @media ${theme.mediaQuery.tabletUp} {
        .header-submenu-link div {
          padding: ${theme.spacingOld(4, 5)};
        }
        .header-submenu-link {
          font-size: ${theme.fontSize.m};
        }
      }
    `}</style>
  </>;
};
