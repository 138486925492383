import React, { FunctionComponent, useState, useEffect } from 'react';
import { theme } from '@admitkard/ccl/style';
import { cn } from '@admitkard/ccl/cn';
import { Img } from '@admitkard/ccl/Img';
import { ProgressBar } from '@admitkard/ccl/progressBar';
import { Text } from '@admitkard/ccl/Text';
import {useRouter} from 'next/router';
import { env } from '@admitkard/uiutils/env';

type LoaderState = 'entering' | 'exiting' | 'exited' | undefined;

interface LoaderProps {
  transitionState?: LoaderState;
  delay?: number;
  duration?: number;
  enterDuration?: number;
  exitDuration?: number;
  onExit?: () => void;
}

const hideAKLoader = [
  '/bigleague',
  '/tcl'
];





const ENTER_DELAY = 500;
const EXIT_DELAY = 300;

export const Loader: FunctionComponent<LoaderProps> = (props) => {


  const router= useRouter();
  function isAkLoaderHidden(){
    if (hideAKLoader.some((value) => router.pathname.startsWith(`${value}`))){
      return true;
    }
    else{
      return false;
    }
  }

  const [ loaderState, setLoaderState ] = useState<LoaderState>();
  if (!loaderState) {
    setTimeout(() => setLoaderState('entering'));
  }
  useEffect(() => {
    if (props.transitionState) {
      setLoaderState(props.transitionState);
    }
  }, [props.transitionState]);

  if (loaderState === 'exiting') {
    setTimeout(() => {
      setLoaderState('exited');
      props.onExit && props.onExit();
    }, EXIT_DELAY + 100);
  }
  return <>
    <div className={cn(
      'loaderContainer',
      'loaderContainerEntering',
      {
        loadaerContainerExiting: loaderState === 'exiting',
      },
    )}>
      <div className="loaderContent">
        <div></div>
        { !isAkLoaderHidden() &&   <Img src="images/admitkard_logo_2.svg"  />}
        { isAkLoaderHidden() &&
        <Img src={env.getAssetUrl('images/bigleague/big-league-logo.png')} style={{height:'32px', width:'198px'}} />}
        <div className="loaderProgressbar">
          <ProgressBar variant="indeterminate" value={50} color="primary" />
        </div>
        <Text variant="s" lightness={4} align="center">
          { loaderState === 'entering' && 'Searching globally for a perfect answer...' }
          { loaderState === 'exiting' && 'Found it!!!' }
        </Text>
      </div>
    </div>
    <style jsx>{`
      .loaderContainer {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${theme.palette.grey[10]};
        opacity: 0;
        z-index: 1200;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .loaderContainerEntering {
        opacity: 1;
        transition: ${ENTER_DELAY/1000}s opacity;
      }
      .loaderContainerExiting {
        animation: exitOpacity ${EXIT_DELAY/1000}s;
      } 
      .loaderContent {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 20rem;
        padding: 1rem;
        align-items: center;
        justify-content: center;
      }
      .loaderContent img {
        width: 100%;
        max-width: 6rem;

      }
      .loaderProgressbar {
        padding: 0.5rem ;
        width: 100%;
        max-width: 10rem;
      }
      @keyframes exitOpacity {
        from { opacity: 1 }
        to { opacity: 0}
      }
    `}</style>
  </>;
};
