/* eslint-disable max-len */
export const benefits = [
  {
    key: 1,
    title: 'Credit Card',
    tile1: '200$ cash back on 2000$ spend',
    tile2: '3% cash back on online and offline spends',
    tile3: 'Amex credit cards with anti theft protection',
    tile4: 'No social security number required',
    imageUrl: 'images/mentorX/Credit_card.png',
    imageUrlD: 'images/mentorX/benefit1.png',
    backgroundColor: '#F7EFFF',
    buttonBackground: '#F0E0FF',
  },
  {
    key: 2,
    title: 'International Bank Account',
    tile1: 'No monthly fees',
    tile2: 'Visa Debit card delivered in India',
    tile3: 'No foreign transactions fees',
    tile4: 'No international wire transfer fees',
    imageUrl: 'images/mentorX/International_Bank_Account.png',
    imageUrlD: 'images/mentorX/benefit2.png',
    backgroundColor: '#FFEFE0',
    buttonBackground: '#FFE6CE',
  },
  {
    key: 3,
    title: 'International Sim Card',
    tile1: 'Get a US phone number in 5 min',
    tile2: 'No contracts and surprise fees',
    tile3: 'Pause plans anytime you use',
    tile4: 'Only pay for data you use',
    imageUrl: 'images/mentorX/International_Sim_Card.png',
    imageUrlD: 'images/mentorX/benefit3.png',
    backgroundColor: '#F8F8F8',
    buttonBackground: '#F2F2F2',
  },
  {
    key: 4,
    title: 'International Money Transfer',
    tile1: 'Lowest currency exchange rates',
    tile2: '100% Payment security',
    tile3: 'No Hidden Charges',
    tile4: 'RBI norm compliant',
    imageUrl: 'images/mentorX/International_Money_Transfer.png',
    imageUrlD: 'images/mentorX/benefit4.png',
    backgroundColor: '#F7EFFF',
    buttonBackground: '#F0E0FF',
  },
  {
    key: 5,
    title: 'Accommodation',
    tile1: 'Fast and easy bookings',
    tile2: '100% verified listings',
    tile3: 'Stress Free Booking',
    tile4: 'Call for Free Advice',
    imageUrl: 'images/mentorX/Accomodation.png',
    imageUrlD: 'images/mentorX/benefit5.png',
    backgroundColor: '#FFEFE0',
    buttonBackground: '#FFE6CE',
  },
  {
    key: 6,
    title: 'Access to AKISC community',
    tile1: 'Access to international student network',
    tile2: 'Regular meetups/webinars',
    tile3: 'Multiple offers rolled out for AKISC members',
    tile4: 'Upskilling classes and events held for our community',
    imageUrl: 'images/mentorX/akisc.png',
    imageUrlD: 'images/mentorX/benefit6.png',
    backgroundColor: '#F8F8F8',
    buttonBackground: '#F2F2F2',
  },
];

export const MentorXMediaImages = [
  { key: '1', imageUrl: 'images/mentorX/media/01.png' },
  { key: '2', imageUrl: 'images/mentorX/media/02.png' },
  { key: '3', imageUrl: 'images/mentorX/media/03.png' },
  { key: '4', imageUrl: 'images/mentorX/media/04.png' },
  { key: '5', imageUrl: 'images/mentorX/media/05.png' },
  { key: '6', imageUrl: 'images/mentorX/media/06.png' },
  { key: '7', imageUrl: 'images/mentorX/media/07.png' },
  { key: '8', imageUrl: 'images/mentorX/media/08.png' },
  { key: '9', imageUrl: 'images/mentorX/media/09.png' },
  { key: '10', imageUrl: 'images/mentorX/media/10.png' },
  { key: '11', imageUrl: 'images/mentorX/media/11.png' },
  { key: '12', imageUrl: 'images/mentorX/media/12.png' },
];

export const questionnare_questions_usa = [
  {
    question: 'Which all services do you need?',
    type: 'multiple_select',
    options: [
      'Credit Card',
      'International Bank Account',
      'International Sim Card',
      'International Money Transfer',
      'Accommodation',
      'Access to AKISC community',
    ],
  },
  {
    question: 'Let’s know more about you',
    type: 'name_number',
  },
  { question: 'Verify your phone number', type: 'otp_input' },

  {
    question: 'When does your course begin?',
    type: 'single_select',
    options: ['2021', '2022', '2023'],
  },
  { question: 'Do you have a Social Security Number?', type: 'boolean' },
];

export const questionnare_questions_uk = [
  {
    question: 'Which all services do you need?',
    type: 'multiple_select',
    // eslint-disable-next-line max-len
    options: [
      'Credit Card',
      'International Bank Account',
      'International Sim Card',
      'International Money Transfer',
      'Accommodation',
      'Access to AKISC community',
    ],
  },
  {
    question: 'Let’s know more about you',
    type: 'name_number',
  },
  { question: 'Verify your phone number', type: 'otp_input' },
  {
    question: 'When does your course begin?',
    type: 'single_select',
    options: ['2021', '2022', '2023'],
  },
  { question: 'Do you have National Insurance number?', type: 'boolean' },
];

export const MentorXServices = [
  'Credit Card',
  'International Bank Account',
  'International Sim Card',
  'International Money Transfer',
  'Accommodation',
  'Access to AKISC community',
];

export const MentorXServicesSlugs = [
  'credit-card',
  'international-bank-account',
  'international-sim-card',
  'international-money-transfer',
  'accommodation',
  'access-to-akisc-community',
];

export const MentorXFAQs = [
  {
    title: 'What is MentorX?',
    summary:
      'It is a set of FREE study abroad products, utilities & services where a student can get everything from a bank account, credit cards, and insurance to accommodation. It helps to transition to a country abroad easier. If a student avails all the services they get a total of $500 in rewards and cashbacks.'
  },
  {
    title: 'How do we become a MentorX Ambassador?',
    summary:
      'Just reach out to support@admitkard.com and you will receive an invitation for a call where you shall be given an onboarding where you can earn on every conversion. ',
  },
  {
    title: 'What are the benefits of MentorX?',
    summary:
      'We aim to provide services & utilities to all students going abroad and make their life easier. Its a one stop shop for all your needs.'
  },
  {
    title: 'Who can join MentorX?',
    summary:
      'Anyone who is going or is already abroad can avail these services as per their home country',
  },
  {
    title: 'What is AKISC?',
    summary:
      'An Indian community made to help students looking for networking opportunities with AdmitKard Alumni. It is country specific and focused towards skill development, meetups, & interviews/ between well settled members in the community'
  }];

/**
 * key for referral value stored in localStorage
 */

export const classNameKeyForAnalytics: Record<number, string> = {
  [0]: 'Services-Select',
  [1]: 'Basic-Details-Select',
  [2]: 'OTP-Select',
  [3]: 'Course-Year-Select',
  [4]: 'Social-Security-Select',
};

export const LOCAL_STORAGE_KEY_REFERRED_BY = 'referredBy';
export const LOCAL_STORAGE_KEY_LEAD_SOURCE = 'utm_source';
