import { SuaveChip } from '@admitkard/ccl/Suave';
import { SingleSelectItemConfig } from 'components/ListingEngine/types';
import React from 'react';

interface MultiSelectMobileFilterProps {
  itemConfigs: SingleSelectItemConfig[],
  onSelect: (selection?: string[]) => void;
  selection?: string[];
  withIcon?: boolean;
}

const MultiSelectMobileFilter: React.FunctionComponent<MultiSelectMobileFilterProps> = (props) => {

  const localData = new Set<string>(props.selection);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: '0.5rem',
    }}>
      {props.itemConfigs.map((value) =>
      {
        const isSelected = localData.has(value.slug);
        return (
          <SuaveChip
            key={`single-item-${value.slug}}`}
            label={value.label}
            variant='select-able'
            isSelected={isSelected}
            icon={props.withIcon ? value?.icon : undefined}
            onClick={
              () => {
                isSelected === true ? localData.delete(value.slug) : localData.add(value.slug);
                if(localData.size > 0) {
                  props.onSelect(Array.from<string>(localData));
                } else {
                  props.onSelect(undefined);
                }
              }
            }
          />
        );
      }
      )}
    </div>
  );
};

export default MultiSelectMobileFilter;
