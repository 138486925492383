import { Button, SuaveChip } from '@admitkard/ccl';
import pushToDataLayer from '@admitkard/uiutils/gTagDataLayer';
import React from 'react';
import { LIST_COUNTRIES } from '../Constants';
import { PafPageProps } from '../Types';
import { QuestionHeader } from './Components';
import styles from './questionStyles.css';
import { Analytics } from '@admitkard/uiutils/analytics/events';

export const CountrySelect: React.FunctionComponent<PafPageProps> = (props) => {
  const classes = styles();
  const { pageKey } = props;
  if (props.urlParams.country && !props.pafData[pageKey]) {
    props.setData(props.urlParams.country);
  }
  const goToNext = () => {
    pushToDataLayer({
      event: 'level_country',
      data: {
        ['country']: props.pafData[pageKey],
      },
    });
    Analytics.track('level_country', { ['country']: props.pafData[pageKey] });
    Analytics.people.set({ ['country']: props.pafData[pageKey] });
    props.goToNext();
  };

  return (
    <div className={classes.questionContainer}>
      <QuestionHeader
        questionText="where do you want to study?"
        isFirstPage={props.currentPage === 0}
        onBackClick={props.goToPrevious}
      />
      <div className={classes.optionsContainerMedium}>
        {LIST_COUNTRIES.map((country) => {
          const isSelected = props.pafData[pageKey] === country.slug;
          return (
            <SuaveChip
              key={country.slug}
              avatar={{
                alt: country.slug,
                src: country.avatar,
              }}
              label={country.label}
              size="medium"
              isSelected={isSelected}
              isInActive={!isSelected && props.pafData[pageKey]?.length > 0}
              onClick={() => (isSelected ? props.setData(undefined) : props.setData(country.slug))}
            />
          );
        })}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          id="next-button-country-select"
          isRounded={true}
          color="indigo"
          hasArrowIcon
          disabled={props.pafData[pageKey] ? false : true}
          onClick={goToNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
