import { IconName } from '@admitkard/ccl';

type ScoreRage = {
  minScore: number;
  maxScore: number;
  defaultValue?: number;
  step?: number;
};

type ChipData = {
  _id?: string;
  label: string;
  slug: string;
  icon: IconName;
  recommended?: boolean;
};

type ChipDataWithScore = ChipData & ScoreRage;

export const LIST_COUNTRIES = [
  {
    label: 'United States',
    slug: 'United States',
    avatar:
      'https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/' +
      '800px-Flag_of_the_United_States.svg.png?20151118161041',
    conversionRate: 81.52155,
  },
  {
    label: 'United Kingdom',
    slug: 'United Kingdom',
    avatar:
      'https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/' +
      '800px-Flag_of_the_United_Kingdom.svg.png?20151118161041',
    conversionRate: 90.899162,
  },
  {
    label: 'Australia',
    slug: 'Australia',
    avatar:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Flag_of_Australia_%28converted%29.svg/' +
      '800px-Flag_of_Australia_%28converted%29.svg.png?20151118161041',
    conversionRate: 52.385672,
  },
  {
    label: 'Canada',
    slug: 'Canada',
    avatar:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Flag_of_Canada.svg/' +
      '800px-Flag_of_Canada.svg.png?20151118161041',
    conversionRate: 59.160828,
  },
  {
    label: 'Singapore',
    slug: 'Singapore',
    avatar:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Flag_of_Singapore.svg/' +
      '800px-Flag_of_Singapore.svg.png?20151118161041',
    conversionRate: 56.774428,
  },
  {
    label: 'France',
    slug: 'France',
    avatar:
      'https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Flag_of_France.svg/' +
      '800px-Flag_of_France.svg.png?20151118161041',
    conversionRate: 79.882755,
  },
  {
    label: 'Germany',
    slug: 'Germany',
    avatar:
      'https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/' +
      '800px-Flag_of_Germany.svg.png?20151118161041',
    conversionRate: 79.882755,
  },

  {
    label: 'New Zealand',
    slug: 'New Zealand',
    avatar:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/Flag_of_New_Zealand.svg/' +
      '800px-Flag_of_New_Zealand.svg.png?20151118161041',
    conversionRate: 45.804016,
  },
  {
    label: 'Ireland',
    slug: 'Ireland',
    avatar:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/Flag_of_Ireland.svg/' +
      '800px-Flag_of_Ireland.svg.png?20151118161041',
    conversionRate: 79.882755,
  },
];

export const LIST_APTITUDE_TESTS_PG_PHD: ChipDataWithScore[] = [
  {
    label: 'GRE',
    slug: 'gre',
    icon: 'aptitude1',
    minScore: 260,
    maxScore: 340,
    defaultValue: 280,
  },
  {
    label: 'GMAT',
    slug: 'gmat',
    icon: 'aptitude2',
    minScore: 200,
    maxScore: 800,
    defaultValue: 550,
  },
  {
    label: 'None',
    slug: 'none',
    icon: 'none',
    minScore: 0,
    maxScore: 0,
  },
];

export const LIST_APTITUDE_TESTS_UG: ChipDataWithScore[] = [
  {
    label: 'SAT',
    slug: 'sat',
    icon: 'aptitude3',
    minScore: 600,
    maxScore: 1600,
    defaultValue: 1060,
  },
  {
    label: 'ACT',
    slug: 'act',
    icon: 'aptitude4',
    minScore: 1,
    maxScore: 36,
    defaultValue: 21,
  },
  {
    label: 'None',
    slug: 'none',
    icon: 'none',
    minScore: 0,
    maxScore: 0,
  },
];

export const LIST_COURSE_LEVELS: ChipData[] = [
  {
    label: '12th',
    slug: 'twelfth',
    icon: 'bachelors',
  },
  {
    label: 'Bachelors',
    slug: 'ug',
    icon: 'masters',
  },
  {
    label: 'Masters',
    slug: 'pg',
    icon: 'phd',
  },
];

export const LIST_DEGREES: ChipData[] = [
  {
    label: 'Bachelors',
    slug: 'ug',
    icon: 'bachelors',
  },
  {
    label: 'Masters',
    slug: 'pg',
    icon: 'masters',
  },
];

export const LIST_ENGLISH_TESTS: ChipDataWithScore[] = [
  {
    label: 'TOEFL',
    slug: 'toefl',
    icon: 'eng1',
    minScore: 0,
    maxScore: 120,
    defaultValue: 90,
    step: 1,
  },
  {
    label: 'IELTS',
    slug: 'ielts',
    icon: 'eng2',
    minScore: 3,
    maxScore: 9,
    defaultValue: 6.5,
    step: 0.5,
  },
  {
    label: 'PTE',
    slug: 'pte',
    icon: 'eng3',
    minScore: 10,
    maxScore: 90,
    defaultValue: 70,
    step: 1,
  },
  {
    label: 'None',
    slug: 'none',
    icon: 'none',
    minScore: 0,
    maxScore: 0,
  },
];

export const LIST_AREA_OF_STUDIES: ChipData[] = [
  {
    _id: '5ba11c95cccffa1302b8f8ff',
    label: 'Business and Management',
    slug: 'business-and-management',
    icon: 'business',
    recommended: true,
  },
  {
    _id: '5ba11c95cccffa1302b8f900',
    label: 'Computer Science and IT',
    slug: 'computer-science-and-it',
    icon: 'cs',
    recommended: true,
  },
  {
    _id: '5ba11c95cccffa1302b8f902',
    label: 'Engineering',
    slug: 'engineering',
    icon: 'engineering2',
    recommended: true,
  },
  {
    _id: '5ba11c95cccffa1302b8f909',
    label: 'Social Science',
    slug: 'social-science',
    icon: 'socialScience',
  },
  {
    _id: '5ba11c95cccffa1302b8f8fe',
    label: 'Architecture',
    slug: 'architecture',
    icon: 'architecture2',
  },
  {
    _id: '5ba11c95cccffa1302b8f907',
    label: 'Professional Studies',
    slug: 'professional-studies',
    icon: 'studies',
  },
  {
    _id: '5dbabdefd8bf386139c231ce',
    label: 'Hospitality and Tourism',
    slug: 'hospitality-and-tourism',
    icon: 'tourism',
  },
  {
    _id: '5ba11c95cccffa1302b8f908',
    label: 'Science',
    slug: 'science',
    icon: 'science',
  },
  {
    _id: '5ba11c95cccffa1302b8f90a',
    label: 'Sports',
    slug: 'sports',
    icon: 'sports',
  },
  {
    _id: '5ba11c95cccffa1302b8f903',
    label: 'Fine Arts',
    slug: 'fine-arts',
    icon: 'arts',
  },
  {
    _id: '5ba11c95cccffa1302b8f904',
    label: 'Law',
    slug: 'law',
    icon: 'law2',
  },
  {
    _id: '5ba11c95cccffa1302b8f901',
    label: 'Education',
    slug: 'education',
    icon: 'graduation',
  },
  {
    _id: '5ba11c95cccffa1302b8f905',
    label: 'Mathematics',
    slug: 'mathematics',
    icon: 'maths',
  },
  {
    _id: '5ba11c95cccffa1302b8f906',
    label: 'Medicine',
    slug: 'medicine',
    icon: 'medicine',
  },
  {
    _id: '5eba7a62d4bfba27b11ba671',
    label: 'Journalism and Media',
    slug: 'journalism-and-media',
    icon: 'media',
  },
  {
    _id: '5eba7a62d4bfba27b11ba674',
    label: 'Agriculture and Forestry',
    slug: 'agriculture-and-forestry',
    icon: 'agriculture2',
  },
];

export const AOS = [
  {
    icon: 'business',
    slug: 'business-management',
    label: 'Business & Management',
    recommended: true,
  },
  {
    icon: 'cs',
    slug: 'computer-science-it',
    label: 'Computer Science & IT',
    recommended: true,
  },
  {
    icon: 'engineering2',
    slug: 'engineering',
    label: 'Engineering',
    recommended: true,
  },
  {
    icon: 'agriculture2',
    slug: 'agriculture-forestry',
    label: 'Agriculture & Forestry',
  },
  {
    icon: 'architecture2',
    slug: 'architecture',
    label: 'Architecture',
  },
  {
    icon: 'law2',
    slug: 'law',
    label: 'Law',
  },
  {
    icon: 'arts',
    slug: 'fine-arts',
    label: 'Fine Arts',
  },
  {
    icon: 'tourism',
    slug: 'hospitality-tourism',
    label: 'Hospitality & Tourism',
  },
  {
    icon: 'media',
    slug: 'journalism-media',
    label: 'Journalism & Media',
  },
  {
    icon: 'maths',
    slug: 'mathematics',
    label: 'Mathematics',
  },
  {
    icon: 'medicine',
    slug: 'medicine',
    label: 'Medicine',
  },
  {
    icon: 'science',
    slug: 'science',
    label: 'Science',
  },
  {
    icon: 'studies',
    slug: 'professional-studies',
    label: 'Professional Studies',
  },
  {
    icon: 'socialScience',
    slug: 'social-science',
    label: 'Social Science',
  },
];

export const SPECIALIZATION = [
  {
    name: 'Agribusiness',
    slug: 'agribusiness',
    aos: ['agriculture-and-forestry'],
    department: { id: '5ebb8c49d4bfba27b11ba69c', slug: 'agribusiness' },
  },
  {
    name: 'Agricultural Science',
    slug: 'agricultural-science',
    aos: ['agriculture-and-forestry'],
    department: { id: '5ebcf830d4bfba27b11ba6a5', slug: 'agriculture-sciences' },
  },
  {
    name: 'Agronomy',
    slug: 'agronomy',
    aos: ['agriculture-and-forestry'],
    department: { id: '5ebaa0add4bfba27b11ba682', slug: 'plant-and-crop-sciences' },
  },
  {
    name: 'Fisheries',
    slug: 'fisheries',
    aos: ['agriculture-and-forestry'],
    department: {
      id: '5ebaa0add4bfba27b11ba684',
      slug: 'aquaculture-and-fisheries',
    },
  },
  {
    name: 'Floriculture',
    slug: 'floriculture',
    aos: ['agriculture-and-forestry'],
    department: { id: '5ebcf830d4bfba27b11ba6a5', slug: 'agriculture-sciences' },
  },
  {
    name: 'Forestry',
    slug: 'forestry',
    aos: ['agriculture-and-forestry'],
    department: { id: '5ba139245a7d95641e3eaa76', slug: 'forestry' },
  },
  {
    name: 'Horticulture',
    slug: 'horticulture',
    aos: ['agriculture-and-forestry'],
    department: { id: '5ebaa0add4bfba27b11ba683', slug: 'horticulture' },
  },
  {
    name: 'Mariculture',
    slug: 'mariculture',
    aos: ['agriculture-and-forestry'],
    department: {
      id: '5ebaa0add4bfba27b11ba684',
      slug: 'aquaculture-and-fisheries',
    },
  },
  {
    name: 'Poultry, Dairy',
    slug: 'poultry-dairy',
    aos: ['agriculture-and-forestry'],
    department: { id: '5ebaa115d4bfba27b11ba688', slug: 'animal-sciences' },
  },
  {
    name: 'Architecture',
    slug: 'architecture',
    aos: ['architecture'],
    department: { id: '5ebaa115d4bfba27b11ba685', slug: 'architecture' },
  },
  {
    name: 'Construction',
    slug: 'construction',
    aos: ['architecture'],
    department: {
      id: '5ba139245a7d95641e3eaa45',
      slug: 'construction-engineering',
    },
  },
  {
    name: 'Construction Management',
    slug: 'construction-management',
    aos: ['architecture'],
    department: { id: '5ebaa115d4bfba27b11ba685', slug: 'architecture' },
  },
  {
    name: 'Heating',
    slug: 'heating',
    aos: ['architecture'],
    department: { id: '5ebaa115d4bfba27b11ba685', slug: 'architecture' },
  },
  {
    name: 'Interior Design',
    slug: 'interior-design',
    aos: ['architecture'],
    department: { id: '5ba139245a7d95641e3eaa24', slug: 'interior-design' },
  },
  {
    name: 'Surveying',
    slug: 'surveying',
    aos: ['architecture'],
    department: { id: '5ebaa115d4bfba27b11ba685', slug: 'architecture' },
  },
  {
    name: 'Urban Planning',
    slug: 'urban-planning',
    aos: ['architecture'],
    department: { id: '5ba139245a7d95641e3eaa44', slug: 'civil-engineering' },
  },
  {
    name: 'Ventilation',
    slug: 'ventilation',
    aos: ['architecture'],
    department: { id: '5ba139245a7d95641e3eaa44', slug: 'civil-engineering' },
  },
  {
    name: 'Accounts',
    slug: 'accounts',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa25', slug: 'accountancy' },
  },
  {
    name: 'Banking, Taxation, Investment',
    slug: 'banking-taxation-investment',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa37', slug: 'taxation' },
  },
  {
    name: 'Business Analytics, Management Consulting',
    slug: 'business-analytics-management-consulting',
    aos: ['business-and-management'],
    department: { id: '5ebb8c49d4bfba27b11ba69d', slug: 'business-analytics' },
  },
  {
    name: 'Business Communication',
    slug: 'business-communication',
    aos: ['business-and-management'],
    department: { id: '5ebb8c49d4bfba27b11ba69e', slug: 'corporate-communication' },
  },
  {
    name: 'Business Management, Business Administration',
    slug: 'business-management-business-administration',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa27', slug: 'business-administration' },
  },
  {
    name: 'Commerce',
    slug: 'commerce',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa29', slug: 'commerce' },
  },
  {
    name: 'Conflict Analysis and Management',
    slug: 'conflict-analysis-and-management',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa27', slug: 'business-administration' },
  },
  {
    name: 'E-business, Digital Business',
    slug: 'e-business-digital-business',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa27', slug: 'business-administration' },
  },
  {
    name: 'Entrepreneurship, Innovation',
    slug: 'entrepreneurship-innovation',
    aos: ['business-and-management'],
    department: { id: '5ebb8c49d4bfba27b11ba6a1', slug: 'entrepreneurship' },
  },
  {
    name: 'Event Management, Organizational Management',
    slug: 'event-management-organizational-management',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa2b', slug: 'event-management' },
  },
  {
    name: 'Finance',
    slug: 'finance',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa2c', slug: 'finance' },
  },
  {
    name: 'Financial Management',
    slug: 'financial-management',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa2c', slug: 'finance' },
  },
  {
    name: 'Health Management',
    slug: 'health-management',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa30', slug: 'management-others' },
  },
  {
    name: 'Human Resource Management, Human Resource Development',
    slug: 'human-resource-management-human-resource-development',
    aos: ['business-and-management'],
    department: {
      id: '5ebb8c49d4bfba27b11ba6a2',
      slug: 'human-resource-management',
    },
  },
  {
    name: 'Information Systems, Information Technology Management',
    slug: 'information-systems-information-technology-management',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa3d', slug: 'information-technology' },
  },
  {
    name: 'Insurance, Risk Management',
    slug: 'insurance-risk-management',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa30', slug: 'management-others' },
  },
  {
    name: 'International Business',
    slug: 'international-business',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa2e', slug: 'international-business' },
  },
  {
    name: 'International Management',
    slug: 'international-management',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa30', slug: 'management-others' },
  },
  {
    name: 'Logistics, Supply Chain Management',
    slug: 'logistics-supply-chain-management',
    aos: ['business-and-management'],
    department: {
      id: '5ba139245a7d95641e3eaa36',
      slug: 'supply-chain-management-and-logistics',
    },
  },
  {
    name: 'Marketing Management',
    slug: 'marketing-management',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa31', slug: 'marketing' },
  },
  {
    name: 'Office Administration',
    slug: 'office-administration',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa68', slug: 'office-administration' },
  },
  {
    name: 'Operations',
    slug: 'operations',
    aos: ['business-and-management'],
    department: {
      id: '5ba139245a7d95641e3eaa36',
      slug: 'supply-chain-management-and-logistics',
    },
  },
  {
    name: 'Project Management',
    slug: 'project-management',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa33', slug: 'project-management' },
  },
  {
    name: 'Real Estate, Property Administeration',
    slug: 'real-estate-property-administeration',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa27', slug: 'business-administration' },
  },
  {
    name: 'Sales, Marketing, Digital Marketing',
    slug: 'sales-marketing-digital-marketing',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa31', slug: 'marketing' },
  },
  {
    name: 'Technical Management',
    slug: 'technical-management',
    aos: ['business-and-management'],
    department: { id: '5ba139245a7d95641e3eaa30', slug: 'management-others' },
  },
  {
    name: 'Artificial Intelligence',
    slug: 'artificial-intelligence',
    aos: ['computer-science-and-it'],
    department: { id: '5ebb8b64d4bfba27b11ba690', slug: 'artificial-intelligence' },
  },
  {
    name: 'Business Analysis, IT Business Analytics',
    slug: 'business-analysis-it-business-analytics',
    aos: ['computer-science-and-it'],
    department: { id: '5ebb8c49d4bfba27b11ba69d', slug: 'business-analytics' },
    recommended: true,
  },
  {
    name: 'Computer Information Systems',
    slug: 'computer-information-systems',
    aos: ['computer-science-and-it'],
    department: { id: '5ebb8b64d4bfba27b11ba692', slug: 'computer-science' },
    recommended: true,
  },
  {
    name: 'Computer Science, Software Development',
    slug: 'computer-science-software-development',
    aos: ['computer-science-and-it'],
    department: { id: '5ebb8b64d4bfba27b11ba692', slug: 'computer-science' },
    recommended: true,
  },
  {
    name: 'Computer Technology',
    slug: 'computer-technology',
    aos: ['computer-science-and-it'],
    department: { id: '5ebb8b64d4bfba27b11ba692', slug: 'computer-science' },
  },
  {
    name: 'Computer/ Game Programming',
    slug: 'computer-game-programming',
    aos: ['computer-science-and-it'],
    department: { id: '5ebb8b64d4bfba27b11ba692', slug: 'computer-science' },
  },
  {
    name: 'Computing',
    slug: 'computing',
    aos: ['computer-science-and-it'],
    department: { id: '5ebb8b64d4bfba27b11ba692', slug: 'computer-science' },
  },
  {
    name: 'Customer Intelligence and Analytics',
    slug: 'customer-intelligence-and-analytics',
    aos: ['computer-science-and-it'],
    department: { id: '5ba139245a7d95641e3eaa27', slug: 'business-administration' },
  },
  {
    name: 'Cyber Security, Information Security',
    slug: 'cyber-security-information-security',
    aos: ['computer-science-and-it'],
    department: { id: '5ba139245a7d95641e3eaa3c', slug: 'cyber-security' },
  },
  {
    name: 'Data Science and Analytics',
    slug: 'data-science-and-analytics',
    aos: ['computer-science-and-it'],
    department: {
      id: '5ebb8b64d4bfba27b11ba693',
      slug: 'data-science-and-big-data',
    },
  },
  {
    name: 'Game Design',
    slug: 'game-design',
    aos: ['computer-science-and-it'],
    department: { id: '5ebb8b64d4bfba27b11ba692', slug: 'computer-science' },
  },
  {
    name: 'Information Technology',
    slug: 'information-technology',
    aos: ['computer-science-and-it'],
    department: { id: '5ba139245a7d95641e3eaa3d', slug: 'information-technology' },
  },
  {
    name: 'Mobile Application',
    slug: 'mobile-application',
    aos: ['computer-science-and-it'],
    department: { id: '5ebb8b64d4bfba27b11ba692', slug: 'computer-science' },
  },
  {
    name: 'Mobile Communication',
    slug: 'mobile-communication',
    aos: ['computer-science-and-it'],
    department: { id: '5ebb8b64d4bfba27b11ba692', slug: 'computer-science' },
  },
  {
    name: 'Multimedia',
    slug: 'multimedia',
    aos: ['computer-science-and-it'],
    department: {
      id: '5ebb8b64d4bfba27b11ba698',
      slug: 'video-games-and-multimedia',
    },
  },
  {
    name: 'Networking',
    slug: 'networking',
    aos: ['computer-science-and-it'],
    department: { id: '5ba139245a7d95641e3eaa3a', slug: 'computer-engineering' },
  },
  {
    name: 'Programmer',
    slug: 'programmer',
    aos: ['computer-science-and-it'],
    department: { id: '5ba139245a7d95641e3eaa3a', slug: 'computer-engineering' },
  },
  {
    name: 'Software Testing',
    slug: 'software-testing',
    aos: ['computer-science-and-it'],
    department: { id: '5ebb8b64d4bfba27b11ba697', slug: 'software-engineering' },
  },
  {
    name: 'Web Design, Web Applications',
    slug: 'web-design-web-applications',
    aos: ['computer-science-and-it'],
    department: {
      id: '5ebb8b64d4bfba27b11ba699',
      slug: 'web-technologies-and-cloud-computing',
    },
  },
  {
    name: 'Adult, Organisational Learning and Leadership',
    slug: 'adult-organisational-learning-and-leadership',
    aos: ['education'],
    department: { id: '5ba139245a7d95641e3eaa2f', slug: 'leadership' },
  },
  {
    name: 'Early Childhood Education, Primary Education, Secondary Education',
    slug: 'early-childhood-education-primary-education-secondary-education',
    aos: ['education'],
    department: { id: '5ba139245a7d95641e3eaa3e', slug: 'education' },
  },
  {
    name: 'Education Assistance',
    slug: 'education-assistance',
    aos: ['education'],
    department: { id: '5ba139245a7d95641e3eaa3e', slug: 'education' },
  },
  {
    name: 'Education Counseling',
    slug: 'education-counseling',
    aos: ['education'],
    department: undefined,
  },
  {
    name: 'Educational Training',
    slug: 'educational-training',
    aos: ['education'],
    department: { id: '5ba139245a7d95641e3eaa3e', slug: 'education' },
  },
  {
    name: 'Elementary Education',
    slug: 'elementary-education',
    aos: ['education'],
    department: { id: '5ba139245a7d95641e3eaa3e', slug: 'education' },
  },
  {
    name: 'Gender Studies',
    slug: 'gender-studies',
    aos: ['education'],
    department: { id: '5ba139245a7d95641e3eaa90', slug: 'social-sciences' },
  },
  {
    name: 'Physical Education',
    slug: 'physical-education',
    aos: ['education'],
    department: { id: '5ba139245a7d95641e3eaa3e', slug: 'education' },
  },
  {
    name: 'Reading',
    slug: 'reading',
    aos: ['education'],
    department: { id: '5ba139245a7d95641e3eaa3e', slug: 'education' },
  },
  {
    name: 'Teacher Education Program',
    slug: 'teacher-education-program',
    aos: ['education'],
    department: { id: '5ba139245a7d95641e3eaa3e', slug: 'education' },
  },
  {
    name: 'Aeronautical, Aerospace Engineering, Aviation Technology',
    slug: 'aeronautical-aerospace-engineering-aviation-technology',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa40', slug: 'aerospace-engineering' },
  },
  {
    name: 'Applied Engineering',
    slug: 'applied-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa48', slug: 'engineering-others' },
  },
  {
    name: 'Architectural Engineering, Structural Engineering',
    slug: 'architectural-engineering-structural-engineering',
    aos: ['engineering'],
    department: { id: '5ebaa115d4bfba27b11ba685', slug: 'architecture' },
  },
  {
    name: 'Automotive Engineering',
    slug: 'automotive-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa48', slug: 'engineering-others' },
  },
  {
    name: 'Biomedical Engineering',
    slug: 'biomedical-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa41', slug: 'biomedical-engineering' },
  },
  {
    name: 'Chemical Engineering',
    slug: 'chemical-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa42', slug: 'chemical-engineering' },
  },
  {
    name: 'Civil Engineering, Geotechnical Engineering',
    slug: 'civil-engineering-geotechnical-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa44', slug: 'civil-engineering' },
  },
  {
    name: 'Computer Engineering, Software Engineering',
    slug: 'computer-engineering-software-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa3a', slug: 'computer-engineering' },
  },
  {
    name: 'Electrical and Electronic Engineering',
    slug: 'electrical-and-electronic-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa46', slug: 'electrical-engineering' },
  },
  {
    name: 'Electrical Engineering',
    slug: 'electrical-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa47', slug: 'electrical-engineering' },
  },
  {
    name: 'Electromechanical Engineering',
    slug: 'electromechanical-engineering',
    aos: ['engineering'],
    department: { id: '5ebaa04ed4bfba27b11ba681', slug: 'electronics-engineering' },
  },
  {
    name: 'Electronics and Telecommunication Engineering',
    slug: 'electronics-and-telecommunication-engineering',
    aos: ['engineering'],
    department: { id: '5ebaa04ed4bfba27b11ba681', slug: 'electronics-engineering' },
  },
  {
    name: 'Electronics Engineering',
    slug: 'electronics-engineering',
    aos: ['engineering'],
    department: { id: '5ebaa04ed4bfba27b11ba681', slug: 'electronics-engineering' },
  },
  {
    name: 'Energy Systems Technology',
    slug: 'energy-systems-technology',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa48', slug: 'engineering-others' },
  },
  {
    name: 'Engineering Management',
    slug: 'engineering-management',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa49', slug: 'engineering-management' },
  },
  {
    name: 'Engineering Science',
    slug: 'engineering-science',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa48', slug: 'engineering-others' },
  },
  {
    name: 'Environmental Engineering',
    slug: 'environmental-engineering',
    aos: ['engineering'],
    department: {
      id: '5ba139245a7d95641e3eaa72',
      slug: 'environmental-engineering',
    },
  },
  {
    name: 'Industrial Design',
    slug: 'industrial-design',
    aos: ['engineering'],
    department: { id: '5ebaa115d4bfba27b11ba687', slug: 'industrial-design' },
  },
  {
    name: 'Industrial Engineering, Process Engineering',
    slug: 'industrial-engineering-process-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa4a', slug: 'industrial-engineering' },
  },
  {
    name: 'Instrumentation',
    slug: 'instrumentation',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa5c', slug: 'music' },
  },
  {
    name: 'Interdisciplinary Engineering',
    slug: 'interdisciplinary-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa48', slug: 'engineering-others' },
  },
  {
    name: 'Liquid Crystal Engineering',
    slug: 'liquid-crystal-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa48', slug: 'engineering-others' },
  },
  {
    name: 'Machine Learning',
    slug: 'machine-learning',
    aos: ['engineering'],
    department: { id: '5ebb8b64d4bfba27b11ba696', slug: 'machine-learning' },
  },
  {
    name: 'Manufacturing Engineering',
    slug: 'manufacturing-engineering',
    aos: ['engineering'],
    department: {
      id: '5ba139245a7d95641e3eaa4c',
      slug: 'manufacturing-engineering',
    },
  },
  {
    name: 'Marine Engineering',
    slug: 'marine-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa48', slug: 'engineering-others' },
  },
  {
    name: 'Material Science, Material Science and Engineering',
    slug: 'material-science-material-science-and-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa4d', slug: 'materials-engineering' },
  },
  {
    name: 'Mechanical and Mechatronics Engineering',
    slug: 'mechanical-and-mechatronics-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa4e', slug: 'mechanical-engineering' },
  },
  {
    name: 'Mechanical Engineering',
    slug: 'mechanical-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa4e', slug: 'mechanical-engineering' },
  },
  {
    name: 'Mechatronics',
    slug: 'mechatronics',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa4e', slug: 'mechanical-engineering' },
  },
  {
    name: 'Metallurgical Engineering, Material Engineering',
    slug: 'metallurgical-engineering-material-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa4d', slug: 'materials-engineering' },
  },
  {
    name: 'Micro electro-mechanical systems (MEMS)',
    slug: 'micro-electro-mechanical-systems-mems',
    aos: ['engineering'],
    department: { id: '5ebaa04ed4bfba27b11ba681', slug: 'electronics-engineering' },
  },
  {
    name: 'Mining',
    slug: 'mining',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa4f', slug: 'mining-engineering' },
  },
  {
    name: 'Mining Engineering',
    slug: 'mining-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa4f', slug: 'mining-engineering' },
  },
  {
    name: 'Nuclear Engineering',
    slug: 'nuclear-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa48', slug: 'engineering-others' },
  },
  {
    name: 'Paper and Bioprocess',
    slug: 'paper-and-bioprocess',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa82', slug: 'science-others' },
  },
  {
    name: 'Petroleum Engineering',
    slug: 'petroleum-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa50', slug: 'petroleum-engineering' },
  },
  {
    name: 'Petroleum, Oil and Gas, Diesel Technology',
    slug: 'petroleum-oil-and-gas-diesel-technology',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa50', slug: 'petroleum-engineering' },
  },
  {
    name: 'Plastics Engineering',
    slug: 'plastics-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa47', slug: 'engineering-others' },
  },
  {
    name: 'Power Engineering',
    slug: 'power-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa47', slug: 'engineering-others' },
  },
  {
    name: 'Product Design Engineering',
    slug: 'product-design-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa48', slug: 'engineering-others' },
  },
  {
    name: 'Robotics',
    slug: 'robotics',
    aos: ['engineering'],
    department: { id: '5ebb8b64d4bfba27b11ba692', slug: 'computer-science' },
  },
  {
    name: 'Spatial Engineering',
    slug: 'spatial-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa48', slug: 'engineering-others' },
  },
  {
    name: 'System Engineering',
    slug: 'system-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa52', slug: 'systems-engineering' },
  },
  {
    name: 'Telecommunications Engineering',
    slug: 'telecommunications-engineering',
    aos: ['engineering'],
    department: { id: '5ebaa04ed4bfba27b11ba681', slug: 'electronics-engineering' },
  },
  {
    name: 'Textile Engineering',
    slug: 'textile-engineering',
    aos: ['engineering'],
    department: { id: '5ba139245a7d95641e3eaa48', slug: 'engineering-others' },
  },
  {
    name: 'Welding and Fabrication',
    slug: 'welding-and-fabrication',
    aos: ['engineering'],
    department: {
      id: '5ba139245a7d95641e3eaa66',
      slug: 'applied-professional-studies',
    },
  },
  {
    name: 'Animation',
    slug: 'animation',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa38', slug: 'animation' },
  },
  {
    name: 'Applied Arts , Printing, Studio Art',
    slug: 'applied-arts-printing-studio-art',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa59', slug: 'fine-arts-studies' },
  },
  {
    name: 'Arts',
    slug: 'arts',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa59', slug: 'fine-arts-studies' },
  },
  {
    name: 'Bakery and Pastry Arts',
    slug: 'bakery-and-pastry-arts',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa54', slug: 'culinary-arts' },
  },
  {
    name: 'Car Interior Design',
    slug: 'car-interior-design',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa59', slug: 'fine-arts-studies' },
  },
  {
    name: 'Carpentry, Woodworking, Blacksmithing, Goldsmithing, Silversmithing, Ceramics',
    slug: 'carpentry-woodworking-blacksmithing-goldsmithing-silversmithing-ceramics',
    aos: ['fine-arts'],
    department: {
      id: '5ba139245a7d95641e3eaa66',
      slug: 'applied-professional-studies',
    },
  },
  {
    name: 'Creative Writing',
    slug: 'creative-writing',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa59', slug: 'fine-arts-studies' },
  },
  {
    name: 'Dance',
    slug: 'dance',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa59', slug: 'fine-arts-studies' },
  },
  {
    name: 'Design , Fashion, Textile, Graphics, Product',
    slug: 'design-fashion-textile-graphics-product',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa59', slug: 'fine-arts-studies' },
  },
  {
    name: 'English',
    slug: 'english',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa88', slug: 'english-composition' },
  },
  {
    name: 'Exhibition/Event',
    slug: 'exhibition-event',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa59', slug: 'fine-arts-studies' },
  },
  {
    name: 'Film, Cinematography, Interactive Media, Multimedia',
    slug: 'film-cinematography-interactive-media-multimedia',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa59', slug: 'fine-arts-studies' },
  },
  {
    name: 'Fine Arts-Drawing, Painting, Sculpture',
    slug: 'fine-arts-drawing-painting-sculpture',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa59', slug: 'fine-arts-studies' },
  },
  {
    name: 'General Studies',
    slug: 'general-studies',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa90', slug: 'social-sciences' },
  },
  {
    name: 'Interdisciplinary Studies',
    slug: 'interdisciplinary-studies',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa90', slug: 'social-sciences' },
  },
  {
    name: 'Liberal Arts and Studies',
    slug: 'liberal-arts-and-studies',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa90', slug: 'social-sciences' },
  },
  {
    name: 'Library',
    slug: 'library',
    aos: ['fine-arts'],
    department: {
      id: '5ba139245a7d95641e3eaa66',
      slug: 'applied-professional-studies',
    },
  },
  {
    name: 'Music',
    slug: 'music',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa59', slug: 'fine-arts-studies' },
  },
  {
    name: 'Photography',
    slug: 'photography',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa59', slug: 'fine-arts-studies' },
  },
  {
    name: 'Publishing',
    slug: 'publishing',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa59', slug: 'fine-arts-studies' },
  },
  {
    name: 'Textiles',
    slug: 'textiles',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa59', slug: 'fine-arts-studies' },
  },
  {
    name: 'Theatre, Drama',
    slug: 'theatre-drama',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa59', slug: 'fine-arts-studies' },
  },
  {
    name: 'Visual Arts',
    slug: 'visual-arts',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa38', slug: 'animation' },
  },
  {
    name: 'Wine Making, Brewery, Winery',
    slug: 'wine-making-brewery-winery',
    aos: ['fine-arts'],
    department: { id: '5ba139245a7d95641e3eaa54', slug: 'culinary-arts' },
  },
  {
    name: 'Hospitality',
    slug: 'hospitality',
    aos: ['hospitality-and-tourism'],
    department: {
      id: '5ba139245a7d95641e3eaa8a',
      slug: 'hospitality-and-hotel-management',
    },
  },
  {
    name: 'Travel and Tourism',
    slug: 'travel-and-tourism',
    aos: ['hospitality-and-tourism'],
    department: { id: '5ba139245a7d95641e3eaa93', slug: 'tourism' },
  },
  {
    name: 'Advertising',
    slug: 'advertising',
    aos: ['journalism-and-media'],
    department: { id: '5ba139245a7d95641e3eaa26', slug: 'advertising' },
  },
  {
    name: 'Journalism, Broadcasting Journalism',
    slug: 'journalism-broadcasting-journalism',
    aos: ['journalism-and-media'],
    department: { id: '5eba997cd4bfba27b11ba677', slug: 'journalism' },
  },
  {
    name: 'Media, Mass communication, Technical comunication',
    slug: 'media-mass-communication-technical-comunication',
    aos: ['journalism-and-media'],
    department: {
      id: '5eba997cd4bfba27b11ba678',
      slug: 'media-studies-and-mass-media',
    },
  },
  {
    name: 'Criminology',
    slug: 'criminology',
    aos: ['law'],
    department: { id: '5ba139245a7d95641e3eaa5f', slug: 'criminology' },
  },
  {
    name: 'Forensic Science',
    slug: 'forensic-science',
    aos: ['law', 'professional-studies'],
    department: {
      id: '5ba139245a7d95641e3eaa66',
      slug: 'applied-professional-studies',
    },
  },
  {
    name: 'Justice and Emergency Services',
    slug: 'justice-and-emergency-services',
    aos: ['law'],
    department: { id: '5ba139245a7d95641e3eaa5f', slug: 'criminology' },
  },
  {
    name: 'Law, LLB, LLM',
    slug: 'law-llb-llm',
    aos: ['law'],
    department: { id: '5ba139245a7d95641e3eaa5f', slug: 'criminology' },
  },
  {
    name: 'Legal Assistant, Court Support',
    slug: 'legal-assistant-court-support',
    aos: ['law'],
    department: {
      id: '5ba139245a7d95641e3eaa66',
      slug: 'applied-professional-studies',
    },
  },
  {
    name: 'Actuarial Science',
    slug: 'actuarial-science',
    aos: ['mathematics'],
    department: { id: '5ebb8c49d4bfba27b11ba69b', slug: 'actuarial-science' },
  },
  {
    name: 'Mathematics',
    slug: 'mathematics',
    aos: ['mathematics'],
    department: { id: '5ba139245a7d95641e3eaa61', slug: 'mathematics' },
  },
  {
    name: 'Statistics',
    slug: 'statistics',
    aos: ['mathematics'],
    department: { id: '5ba139245a7d95641e3eaa62', slug: 'statistics' },
  },
  {
    name: 'Acupunture',
    slug: 'acupunture',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Addiction and Mental Health',
    slug: 'addiction-and-mental-health',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Audiology , Speech, Speech pathology, Speech Therapy',
    slug: 'audiology-speech-speech-pathology-speech-therapy',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Autism',
    slug: 'autism',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Cardiovascular Science',
    slug: 'cardiovascular-science',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Clinical Lab',
    slug: 'clinical-lab',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Communicative Disorder',
    slug: 'communicative-disorder',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Cytotechnology',
    slug: 'cytotechnology',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Dentistry, Dental Hygiene',
    slug: 'dentistry-dental-hygiene',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Dietetics, Nutrition',
    slug: 'dietetics-nutrition',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Drug Development',
    slug: 'drug-development',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Fitness, Physical Activity',
    slug: 'fitness-physical-activity',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Health Psychology',
    slug: 'health-psychology',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Health Science, Healthcare, Health Management',
    slug: 'health-science-healthcare-health-management',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Kinesiology',
    slug: 'kinesiology',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Medical Radiation Technology',
    slug: 'medical-radiation-technology',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Medical Science',
    slug: 'medical-science',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Medical Technology',
    slug: 'medical-technology',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Medicine',
    slug: 'medicine',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Midwifery',
    slug: 'midwifery',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Nursing',
    slug: 'nursing',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa7b', slug: 'nursing' },
  },
  {
    name: 'Occupational Therapy',
    slug: 'occupational-therapy',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa7d', slug: 'occupational-therapy' },
  },
  {
    name: 'Optometry',
    slug: 'optometry',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Paradmeic Studies',
    slug: 'paradmeic-studies',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Pharmaceutical Sciences, Pharmacy',
    slug: 'pharmaceutical-sciences-pharmacy',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa7e', slug: 'pharmacy' },
  },
  {
    name: 'Physical Therapy, Physiotherapy',
    slug: 'physical-therapy-physiotherapy',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Psychiatric Nursing',
    slug: 'psychiatric-nursing',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Psychology',
    slug: 'psychology',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa71', slug: 'clinical-psychology' },
  },
  {
    name: 'Public Heath',
    slug: 'public-heath',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Radiologic Science',
    slug: 'radiologic-science',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Recreation',
    slug: 'recreation',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa43', slug: 'city-planning' },
  },
  {
    name: 'Rehabilitation Assistant',
    slug: 'rehabilitation-assistant',
    aos: ['medicine'],
    department: {
      id: '5ba139245a7d95641e3eaa66',
      slug: 'applied-professional-studies',
    },
  },
  {
    name: 'Respiratory Care',
    slug: 'respiratory-care',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Therapeutic Recreation',
    slug: 'therapeutic-recreation',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Therapist Assistant',
    slug: 'therapist-assistant',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Veterinary Science',
    slug: 'veterinary-science',
    aos: ['medicine'],
    department: { id: '5ba139245a7d95641e3eaa83', slug: 'veterinary-science' },
  },
  {
    name: 'Aviation',
    slug: 'aviation',
    aos: ['professional-studies'],
    department: { id: '5ba139245a7d95641e3eaa40', slug: 'aerospace-engineering' },
  },
  {
    name: 'Criminal Science, International Criminology',
    slug: 'criminal-science-international-criminology',
    aos: ['professional-studies'],
    department: { id: '5ba139245a7d95641e3eaa5f', slug: 'criminology' },
  },
  {
    name: 'Culinary Skills',
    slug: 'culinary-skills',
    aos: ['professional-studies'],
    department: { id: '5ba139245a7d95641e3eaa54', slug: 'culinary-arts' },
  },
  {
    name: 'Financial Services',
    slug: 'financial-services',
    aos: ['professional-studies'],
    department: { id: '5ba139245a7d95641e3eaa2c', slug: 'finance' },
  },
  {
    name: 'Fire Science, firefighting, Fire and Safety',
    slug: 'fire-science-firefighting-fire-and-safety',
    aos: ['professional-studies'],
    department: {
      id: '5ba139245a7d95641e3eaa66',
      slug: 'applied-professional-studies',
    },
  },
  {
    name: 'Flying',
    slug: 'flying',
    aos: ['professional-studies'],
    department: {
      id: '5ba139245a7d95641e3eaa66',
      slug: 'applied-professional-studies',
    },
  },
  {
    name: 'Hair Styling, Makeup, Cosmetics, Esthetic Services',
    slug: 'hair-styling-makeup-cosmetics-esthetic-services',
    aos: ['professional-studies'],
    department: { id: '5ba139245a7d95641e3eaa59', slug: 'fine-arts-studies' },
  },
  {
    name: 'Massage Therapy',
    slug: 'massage-therapy',
    aos: ['professional-studies'],
    department: {
      id: '5ba139245a7d95641e3eaa66',
      slug: 'applied-professional-studies',
    },
  },
  {
    name: 'Military',
    slug: 'military',
    aos: ['professional-studies'],
    department: {
      id: '5ba139245a7d95641e3eaa66',
      slug: 'applied-professional-studies',
    },
  },
  {
    name: 'Safety, Police and Public Safety',
    slug: 'safety-police-and-public-safety',
    aos: ['professional-studies'],
    department: {
      id: '5ba139245a7d95641e3eaa66',
      slug: 'applied-professional-studies',
    },
  },
  {
    name: 'Technician',
    slug: 'technician',
    aos: ['professional-studies'],
    department: {
      id: '5ba139245a7d95641e3eaa66',
      slug: 'applied-professional-studies',
    },
  },
  {
    name: 'Transportation, Transportation Management',
    slug: 'transportation-transportation-management',
    aos: ['professional-studies'],
    department: { id: '5ba139245a7d95641e3eaa30', slug: 'management-others' },
  },
  {
    name: 'Animal Care',
    slug: 'animal-care',
    aos: ['science'],
    department: { id: '5ebaa115d4bfba27b11ba688', slug: 'animal-sciences' },
  },
  {
    name: 'Animal Conservation',
    slug: 'animal-conservation',
    aos: ['science'],
    department: { id: '5ebaa115d4bfba27b11ba688', slug: 'animal-sciences' },
  },
  {
    name: 'Animal Science',
    slug: 'animal-science',
    aos: ['science'],
    department: { id: '5ebaa115d4bfba27b11ba688', slug: 'animal-sciences' },
  },
  {
    name: 'Astronomy, Astrophysics',
    slug: 'astronomy-astrophysics',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa6c', slug: 'astronomy' },
  },
  {
    name: 'Atmospheric Sciences',
    slug: 'atmospheric-sciences',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa73', slug: 'environmental-studies' },
  },
  {
    name: 'Bioinformatics',
    slug: 'bioinformatics',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa6d', slug: 'bioinformatics' },
  },
  {
    name: 'Biology, Biological Science',
    slug: 'biology-biological-science',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa6e', slug: 'biology' },
  },
  {
    name: 'Biomaterials',
    slug: 'biomaterials',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa41', slug: 'biomedical-engineering' },
  },
  {
    name: 'Biomechanical devices',
    slug: 'biomechanical-devices',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa41', slug: 'biomedical-engineering' },
  },
  {
    name: 'Biomedical Sciences',
    slug: 'biomedical-sciences',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa6f', slug: 'biomedical-science' },
  },
  {
    name: 'Biophysics and Biochemistry',
    slug: 'biophysics-and-biochemistry',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa6e', slug: 'biology' },
  },
  {
    name: 'Biotechnology',
    slug: 'biotechnology',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa6f', slug: 'biomedical-science' },
  },
  {
    name: 'Chemistry',
    slug: 'chemistry',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa70', slug: 'chemistry' },
  },
  {
    name: 'Clinical Sciences',
    slug: 'clinical-sciences',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Earth Sciences',
    slug: 'earth-sciences',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa90', slug: 'social-sciences' },
  },
  {
    name: 'Environmental Science and Technology',
    slug: 'environmental-science-and-technology',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa73', slug: 'environmental-studies' },
  },
  {
    name: 'Food Science, Food Science and Nutrition, Food Science and Technology',
    slug: 'food-science-food-science-and-nutrition-food-science-and-technology',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa74', slug: 'food-science' },
  },
  {
    name: 'Genetics',
    slug: 'genetics',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa6e', slug: 'biology' },
  },
  {
    name: 'Geography',
    slug: 'geography',
    aos: ['science', 'social-science'],
    department: { id: '5ba139245a7d95641e3eaa77', slug: 'geology-and-geography' },
  },
  {
    name: 'Geology',
    slug: 'geology',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa77', slug: 'geology-and-geography' },
  },
  {
    name: 'Geospatial Science',
    slug: 'geospatial-science',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa82', slug: 'science-others' },
  },
  {
    name: 'Hydrobiology',
    slug: 'hydrobiology',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa82', slug: 'science-others' },
  },
  {
    name: 'Immunology',
    slug: 'immunology',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Lab Technician',
    slug: 'lab-technician',
    aos: ['science'],
    department: {
      id: '5ba139245a7d95641e3eaa66',
      slug: 'applied-professional-studies',
    },
  },
  {
    name: 'Life Science',
    slug: 'life-science',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa82', slug: 'science-others' },
  },
  {
    name: 'Marine Biology',
    slug: 'marine-biology',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa82', slug: 'science-others' },
  },
  {
    name: 'Meteorology',
    slug: 'meteorology',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa82', slug: 'science-others' },
  },
  {
    name: 'Microbiology',
    slug: 'microbiology',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa7a', slug: 'microbiology' },
  },
  {
    name: 'Nanoscience, Nanotechnology',
    slug: 'nanoscience-nanotechnology',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa82', slug: 'science-others' },
  },
  {
    name: 'Neuroscience',
    slug: 'neuroscience',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Oral Science',
    slug: 'oral-science',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'Physics',
    slug: 'physics',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa80', slug: 'physics' },
  },
  {
    name: 'Physiology',
    slug: 'physiology',
    aos: ['science'],
    department: { id: '5dbabfad97d7443dc49215a3', slug: 'physiology' },
  },
  {
    name: 'Plant Taxanomy , Plant Science',
    slug: 'plant-taxanomy-plant-science',
    aos: ['science'],
    department: { id: '5ebaa0add4bfba27b11ba682', slug: 'plant-and-crop-sciences' },
  },
  {
    name: 'Renewable Energy and Materials',
    slug: 'renewable-energy-and-materials',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa73', slug: 'environmental-studies' },
  },
  {
    name: 'Science',
    slug: 'science',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa82', slug: 'science-others' },
  },
  {
    name: 'Water Resources',
    slug: 'water-resources',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa73', slug: 'environmental-studies' },
  },
  {
    name: 'Wild Life Ecosystem, Conservation',
    slug: 'wild-life-ecosystem-conservation',
    aos: ['science'],
    department: { id: '5ba139245a7d95641e3eaa73', slug: 'environmental-studies' },
  },
  {
    name: 'Zoology',
    slug: 'zoology',
    aos: ['science'],
    department: {
      id: '5ba139245a7d95641e3eaa84',
      slug: 'zoology-and-animal-science',
    },
  },
  {
    name: 'Anthropology',
    slug: 'anthropology',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa85', slug: 'anthropology' },
  },
  {
    name: 'Archaeology',
    slug: 'archaeology',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa85', slug: 'anthropology' },
  },
  {
    name: 'Behavioral Sciences',
    slug: 'behavioral-sciences',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa71', slug: 'clinical-psychology' },
  },
  {
    name: 'Child Care, Child and Youth Worker, Child Care Development and Studies',
    slug: 'child-care-child-and-youth-worker-child-care-development-and-studies',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa3e', slug: 'education' },
  },
  {
    name: 'Community Development',
    slug: 'community-development',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa90', slug: 'social-sciences' },
  },
  {
    name: 'Cultural studies, Inter-cultural communication',
    slug: 'cultural-studies-inter-cultural-communication',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa90', slug: 'social-sciences' },
  },
  {
    name: 'Developmental Service Worker',
    slug: 'developmental-service-worker',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa90', slug: 'social-sciences' },
  },
  {
    name: 'Economics',
    slug: 'economics',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa87', slug: 'economics' },
  },
  {
    name: 'Ethnic Studies',
    slug: 'ethnic-studies',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa90', slug: 'social-sciences' },
  },
  {
    name: 'Gerontology',
    slug: 'gerontology',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa64', slug: 'medicine' },
  },
  {
    name: 'History',
    slug: 'history',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa90', slug: 'social-sciences' },
  },
  {
    name: 'Human Ecology, Ecology',
    slug: 'human-ecology-ecology',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa82', slug: 'science-others' },
  },
  {
    name: 'Human Service',
    slug: 'human-service',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa2d', slug: 'human-resources' },
  },
  {
    name: 'Humanities',
    slug: 'humanities',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa90', slug: 'social-sciences' },
  },
  {
    name: 'Indigenous Studies',
    slug: 'indigenous-studies',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa90', slug: 'social-sciences' },
  },
  {
    name: 'International Relations',
    slug: 'international-relations',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa8b', slug: 'international-relations' },
  },
  {
    name: 'Language and Literature',
    slug: 'language-and-literature',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa90', slug: 'social-sciences' },
  },
  {
    name: 'Museum and Gallery Studies',
    slug: 'museum-and-gallery-studies',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa59', slug: 'fine-arts-studies' },
  },
  {
    name: 'Personal Support Work',
    slug: 'personal-support-work',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa90', slug: 'social-sciences' },
  },
  {
    name: 'Philosophy, Aesthetics',
    slug: 'philosophy-aesthetics',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa8e', slug: 'philosophy' },
  },
  {
    name: 'Political Science',
    slug: 'political-science',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa8f', slug: 'political-science' },
  },
  {
    name: 'Public Relations, Public Services',
    slug: 'public-relations-public-services',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa34', slug: 'public-relations' },
  },
  {
    name: 'Religious Studies',
    slug: 'religious-studies',
    aos: ['social-science'],
    department: { id: '5dbabfad97d7443dc49215a2', slug: 'theology' },
  },
  {
    name: 'Social Policy',
    slug: 'social-policy',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa91', slug: 'social-work' },
  },
  {
    name: 'Social Work and NGO Related Fields',
    slug: 'social-work-and-ngo-related-fields',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa91', slug: 'social-work' },
  },
  {
    name: 'Sociology, Social Science',
    slug: 'sociology-social-science',
    aos: ['social-science'],
    department: { id: '5ba139245a7d95641e3eaa90', slug: 'social-sciences' },
  },
  {
    name: 'Exercise',
    slug: 'exercise',
    aos: ['sports'],
    department: { id: '5ba139245a7d95641e3eaa95', slug: 'sports' },
  },
  {
    name: 'Sports',
    slug: 'sports',
    aos: ['sports'],
    department: { id: '5ba139245a7d95641e3eaa95', slug: 'sports' },
  },
  {
    name: 'Sports Management',
    slug: 'sports-management',
    aos: ['sports'],
    department: { id: '5ba139245a7d95641e3eaa95', slug: 'sports' },
  },
  {
    name: 'Sports Science',
    slug: 'sports-science',
    aos: ['sports'],
    department: { id: '5ba139245a7d95641e3eaa95', slug: 'sports' },
  },
  {
    name: 'Applied Professional Studies',
    slug: 'applied-professional-studies',
    aos: ['professional-studies'],
    department: {
      id: '5ba139245a7d95641e3eaa66',
      slug: 'applied-professional-studies',
    },
  },
  {
    name: 'education',
    slug: 'education',
    aos: ['education'],
    department: { id: '5ba139245a7d95641e3eaa3e', slug: 'education' },
  },
];
