import React, { FunctionComponent } from 'react';
import Head from 'next/head';
import { theme } from '@admitkard/ccl/style';
import { env } from '@admitkard/uiutils/env';
import {ORGANIZATION_SCHEMA} from '@admitkard/uiutils/web_schemas';

export interface PostMeta {
  type?: 'article' | '';
  canonical: string;
  publishedTime?: string;
  modifiedTime?: string;
  robots?: string;
  additionalInfo?: {
    primary?: {
      label: string;
      value: string;
    };
    secondary?: {
      label: string;
      value: string;
    };
  };
  dateGmt?: string;
  modified?: string;
  modifiedGmt?: string;
}
interface HeadTagContent {
  title?: string;
  description?: string;
  image?: string;
  keywords?: string;
  jsonld?: any;
  meta?: PostMeta;
  jsonldClassName?: string;
  pageNext?: string;
  pagePrevious?: string;
}

const DEFAULT_TITLE = 'Study Abroad - Colleges, Courses, Exams, Fees';
const DEFAULT_DESC =
  'Want to study abroad ? Get expert advice and information on colleges, courses, exams, admission, ' +
  'student visa, and application process to study overseas.';

export const AkHead: FunctionComponent<HeadTagContent> = (props) => {
  const title = `${props.title || DEFAULT_TITLE} | AdmitKard`;
  const desc = `${props.description || DEFAULT_DESC}`;
  const ogImage = `${props.image || env.getAssetUrl('images/ak-og-image.webp')}`;
  return (
    <Head>
      <title key="title">{title}</title>
      {/* META TAGS */}
      {props.meta?.robots && <meta name="robots" content={props.meta?.robots} />}
      <meta property="og:locale" content="en_US" />
      <meta key="description" name="description" content={desc} />
      <meta key="theme-color" name="theme-color" content={theme.palette.primary.main} />
      <meta
        key="viewport-alignment"
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, target-densitydpi=device-dpi"
      />
      <meta key="cache-control-meta" httpEquiv="Cache-Control" content="max-age=1209600" />
      {/* SOCIAL META TAGS*/}
      <meta key="og:title" property="og:title" content={title} />
      <meta key="og:description" property="og:description" content={desc} />
      <meta key="og:site_name" property="og:site_name" content={'AdmitKard'} />
      <meta key="og:image" name="og:image" content={ogImage} />
      {props.meta?.type && <meta key="og:type" property="og:type" content={props.meta.type} />}
      {props.meta?.canonical && <meta key="og:url" property="og:url" content={props.meta.canonical} />}
      {props.meta?.canonical && (
        <link key="og:canonical" property="og:canonical" rel="canonical" href={props.meta.canonical} />
      )}

      {props.pageNext && (
        <link key="og:next" property="og:next" rel="next" href={props.pageNext}/>
      )}

      {props.pagePrevious && (
        <link  key="og:prev" property="og:prev" rel="prev" href={props.pagePrevious}/>
      )}

      {props.meta?.publishedTime && (
        <meta key="article:published_time" property="article:published_time" content={props.meta?.publishedTime} />
      )}
      {props.meta?.modifiedTime && (
        <meta key="article:modified_time" property="article:modified_time" content={props.meta?.modifiedTime} />
      )}
      <meta key="twitter:title" name="twitter:title" content={title} />
      <meta key="twitter:description" name="twitter:description" content={desc} />
      <meta key="twitter:image" name="twitter:image" content={ogImage} />
      <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
      <meta key="twitter:site" name="twitter:site" content="@admitkard" />

      {props.meta?.additionalInfo?.primary?.label && (
        <meta key="twitter:label1" name="twitter:label1" content={props.meta?.additionalInfo?.primary?.label} />
      )}
      {props.meta?.additionalInfo?.primary?.value && (
        <meta key="twitter:data1" name="twitter:data1" content={props.meta?.additionalInfo?.primary?.value} />
      )}
      {props.meta?.additionalInfo?.secondary?.label && (
        <meta key="twitter:label2" name="twitter:label2" content={props.meta?.additionalInfo?.secondary?.label} />
      )}
      {props.meta?.additionalInfo?.secondary?.value && (
        <meta key="twitter:data2" name="twitter:data2" content={props.meta?.additionalInfo?.secondary?.value} />
      )}
      {props.jsonld && (
        <script
          key="ld+json"
          type="application/ld+json"
          className={props.jsonldClassName}
          dangerouslySetInnerHTML={{ __html: props.jsonld }}
        ></script>
      )}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ORGANIZATION_SCHEMA) }}
      />

      {/* FAVICONS */}
      <link key="favicon" rel="shortcut icon" href={env.getAssetUrl('images/favicon.png')} />
      <link key="msfavicon" rel="icon" type="image/vnd.microsoft.icon" href={env.getAssetUrl('images/favicon.png')} />
      <link key="xfavicon" rel="icon" type="image/x-icon" href={env.getAssetUrl('images/favicon.png')} />
      <link key="applefavicon" rel="apple-touch-icon" href={env.getAssetUrl('images/favicon.png')} />
      <link key="icon" rel="icon" href={env.getAssetUrl('images/favicon.png')} />
      <link key="font-1" rel="font" href="https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2" />
      <link key="font-2" rel="font" href="https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2" />
      <link key="font-3" rel="font" href="https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2" />
      <link key="font-4" rel="font" href="https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2" />
      <link key="font-5" rel="font" href="https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2" />
      <link key="font-6" rel="font" href="https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2" />
      <link key="font-7" rel="font" href="https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBBc4.woff2" />

      <style key="baseStyle" jsx global>{`
        html,
        body,
        #app {
          min-height: 100%;
          width: 100%;
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          font-size: calc(14px + (18 - 14) * ((100vw - 360px) / (1600 - 360)));
          overflow-x: hidden;
          scroll-behavior: smooth;
        }
        body {
          min-height: 100%;
          margin: 0;
        }
      `}</style>
      {props.children}
    </Head>
  );
};
