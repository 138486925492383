import { SuaveChip } from '@admitkard/ccl/Suave';
import React, { useState } from 'react';
import { BaseCustomFilter, RenderMobileFilterProps, SpecialFilterItemProps } from '../../types';
import FilterMobileDialog from './FilterMobileDialog';
import exStyles from './FilterStripMobile.styled';

interface FilterStripMobileProps {
  filterData: Record<string, any>,
  filterConfigs: SpecialFilterItemProps[],
  specialFilterRenderer?: React.FunctionComponent<BaseCustomFilter<any>>,
  setFilterData: (data: Record<string,any>) => void,
  filterRenderer: React.FunctionComponent<RenderMobileFilterProps>
  defaultInitialFilters?: Record<string, any>,
  hideClearAllBtn?:boolean;
}

const FilterStripMobile: React.FunctionComponent<FilterStripMobileProps> = (props) => {
  const [selectedFilterKey, setSelectedFilterKey] = useState<string|undefined>();

  const { filterData, setFilterData, filterConfigs } = props;
  const hideClearAllBtn = props.hideClearAllBtn ? true : false;

  const handleFilterDialogClose = (value?: Record<string, any>) => {
    setSelectedFilterKey(undefined);
    setFilterData(value ?? { ...props?.defaultInitialFilters });
  };

  return (
    <>
      <div style={{
        margin: '0 0.5rem',
        display: 'flex',
        flexDirection: 'row',
        overflow: 'scroll',
      }} className={'filterContainer'}>
        {filterConfigs.map(
          (filterProp) =>
            <SuaveChip
              key={`filter-chip-${filterProp.key}`}
              label={filterProp.label}
              variant='mobile-filter'
              isSelected={filterData[filterProp.key] ? true : false}
              onClick={() => { setSelectedFilterKey(filterProp.key); }}
            />
        )
        }
      </div>
      <FilterMobileDialog
        key={`mobile-filter-dialog-${selectedFilterKey}`}
        filterState={{
          filterConfigs: filterConfigs,
          filterData: filterData
        }}
        specialFilterRenderer={props?.specialFilterRenderer}
        onClose={handleFilterDialogClose}
        open={selectedFilterKey ? true : false}
        selectedFilterKey={selectedFilterKey ?? filterConfigs[0].key }
        filterRenderer={props.filterRenderer}
        defaultInitialFilters={{ ...(props.defaultInitialFilters )}}
        hideClearAllBtn={hideClearAllBtn}
      />
      <style> {exStyles} </style>
    </>
  );
};

export default FilterStripMobile;
