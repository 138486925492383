import { FilterItemProps } from '@admitkard/ccl/Suave';
import React from 'react';
import { RenderDesktopFilterProps } from '../../types';
import DesktopFilterItem from './DesktopFilterItem';

interface DesktopFilterProps {
  filterConfigs: FilterItemProps[],
  filterData: Record<string, any>,
  setFilterData: (filterKey: string, data?: any) => void,
  filterRender: React.FunctionComponent<RenderDesktopFilterProps>,
}

const DesktopFilter: React.FunctionComponent<DesktopFilterProps> = (props) => {
  const { filterData } = props;

  return (
    <div style={{
      all: 'unset',
      display: 'flex',
      flexDirection: 'column',
      height: '1rem',
    }} >
      {props.filterConfigs.map((filterProp) => (
        <DesktopFilterItem
          key={`filter-desktop-${filterProp.key}`}
          filterItemProp={filterProp}
          hasSelection={filterData[filterProp.key] ? true : false}
          onSelection={(value) =>  props.setFilterData(filterProp.key,value)}
          filterData={filterData}
          filterRender={props.filterRender}
        />
      )
      )}
    </div>
  );
};

export default DesktopFilter;
