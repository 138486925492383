import { cn, FilterItemProps, Icon } from '@admitkard/ccl';
import React, { useState } from 'react';
import { RenderDesktopFilterProps } from '../../types';
import useStyles from './DesktopFilterItem.css';
import { slugify } from 'components/MentorX/MentorXutils';



interface DesktopFilterItemProps {
  filterItemProp: FilterItemProps,
  hasSelection?: boolean,
  onSelection: (value?: any) => void,
  filterData: Record<string, any>,
  filterRender: React.FunctionComponent<RenderDesktopFilterProps>,
}

const DesktopFilterItem: React.FunctionComponent<DesktopFilterItemProps> = (props) => {
  const classes = useStyles();
  const { filterItemProp, hasSelection = false } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div id={filterItemProp.key} className={classes.filterContainer}>
      {filterHeader()}
      <div className={cn(classes.filterItemsContainer,
        { [classes.filterItemExpanded]: isExpanded }
      )}>
        <props.filterRender
          selectedFilterKey={filterItemProp.key}
          filterData={props.filterData}
          onSelection={props.onSelection}
        />
      </div>
    </div>
  );

  function filterHeader(): React.ReactElement<any, any> | null {
    return <div id={`select-${slugify(filterItemProp.key)}`} className={classes.filterHeadContainer}
      onClick={() => setIsExpanded(!isExpanded)} >
      <div className={cn(classes.filterStatus, {
        [classes.filterStatusActive]: hasSelection
      })} />
      <span className={classes.filterLabel}>{filterItemProp.label}</span>
      <div className={cn(classes.filterExpandIcon, classes.notExpanded, {
        [classes.expanded]: isExpanded
      })}
      >
        <Icon
          color="primary"
          name="arrowDownLavender"
          size="tile"
          type="fill"
        />
      </div>
    </div>;
  }
};

export default DesktopFilterItem;

