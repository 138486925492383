import { auth } from '@admitkard/uiutils/http';
import React, { useEffect, useState } from 'react';
import * as headerApi from './headerApi';
import { debounce } from '@admitkard/uiutils/objects';
import { WithRouterProps } from 'next/dist/client/with-router';
import { withRouter } from 'next/router';
import NewPAFHeader from 'components/Header/NewPaflHeader';
import { PRE_LOGIN_URLS } from 'constants/links';
import { HomePageConfigContext } from 'components/Home/HomeV2/contexts';
import { useTokenStatus } from 'utils/hooks';
import { deleteCookie } from '@admitkard/uiutils/cookie';

const Header: React.FunctionComponent<WithRouterProps> = (props) => {
  const shouldKeepHeaderCollapsed = () => {
    return (
      window.location.pathname.indexOf('/profile/evaluation-form') !== -1 ||
      window.location.pathname.indexOf('/mentorx') !== -1 ||
      window.location.pathname.indexOf('/mentorx/questionnare') !== -1 ||
      window.location.pathname.indexOf('/paf') !== -1 ||
      window.location.pathname.indexOf(PRE_LOGIN_URLS.Login) !== -1
    );
  };

  const homePageConfig = React.useContext(HomePageConfigContext);

  const [userDetails, setUserDetails] = useState<Record<string, any>>();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [expandedLink, setExpandedLink] = useState<string>();
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(false);

  const { hasToken, token } = useTokenStatus();

  useEffect(() => {
    if (hasToken) {
      setLoggedInUser();
    }
  }, [token]);

  useEffect(() => {
    attachHeaderScrollMorph();
  }, []);

  const attachHeaderScrollMorph = () => {
    if (!shouldKeepHeaderCollapsed() && !isHeaderCollapsed) {
      setIsHeaderCollapsed(true);
    }
    headerCollapsedListener();
    const headerCollapsedDebounced = debounce(headerCollapsedListener, 5);
    window.addEventListener('scroll', headerCollapsedDebounced);
    window.addEventListener('mousewheel', headerCollapsedDebounced);
  };

  const headerCollapsedListener = () => {
    if (window.location.pathname.indexOf('/profile/evaluation-form') !== -1) {
      return;
    }
    if (window.scrollY > 20 && !isHeaderCollapsed) {
      setIsHeaderCollapsed(true);
    } else if (window.scrollY <= 20 && !shouldKeepHeaderCollapsed()) {
      setIsHeaderCollapsed(false);
    }
  };

  const setLoggedInUser = () => {
    const userData = window ? window.localStorage.getItem('userDetails') : '';
    if (token) {
      userData && userData !== 'undefined' && setUserDetails(JSON.parse(userData));
      setIsLoggedIn(true);
      headerApi
        .getUserDetails()
        .then((userDetailsResponse) => {
          window.localStorage.setItem('userDetails', JSON.stringify(userDetailsResponse));
          setUserDetails(userDetailsResponse);
        })
        .catch(() => {
          setIsLoggedIn(false);
          setUserDetails(undefined);
          window.localStorage.removeItem('userDetails');
        }); //if api gives error (token not authenticated)
    } else {
      if (userData) {
        window.localStorage.removeItem('userDetails');
      }
      setIsLoggedIn(false);
    }
  };

  const [isLogoutClicked, setIsLogoutClicked] = useState(false);

  useEffect(() => {
    if (isLogoutClicked) {
      headerApi.logoutUser();
      auth.deleteToken();
      deleteCookie('lastLoginAt');
      window.localStorage.removeItem('userDetails');
      window.localStorage.removeItem('pafData');
      router.reload();
    }
  }, [isLoggedIn]);

  const onLogoutClick = () => {
    setIsLogoutClicked(true);
    setIsLoggedIn(false);
  };

  const getMinimalHeaderRedirection = () => {
    const minimalHeaderRedirection: Record<string, string> = {
      ['/mentorx']: '/mentorx/questionnare',
    };
    return minimalHeaderRedirection[props.router.pathname];
  };

  const shouldHideGetStartedButton = () => {
    const minimalHeaderRedirection: Record<string, boolean> = {
      ['/mentorx/questionnare']: true,
      [PRE_LOGIN_URLS.Generic]: true,
    };
    return minimalHeaderRedirection[props.router.pathname];
  };

  const shouldHideActions = () => {
    const minimalHeaderRedirection: Record<string, boolean> = {
      ['/paf']: true,
      ['/paf/']: true,
      [PRE_LOGIN_URLS.Login]: true,
      [PRE_LOGIN_URLS.PAFScholarship]: true,
      [PRE_LOGIN_URLS.PAFAccommodations]: true,
      [PRE_LOGIN_URLS.Generic]: true,
      ['/']: homePageConfig.headerType === 'minimal',
    };
    return minimalHeaderRedirection[props.router.pathname];
  };
  const router = props.router;
  return (
    <NewPAFHeader
      isLoggedIn={isLoggedIn}
      userDetails={userDetails}
      onLogoutClick={onLogoutClick}
      variant="white"
      hideAllActions={shouldHideActions()}
      getStartedRedirection={getMinimalHeaderRedirection()}
      isGetStartedButtonHidden={shouldHideGetStartedButton()}
    />
  );
};

export default withRouter(Header);
