import { Avatar, cn, makeStyles } from '@admitkard/ccl';
import React, { useContext, useEffect, useState } from 'react';
import MobileHamMenu from './v2/MobileHamMenu';
import Router from 'next/router';
import { UserMenu } from 'components/Header/LoggedInMenu';
import { LoginRegisterContext } from 'context/context';
import { useTokenStatus } from 'utils/hooks';

const useStyles = (isWhite: boolean) =>
  makeStyles(() => ({
    headerContainer: {
      backgroundColor: 'hsla(266, 100%, 67%, 1)',
      display: 'flex',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      paddingLeft: '1rem',
      boxSizing: 'border-box',
      zIndex: 1300,
      minHeight: '2.625rem',
      '& .join-mentorX-button': {
        margin: '0.5rem 1rem 0.5rem 0',
        fontSize: '0.875rem',
      },
      '@media only screen and (min-width: 600px)': {
        padding: '0.5rem 5.125rem 0.5rem 3.75rem',
        backgroundColor: 'white',
        '& .join-mentorX-button': {
          display: 'none',
        },
      },
      '@media only screen and (max-width: 599px)': {
        backgroundColor: `${isWhite ? 'white' : 'hsla(266, 100%, 67%, 1)'}`,
        minHeight: 'unset',
      },
      '@media only screen and (max-width: 991px)': {
        zIndex: 1301,
      },
    },
    headerContainerWebKit: {
      width: '-webkit-fill-available',
    },
    headerContainerFireFox: {
      width: '-moz-available',
    },
    primaryLogo: {
      margin: '1.125rem auto 1.125rem 1rem',
      height: '0.975rem',
      display: 'none',
      '@media only screen and (min-width: 600px)': {
        margin: '0',
        marginRight: 'auto',
        height: '100%',
      },
      cursor: 'pointer',
    },
    primaryLogoWhite: {
      '@media only screen and (max-width: 599px)': {
        display: `${isWhite ? 'none' : 'block'}`,
      },
    },
    primaryLogoColored: {
      height: '2rem',
      width: '7rem',
      '@media only screen and (min-width: 600px)': {
        display: 'block',
      },
      '@media only screen and (max-width: 599px)': {
        display: `${isWhite ? 'block' : 'none'}`,
        marginLeft: '1rem',
        height: '1.2rem',
        width: '6rem',
      },
    },
    desktopHeaderActionContainer: {
      display: 'none',
      alignItems: 'center',
      '@media only screen and (min-width: 599px)': {
        display: 'flex',
      },
    },
    headerLinkText: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      letterSpacing: '-0.02em',
      color: '#483287',
      cursor: 'pointer',
    },
    cardButtonContainer: {
      height: '2.5rem',
      width: 'max-content',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '1rem 1.25rem 1rem 1.25rem',
      gap: '0.75rem',
      background: '#f7f0ff',
      borderRadius: '1.875rem',
      boxSizing: 'border-box',
      fontSize: '0.875rem',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      lineHeight: '1rem',
      fontWeight: 400,
      color: '#483286',
      border: 'none',
      textDecoration: 'none',
      '&:hover': {
        transform: 'translateY(-0.2rem)',
        boxShadow: '0px 3px 0px rgb(0 0 0 / 25%)',
      },
      '@media screen and (min-width: 992px)': {
        fontSize: '1rem',
        height: '2.5rem',
      },
    },
    hideButtonOnMobile: {
      marginRight: '1rem',
      display: 'none',
      '@media only screen and (max-width: 599px)': {
        display: 'block',
      },
    },
  }));

interface NewPAFHeaderProps {
  getStartedRedirection?: string;
  isGetStartedButtonHidden: boolean;
  variant: 'blue' | 'white';
  isLoggedIn?: boolean | null;
  userDetails?: Record<string, any>;
  onLogoutClick: () => void;
  hideAllActions?: boolean;
}

const NewPAFHeader: React.FunctionComponent<NewPAFHeaderProps> = (props) => {
  const classes = useStyles(props.variant === 'white')();
  const { isLoggedIn, userDetails, onLogoutClick } = props;
  const [open, setOpen] = useState(false);
  const { setModalOpen, setRedirectUrl } = useContext(LoginRegisterContext);
  const { hasToken } = useTokenStatus();
  // useRef prevent looping of leadSource attribution
  // const hasLeadSourceSet = useRef(false);

  useEffect(() => {
    const query = Router.query;
    if (!hasToken && query.triggerLogin === 'true') {
      setRedirectUrl('/paf?journey=AFTER_SIGNIN_FLOW');
      setModalOpen(true);
    }
  }, []);

  const AdmitKardLogoWhite = (
    <svg
      id={'AdmitKard-logo'}
      onClick={() => {
        Router.push('/');
      }}
      className={cn(classes.primaryLogo, classes.primaryLogoWhite)}
      xmlns="http://www.w3.org/2000/svg"
      width="89"
      height="14"
      viewBox="0 0 89 14"
      fill="none"
    >
      <path d="M0 13.215L9.1807 0.000915527H11.7609V13.215L9.1807 10.1191V3.73835L2.99095 13.215H0Z" fill="white" />
      <path
        d="M19.546 5.02028H15.2454L13.5159 6.69886V11.6477L15.2454 13.267H20.4512L22.1539 11.6477V1.43787H20.1235V11.6477H15.2461V6.69886H19.5467L19.546 5.02028Z"
        fill="white"
      />
      <path
        d="M25.2829 13.2672H23.3979V6.14857L25.2829 4.52509H33.6542L35.593 6.73627V13.2672H33.6542V6.73627H30.2177V13.2672H28.3959V6.73627H25.2857L25.2829 13.2672Z"
        fill="white"
      />
      <path d="M37.0278 5.09451V11.6957L38.5177 13.2806H40.1685V11.6957H38.5177V5.09451H37.0278Z" fill="white" />
      <path d="M37.0278 2.44846V4.12493H38.734V2.44846H37.0278Z" fill="white" />
      <path
        d="M41.6267 3.28656H43.2783V5.1353H49.0246V6.65129H43.2783V11.7903H49.0246V13.3186H43.2783L41.6267 11.3817V3.28656Z"
        fill="white"
      />
      <path d="M51.6155 0.000915527V13.1468L54.0899 10.2527V0.000915527H51.6155Z" fill="white" />
      <path
        d="M58.154 0.000757025L54.1287 6.68874L58.354 12.9516H60.4213L56.1995 6.45314L60.4213 0L58.154 0.000757025Z"
        fill="white"
      />
      <path
        d="M62.4661 5.09451H68.3977L70.0677 6.71244V11.4731L68.3977 13.0677H63.282L61.6028 11.4731V9.77873L63.282 8.09259H67.4946V9.77873H63.282V11.4731H68.4005V6.71244H62.469L62.4661 5.09451Z"
        fill="white"
      />
      <path d="M79.0113 5.09451H73.0223L71.3743 6.79446V13.3123H73.0223V6.79513H79.0113V5.09451Z" fill="white" />
      <path
        d="M86.3065 5.09467H82.0812L80.563 6.67189V11.8282L82.0812 13.1208H87.3686L89.0002 11.3079V1.59216H87.3686V11.3079L82.0812 11.5533V6.29708H86.3058L86.3065 5.09467Z"
        fill="white"
      />
    </svg>
  );

  const AdmitKardLogoColored = (
    <svg
      id={'AdmitKard-logo'}
      onClick={() => {
        Router.push('/');
      }}
      className={cn(classes.primaryLogo, classes.primaryLogoColored)}
      width="1341"
      height="210"
      viewBox="0 0 1341 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 207.673L141.648 0.375H177.207V151.382L141.648 207.673V59.0064L45.066 207.673H0Z" fill="#483287" />
      <path
        d="M294.5 79.1121H229.702L203.642 105.445V183.081L229.702 208.483H308.14L333.794 183.081V22.9126H303.201V183.081H229.712V105.445H294.511L294.5 79.1121Z"
        fill="#483287"
      />
      <path
        d="M380.954 208.493H352.553V96.8187L380.954 71.3501H507.088L536.302 106.038V208.493H507.088V106.038H455.309V208.493H427.859V106.038H380.997L380.954 208.493Z"
        fill="#483287"
      />
      <path d="M557.905 80.2798V183.837L580.353 208.699H605.226V183.837H580.353V80.2798H557.905Z" fill="#483287" />
      <path d="M557.905 38.7705V65.0704H583.613V38.7705H557.905Z" fill="#483287" />
      <path
        d="M627.214 51.9146H652.099V80.9169H738.681V104.699H652.099V185.318H738.681V209.293H652.099L627.214 178.908V51.9146Z"
        fill="#483287"
      />
      <path d="M777.719 0.374512V206.603L815.003 144.744V0.374512H777.719Z" fill="#B777FA" />
      <path
        d="M876.231 0.374669L815.58 105.293L879.245 203.543H910.394L846.782 101.597L910.394 0.362793L876.231 0.374669Z"
        fill="#B777FA"
      />
      <path
        d="M941.189 80.2798H1030.56L1055.73 105.661V180.345L1030.56 205.36H953.482L928.181 180.345V153.764L953.482 127.312H1016.95V153.764H953.482V180.345H1030.6V105.661H941.232L941.189 80.2798Z"
        fill="#B777FA"
      />
      <path d="M1190.48 80.2798H1100.25L1075.41 106.948V209.197H1100.25V106.958H1190.48V80.2798Z" fill="#B777FA" />
      <path
        d="M1300.41 80.2794H1236.75L1213.87 105.022V185.912L1236.75 206.191H1316.41L1341 177.75V25.3335H1316.41V177.75L1236.75 181.6V99.1424H1300.4L1300.41 80.2794Z"
        fill="#B777FA"
      />
    </svg>
  );

  const renderActionItems = () => (
    <div>
      {!props.isGetStartedButtonHidden &&
        (isLoggedIn ? (
          <div className="join-mentorX-button">
            <Avatar
              onClick={() => {
                setOpen(!open);
              }}
              src={userDetails?.profilePicUrl}
              alt={userDetails?.firstName}
            />
          </div>
        ) : (
          <div className={classes.hideButtonOnMobile}>
            <a id="cta-button" href="/login" className={classes.cardButtonContainer}>
              Login
            </a>
          </div>
        ))}
      <div className={classes.desktopHeaderActionContainer}>
        <span
          className={classes.headerLinkText}
          onClick={() => {
            if (isLoggedIn) {
              Router.push(`/paf?journey=DEFAULT_PAF_FLOW_NO_SUBMIT`);
            } else {
              Router.push(`/paf?journey=DEFAULT_PAF_FLOW`);
            }
          }}
          id="button-desktop-header-coursefinder"
        >
          Course Finder
        </span>
        <div className="h-space" style={{ width: '2.25rem' }} />
        <span
          className={classes.headerLinkText}
          onClick={() => {
            Router.push(`/blog`);
          }}
          id="button-desktop-header-blog"
        >
          Blog
        </span>
        <div className="h-space" style={{ width: '2.25rem' }} />
        {isLoggedIn ? (
          <UserMenu userDetails={userDetails} theme={'suave'} onLogoutClick={onLogoutClick} />
        ) : (
          !hasToken && (
            <div>
              <a id="cta-button" href="/login" className={classes.cardButtonContainer}>
                Login
              </a>
            </div>
          )
        )}
      </div>
    </div>
  );

  return (
    <div className={cn(classes.headerContainer, classes.headerContainerWebKit, classes.headerContainerFireFox)}>
      {!props.hideAllActions && (
        <MobileHamMenu
          open={open}
          setOpen={setOpen}
          isLoggedIn={isLoggedIn}
          onLogoutClick={onLogoutClick}
          variant={props.variant}
        />
      )}
      {AdmitKardLogoWhite}
      {AdmitKardLogoColored}
      {!props.hideAllActions && renderActionItems()}
    </div>
  );
};

export default NewPAFHeader;
