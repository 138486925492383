import { QuestionHeader } from 'components/ListingEngine/CommonComponents/QuestionRenderingFramework/QuestionPages/Components';
import styles from './questionStyles.css';
import { PafPageProps } from '../Types';
import React, { useState } from 'react';
import {  SuaveButton, SuaveSearch, SuaveSearchResultItem, cn } from '@admitkard/ccl';
import { env } from '@admitkard/uiutils/env';
import pushToDataLayer from '@admitkard/uiutils/gTagDataLayer';
import { Analytics } from '@admitkard/uiutils/analytics/events';

export const SearchableCurrentCitySelect: React.FC<PafPageProps> = (props) => {
  const classes = styles();
  const { pageKey } = props;

  const [currentSelection, setCurrentSelection] = useState<string | undefined>('');
  const searchVariant = typeof window !== 'undefined' && window.innerWidth < 767 ? 'style2' : 'style1';

  const handleResult = (value: SuaveSearchResultItem | undefined) => {
    if (value) {
      setCurrentSelection(value.id);
      props.setData(value);
    } else {
      setCurrentSelection(undefined);
      props.setData(undefined);
    }
  };

  const goToNext = () => {
    pushToDataLayer({
      event: 'level_current_city',
      data: {
        ['current_city']: props.pafData[pageKey].id,
      },
    });
    Analytics.track('level_current_city', {
      ['current_city']: props.pafData[pageKey].id,
    });
    Analytics.people.set({
      ['current_city']: props.pafData[pageKey].id,
    });
    props.goToNext();
  };

  const handleOnResult = (value: any) => {
    if (typeof value !== 'string') {
      handleResult(value);
    }
  };

  const renderCustomOption = (value: SuaveSearchResultItem) => {
    return (
      <div className={classes.dropDownContainerCurrentCity}>
        <div>{value.label}</div>
      </div>
    );
  };

  const fetchApi = async (keyword?: string) => {
    const finalResult: SuaveSearchResultItem[] = [];

    let url;

    if (!keyword) {
      setCurrentSelection(undefined);
      url = `${env.KDU_URL}/cities/citySearch?select=name&select=slug&select=countrySlug&select=countryName`;
    } else {
      url = `${env.KDU_URL}/cities/citySearch?select=name&select=slug&select=countrySlug&select=countryName&search=${keyword}`;
    }

    try {
      const response = await fetch(url);
      const data = await response.json();
      // eslint-disable-next-line spellcheck/spell-checker
      const result = data.filter((item: any) => item.countrySlug === 'india');
      if (result && Array.isArray(result)) {
        /* if search 'keyword' is present and result are empty
        show the other option */
        if(keyword  && !result.length) {
          finalResult.push({
            id: 'others',
            label: 'Others',
            value: 'others',
          });
          return finalResult;
        }

        result.forEach((value) =>
          finalResult.push({
            id: value.slug,
            label: value.name,
            value: value.countrySlug,
          })
        );
      }
      return finalResult;
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className={cn(classes.questionContainer, classes.fill)}>
      <div>
        <QuestionHeader
          questionText="What is your current location?"
          isFirstPage={props.currentPage === 0}
          onBackClick={props.goToPrevious}
        />
        <div className={cn(classes.searchBarContainer,classes.searchBarContainerWithMargin)}>
          <SuaveSearch
            id="search-current-city"
            label="Search"
            styleVariant={searchVariant}
            results={fetchApi}
            onResult={handleOnResult}
            renderCustomOptions={renderCustomOption}
            autoLoadAtStart
            optionCount={5}
            isScrollable={true}
          />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <SuaveButton
          id="next-button-current-city-select"
          variants="primary"
          disabled={!currentSelection}
          onClick={goToNext}
        >
          Next
        </SuaveButton>
      </div>
    </div>
  );
};
