import { makeStyles } from '@admitkard/ccl/style';

export default makeStyles((theme) => ({
  filterContainer: {
    borderTop: '0.0681rem solid #F7EFFF',
    borderBottom: '0.0681rem solid #F7EFFF',
  },
  filterHeadContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.75rem',
    width: '19.75rem',
    padding: '1.25rem 0',
    cursor: 'pointer',
  },
  filterStatus: {
    width: '0.375rem',
    height: '0.375rem',
    background: '#F7EFFF',
    borderRadius: '0.625rem'
  },
  filterStatusActive: {
    background: '#44C78D',
  },
  filterLabel: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: '#483287',
    marginRight: 'auto',
    userSelect: 'none',
  },
  filterExpandIcon: {
    display: 'flex',
    height: '1.5rem',
    width: '1.5rem',
    background: '#F7EFFF',
    borderRadius: '2.0041rem',
    alignItems: 'center',
    justifyContent: 'center',
    '--icon-color' : theme.colorPalette.indigo,
    color: '#483287',
    '& .MuiSvgIcon-root':{
      fontSize: '1rem',
    }
  },
  filterItemsContainer: {
    display: 'none',
    flexDirection: 'column',
    marginLeft: '1rem',
    marginBottom: '0.75rem',
    paddingTop: '0.75rem',
    borderTop: '0.0681rem solid #F7EFFF',
  },
  filterItemExpanded: {
    display: 'flex',
  },
  notExpanded: {
    transform: 'rotate(0deg)'
  },
  expanded: {
    transform: 'rotate(180deg)'
  },
}), {name: 'CourseFilterDesktop'});
